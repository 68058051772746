//import React from 'react';
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'; // Import emailjs library
import './bookappointment.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function ContactForm() {
  const [selectedCategory, setSelectedCategory] = useState('Corporate Events');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // After the "API call" has finished, stop showing the loading icon
    setLoading(false);

    try {
      // Assuming sendForm returns a promise
      const result = await emailjs.sendForm('service_mhkkkwa', 'template_megbwh2', form.current, {
        publicKey: '20SA2U48taVusKc5H',
      });
      console.log('Email successfully sent!', result.text);
      // Set form as submitted if email was sent successfully
      setSubmitted(true);
      
      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      // Reset the form here if needed
      form.current.reset();
    } catch (error) {
      console.error('Failed to send email:', error);
    }

    setLoading(false); // End loading
  };

  const filterServices = (category) => {
    // Define your service options based on category
    switch (category) {
      case 'Corporate Events':
        return [
          'Award Ceremonies',
          'Team Building Activities',
          'Exhibitions',
          'Product Launch',
          'Conferences & Seminars',
          'Corporate Offsites',
        ];
      case 'Experiential Travel':
        return [
          'Cultural Experience',
          'Local Cuisine',
          'Local Activities',
          'Customised Itinerary',
          'Flight Tickets',
          'Hotel Booking',
        ];
      case 'Corporate Gifting':
        return [
          'Budget Gifting',
          'Customised Gifting',
          'Occasional Gifting',
          'Festival Gifting',
          'Health and Safety Gifting',
          'Virtual Gifting',
        ];
      case 'Digital Marketing':
        return [
          'SMM',
          'SEO',
          'UI/UX Design',
          'Content Marketing',
          'Tech Solutions',
          'Creative Marketing',
        ];
      default:
        return [];
    }
  };
  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm('service_mhkkkwa', 'template_megbwh2', form.current, {
  //       publicKey: '20SA2U48taVusKc5H',
  //     })
  //     .then(
  //       () => {
  //         console.log('SUCCESS!');
  //         setSubmitted(true);
  //         // Reset form fields after submission
  //         form.current.reset();
  //       },
  //       (error) => {
  //         console.log('FAILED...', error.text);
  //       }
  //     );
  // };

  return (
    <section className="ftco-section img bg-hero ftcbook">
      <div className="mrg">
        
        <div className="row">
          <div className="col contcol">
            <div className="wrapper">
              <div className="row no-gutters justify-content-between">
                <div className="col-lg-6 d-flex align-items-stretch gtboxba">
                  {/* <div className="info">
                    <h3 className=" conth" >Contact Us</h3>
                   <p className='getth'>Progressively incentivize cooperative systems through technically </p>
                   <p className='getth' style={{paddingBottom:'9%'}}>sound functionalities. The credibly productivate seamless data.</p>
                    <div className="dbox  d-flex align-items-start conicon">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone" style={{ lineHeight: 'inherit' }} ></span>
                      </div>
                      <div className="text pl-4 contdetail">
                        <p> <a href="tel://1234567920" className='contdetail'>+91-9876543211, .    +91-9123456789</a></p>
                      </div>
                    </div>
                    <div className="dbox  d-flex align-items-start conicon">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-envelope" style={{ lineHeight: 'inherit' }}></span>
                      </div>
                      <div className="text pl-4">
                        <p> <a href="mailto:info@yoursite.com" className='contdetail'>contact@vuilive.com</a></p>
                      </div>
                    </div>
                    <div className="dbox  d-flex align-items-start conicon">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-clock" style={{ lineHeight: 'inherit' }}></span>
                      </div>
                      <div className="text pl-4">
                        <p> <a href="#" className='contdetail'>10:00 am   to  07:00 pm </a></p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col cont">
  <div className="contact-wrap getcont">
    <h3 className="mb-4 contform">Book An Appointment </h3>
    <div id="form-message-warning" className="mb-4"></div>
    <div id="form-message-success" className="mb-4 contformp">
    Get in touch with us for more information on any of the products or services we offer    </div>
    <form ref={form} onSubmit={handleSubmit}>
    <div className="form-group">
        <label htmlFor="inputAddress" className='fname'>Full Name*</label>
        <input type="text" className="form-control " id="inputname" placeholder="Your Full Name " required style={{width:'97%'}}/>
      </div>
      <div className="form-row">
        
      <div className="form-group col-md-6 contfeild">
        <label htmlFor="phoneNumber" className='fphone'>Phone Number*</label>
        <div className="input-group frmph">
          <div className="input-group-prepend ">
            <select className="custom-select" id="inputGroupSelect01" style={{background:'none',marginTop:'13px',outline:'none'}}>
              <option value="+91" >+91</option>
              <option value="+91">+62</option>
              <option value="+91">+36</option>

              {/* Add more options for other country codes */}
            </select>
          </div>
          <input type="tel" className="form-control" id="phoneNumber" placeholder="9999****** " required style={{borderTop:'none',borderBottom:'none',borderRight:'none',borderLeft:'0.88px solid red'}}/>
        </div>
      </div>
      
        <div className="form-group col-md-6 contfeild">
          <label htmlFor="inputEmail4" className='femail'>Your Email ID</label>
          <input type="email" className="form-control" id="inputEmail4" placeholder="Enter Your Email Id " />
        </div>
      </div>
      <div className="form-row">
                        <div className="form-group col-md-6 contfeild">
                          <label htmlFor="inputState" className="fcategary">
                            Category’s{' '}
                          </label>
                          <select id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
                            <option value="Corporate Events">Corporate Events</option>
                            <option value="Experiential Travel">Experiential Travel</option>
                            <option value="Corporate Gifting">Corporate Gifting</option>
                            <option value="Digital Marketing">Digital Marketing</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6 contfeild">
                          <label htmlFor="inputState" className="fservices">
                            Services{' '}
                          </label>
                          <select id="inputServices" className="form-select selectcat">
                            <option selected>Select Your Services </option>
                            {filterServices(selectedCategory).map((service, index) => (
                              <option key={index}>{service}</option>
                            ))}
                          </select>
                        </div>
      <div className="form-group">
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
        <label className="form-check-label frcheckbox" htmlFor="invalidCheck2">
        By Clicking The Button Below, You Agree To Our Terms Of Service And Acknowledge Our Global Privacy Policy.        </label>
      </div>
    </div>
    </div>
      <button type="submit" className="btn sub" disabled={loading}>
        SUBMIT {loading && <FontAwesomeIcon icon={faSpinner} spin />}
        </button>
    </form>
    
        </div>
                  {submitted && (<div className="alert alert-success" role="alert">
                        THANK YOU FOR YOUR INTREST!
                      </div>
                    )}
</div>
          </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    
  );
}

export default ContactForm;
