import "./BlogSection.css";
// import "./About.css";

import { Link } from "react-router-dom";
import aboutbannerImage from "./images/aboutbanner.png"; // Import the mic image
import image2Image from "./images/image2.png";
import image3Image from "./images/image3.png";
import image4Image from "./images/image4.png";
import image5Image from "./images/image5.png";
// import GridImage from "./images/Grid.png";

import oneImage from "./images/one.png";
import twoImage from "./images/two.png";
import threeImage from "./images/three.png";

import blog1Image from "./images/blog1.png";
import blog2Image from "./images/blog2.png";
import blog3Image from "./images/blog3.png";

import Number1Image from "./images/Number1.png";
import Number2Image from "./images/Number2.png";
import Number3Image from "./images/Number3.png";

import topRImage from "./images/topR.png";
import BottomLImage from "./images/BottomLImage.png";
import gpImage from "./images/gp.png";

import team1Image from "./images/team1.png";
import team2Image from "./images/team2.png";
import team3Image from "./images/team3.png";

import Social1Image from "./images/Social1.png";
import Social2Image from "./images/Social2.png";
import Social3Image from "./images/Social3.png";

import F1Image from "./images/F1.png";
import F2Image from "./images/F2.png";
import F3Image from "./images/F3.png";
import F4Image from "./images/F4.png";
import F5Image from "./images/F5.png";
import F6Image from "./images/F6.png";

import post11Image from "./images/post11.png";
import post12Image from "./images/post12.png";
import post13Image from "./images/post13.png";
import post14Image from "./images/post14.png";

import BL1Image from "./images/BL1.png";
import BL2Image from "./images/BL2.png";
import BL3Image from "./images/BL3.png";

import Date1Image from "./images/Date1.png";
import Date2Image from "./images/Date2.png";
import Date3Image from "./images/Date3.png";

import Bg12Image from "./images/Bg12.png";
import numberImage from "./images/number.png";
import Blogslider from "./Aboutlayout/Blogslider";
import LayoutSection from "./Aboutlayout/layoutsection";
import Layoutbannersection from "./Aboutlayout/layoutbannersection";
import Imagelayout from "./Aboutlayout/imagelayout";
import MyComponent from "./Aboutlayout/bottomLayout";
import BlogComponent from "./Aboutlayout/bloggridbottom";
/*import { width } from "@fortawesome/free-solid-svg-icons/fa0";*/
/*import { Row } from "react-bootstrap";*/
import Slide from "./slide";
import Callback from "./callback";
import vui11Image from "./images/vui11.png"; 
import vui21Image from "./images/vui21.png"; 
import vui31Image from "./images/vui31.png"; 
import vui41Image from "./images/vui41.png"; 
import aboutus1Image from "./images/aboutus1.jpg"; 
import aboutgiftingImage from "./images/aboutgifting.png"; 
import whoImage from "./images/who.jpg"; 
import whatImage from "./images/what.jpg"; 
import whyImage from "./images/why.jpg"; 
import ProcessComponent from "./Aboutlayout/aboutadd";

function UncontrolledExample() {
  // Sample data for blogs
  const blogsData1 = [
    {
      id: 1,
      title: "Blog 1",
      image: "https://picsum.photos/200/300?random=1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget augue eget lacus euismod tempus.",
    },
    {
      id: 2,
      title: "Blog 2",
      image: "https://picsum.photos/200/300?random=2",
      content:
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    {
      id: 3,
      title: "Blog 3",
      image: "https://picsum.photos/200/300?random=3",
      content:
        "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Suspendisse potenti.",
    },
  ];

  // Sample data for blogs
  const blogsData = [
    {
      image2Url: Number1Image,
      id: 1,
      title: "Who We Are?",
      imageUrl: whoImage,
      content:
        "From concept to execution, we're your partners in crafting seamless and impactful event experiences that leave a lasting impression.  ",
    },
    {
      image2Url: Number2Image,
      id: 2,
      title: "What We Do?",
      imageUrl: whatImage,
      content:
        "We organise corporate events, curate marketing strategies, help people explore dream destinations, and assist clients in making their communities stronger. ",
    },
    {
      image2Url: Number3Image,
      id: 3,
      title: "Why Choose Us?",
      imageUrl: whyImage,
      content:
        "Experience the difference of working with a team that values your time, budget, and vision. We're committed to providing the highest level of service with exceptional value.  ",
    },
  ];

  return (
    <>
      <div className="row mb-2 gx-2" id="mbook">
        <div>
          <Callback />
        </div>
        <style>
          {
          `
      #aboutcol #htimg{
           height: 283px !important;
       }
                @media only screen and (min-width: 428px) and (max-width: 430px){
                  .slick-dots li button:before{
                    font-size: 50px !important;
                  }
                  #bottomblog .Bloghpost12 {
                    margin-left:70px !important
                  }
                }
               
          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
          .slick-dots li.slick-active button:before{
            color: #000000 !important;
          }
          .slick-dots li button:before{
            font-size: 150px;
          }
          img.img-fluid2 {
            position: relative;
            top: 236px;
        }
        #backgroundhead{
          font-size: 157px !important;
          transform: translate(-20%, 24%) !important;
          margin-top:-200px;
        }
          div#Blogslider {
            padding: 70px 0px;
        }
          #bottomblog {
           margin-top: 30px !important; 
        }
         #bottomblog #bgblog{
          position: static !important;
          transform: translate(68%, 0%);
          opacity: 5%;
          }
          #aboutcol .img-fluid{
                width:468px;
                height: 410px !important;
              }
              .Head-2{
                position:static !important;
              }
          #LayoutSection{
            margin:0px 0px !important;
          }
          #bottomblog .bgmrg {
            margin-top: 0px !important;
        }
        #bottomblog #bgblog {
          width: 1094px;
          margin-top: -20%;
          height: 442px;
          position: relative;
          left: 918px;
          top: 61px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 301px;
          line-height: 442px;
          display: flex;
          align-items: center;
          opacity: 0.05;
      }
      @media only screen and (max-width: 414px) and (max-height: 896px) {
        #bottomblog .Bloghpost12 {
          margin-top: 0px !important;
          margin-left: 70px !important;
          width: 100%;
          font-weight: bolder;
          color: #000000;
          font-size: 36px !important;
          /* display: flex; */
          line-height: 54px !important;
          padding-bottom: 100px;
          font-family: Helvetica;
      }
    }

              /* For devices with a maximum width of 768px (typical for tablets and mobile phones) */
                @media only screen and (max-width: 768px) {
                  /* Your CSS styles for mobile devices go here */
                  #aboutcol{
                    margin-top:-50%;
                  }
                  #atimg{
                    margin-top:-20px;
                  }
                  #aboutcol .img-fluid {
                    width: 270px !important;
                    max-width: 270px !important;
                    height: 140px !important;
                }
                #backgroundhead{
                  font-size: 65px !important;
                  transform: translate(-20%, 24%) !important;
                  margin-top: 0px;
              
                }
                .bannertxt1{
                  transform: translate3d(2px, -6px, 0px) !important;
                }
                  #aboutcol .col-md{
                    flex-shrink: 0;
                    width: 100%;
                    max-width: 50%;
                    padding-right: 0px;
                    padding-left: 0px;
                    margin-top: 0px;
                }
                #bottomblog #bgblog {
                  /* position: static !important; */
                  /* transform: translate(3%, 0%); */
                  display: none;
              }
              #bottomblog .Bloghpost12 {
                margin-top: 0px !important;
                margin-left: 20px;
                width: 100%;
                font-weight: bolder;
                color: #000000;
                font-size: 36px !important;
                /* display: flex; */
                line-height: 54px !important;
                padding-bottom: 100px;
                font-family: Helvetica;
            }
            #bottomblog {
              margin-top:5% !important;
          }
            #bottomblog .Bloghposth6 {
              margin-top: -55px !important;
              margin-left: 20px !important;
              width: 25%;
              font-weight: bolder;
              color: #ED2756;
              letter-spacing: 0.7px;
              font-size: 18px;
              /* display: flex; */
              font-family: Helvetica;
          }
                }
                @media only screen and (min-width: 1280px) and (max-width: 1380px) {
                  /* Styles for MacBook devices */
                  #bottomblog #bgblog {
                    position: static !important;
                    transform: translate(46%, 5%);
                }
                div#aboutcol {
                  margin-top: -20px !important;
              }
              .img-fluid2{
                height:100px !important;
              }
                #bottomblog .Bloghposth6 {
                  margin-top: 13px;
                  margin-left: 76px !important;
                  width: 25%;
                  font-weight: bolder;
                  color: #ED2756;
                  letter-spacing: 0.7px;
                  font-size: 18px;
                  /* display: flex; */
                  font-family: Helvetica;
              }
                }
                .sdv{
                  margin-top:-120px;
                }
                @media screen and (min-width: 1920px) and (max-width: 1920px) {

                .sdv {
                  margin-left: -69px !important;
                  }
                }

 @media (max-width: 767px) {

                #aboutcol #htimg{
           height: 189px !important;
       }
          }
          `}
        </style>{" "}
        {/* Add margin-bottom and decrease horizontal gap */}
        <div className="col" style={{ position: "static" }}>
          <img
            src={aboutus1Image}
            id="atimg"
            alt="About Banner"
            style={{
              width: "100%",
              height: "400px",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          <div
            style={{
              position: "static",
              marginTop: "-10%",
              transform: "translate( 5%, -100%)",
            }}
          >
            <p className="bannertxt">
              VUI Hospitality <br />
              about us{" "}
            </p>
          </div>
        </div>
      </div>

      <div id="aboutcol" className="row mb-3 gx-md-2">
        {/* Add margin-bottom and decrease horizontal gap for medium screens */}
        <div className="col-md" style={{ position: "relative" }}>
          <img src={vui11Image} alt="About Banner" className="img-fluid" id="htimg"/>
          <div
            className="position-absolute start-0 translate-middle-y"
            style={{ top: "84%" }}
          >
            <p className="bannertxt1">
              CORPORATE <br />
              EVENTS <br />
              services{" "}
            </p>
          </div>
        </div>
        <div className="col-md" style={{ position: "relative" }}>
          <img src={vui21Image} alt="About Banner" className="img-fluid" id="htimg"/>
          <div
            className="position-absolute start-0 translate-middle-y"
            style={{ top: "84%" }}
          >
            <p className="bannertxt1">
              EXPERIENTIAL
              <br />
              TRAVEL
              <br />
              services{" "}
            </p>
          </div>
        </div>
        <div className="col-md" style={{ position: "relative" }}>
          <img src={aboutgiftingImage} alt="About Banner" className="img-fluid" id="htimg"/>
          <div
            className="position-absolute start-0 translate-middle-y"
            style={{ top: "84%" }}
          >
            <p className="bannertxt1">
              CORPORATE
              <br />
              GIFTING <br />
              services{" "}
            </p>
          </div>
        </div>
        <div className="col-md" style={{ position: "relative" }}>
          <img src={vui41Image} alt="About Banner" className="img-fluid" id="htimg"/>
          <div
            className="position-absolute start-0 translate-middle-y"
            style={{ top: "84%" }}
          >
            <p className="bannertxt1">
              DIGITAL
              <br />
              MARKETING <br />
              services{" "}
            </p>
          </div>
        </div>
        {/* <div
          className="about2"        >
          <h1>About Us</h1>
        </div> */}
        {/*We Love to Create */}
        <div id="LayoutSection">
          <LayoutSection />
        </div>
        {/*Our differentiate*/}
        <div>
          <div id="Blogslider" className="container my-3">
            {/* <h2>Latest Blogs</h2> */}
            {/* <img src={numberImage} alt="Number" className="Number" /> */}

            <Blogslider blogs={blogsData} />
          </div>
        </div>

        <div id="aboutdd">
          <ProcessComponent />
        </div>

        <div id="layoutbannersectionmain">
          <Layoutbannersection />
        </div>

        {/*Our Expert Team image*/}
        <div className="container my-3">
          {/* <div id="Teamtxt">
          <h2>MEET OUR EXPERTS</h2>
          <p>Our mission is to create lasting memories for our clients through exceptional service and unique experiences.</p>
          </div> */}
          <MyComponent />
        </div>
        <div id="Imagelayout">
          <Imagelayout />
        </div>

        <div id="bottomblog">
          <div className="bgmrg">
            <div className="col">
              <h6 className="Bloghposth6">INSIGHTS</h6>
              <h1 className="Bloghpost12">
                LATEST BLOG &<br></br>NEWS EVENT{" "}
              </h1>
            </div>
            <h1 id="bgblog">blogs</h1>
            <div className="sdv">
              <BlogComponent />
            </div>
          </div>
        </div>
      </div>
      {/* <BlogComponent/>    */}
    </>
  );
}
export default UncontrolledExample;
