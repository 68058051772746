import React from "react";
import { Link } from "react-router-dom";
import GridImage from "../images/Grid.png"; // Adjusted import path
import aboutbelowImage from "../images/aboutbelow.png"; // Adjusted import path
import newmmtImage from "../images/newmmt.png";
// Functional Component
const LayoutSection = () => {
  return (
    <div>
      <style>
        {`
        #abtbelow{
        margin-left:-7%;
        margin-top:-6%
        }
          div#layoutSection {
            -bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: 0%;
            margin-right: 0px;
            margin-left: 14%;
            /* flex-direction: column; */
        }
          #backgroundhead{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 900;
            font-size: 352.542px;
            line-height: 405px;
            /* identical to box height, or 115% */
            display: flex;
            align-items: center;
            text-transform: capitalize;
            transform: translate(-20%, -19%);
            color:  #2828281A;
            margin-bottom: -5%;

            opacity: 0.5;
          }
              /* For devices with a maximum width of 768px (typical for tablets and mobile phones) */
                @media only screen and (max-width: 768px) {
                  /* Your CSS styles for mobile devices go here */
                  #layoutSection img {
                    transform: translate(-2%, 15%);
                }
                #layoutSection p{
                  width:360px !important;
                  color: rgba(0, 0, 0, 0.5);
                  font-size: 14px;
                }
                #backgroundhead {
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 900;
                  font-size: 73px !important;
                  line-height: 68px;
                  display: flex;
                  align-items: center;
                  text-transform: capitalize;
                  transform: translate(-6%, 69%) !important;
                  color:  #28282833;
                  opacity: 0.5;
              }
              #Pr{
                font-size:16px;
              }
              #topHeaD{
                font-size:43px !important;
              }
                div#layoutSection {
                  -bs-gutter-x: 1.5rem;
                  --bs-gutter-y: 0;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 0%;
                  margin-right: 0px;
                  margin-left: 5%;
                   flex-direction: column; 
              }
                }

                @media only screen and (min-width: 1280px) and (max-width: 1600px) {
                  /* Styles for MacBook devices */
                  #backgroundhead {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 900;
                    font-size: 226px;
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;
                    transform: translate(-20%, -26%);
                    color: #28282833;
                    margin-bottom: -2%;
                    opacity: 0.5;
                
                  }
                }
                   @media (max-width: 767px) {

 #abtbelow{
        margin-left:0%;
        margin-top:0%
        }
        }
              
          `}
      </style>
      <div className="row" id="layoutSection">
        <h1 id="backgroundhead">About Us</h1>
        <div className="col">
          <h1 id="topHeaD" style={{ color: "#000000", fontWeight: "bolder" }}>
            We love turning moments into memories.
          </h1>
          <br></br>
        {/*  <h5
            id="Pr"
            style={{
              letterSpacing: "0.9px",
              fontWeight: "400",
              background:
                "linear-gradient(285deg, #d86c6c, #d90b0b), linear-gradient(90deg, #b04241 6.7%, #F99B62 47.97%, #EE3357 90.07%)",
              backgroundClip: "text",
              webkitTextFillColor: "transparent",
            }}
          >
            Successfully Completed 7,000+ Projects!
          </h5>*/}
          <br></br>
          <p style={{ width: "668px" }}>
          Welcome to VUI Hospitality, your ultimate partner for crafting significant moments and treasured experiences. With VUI Hospitality, engagement and innovation aren't just buzzwords, they're the foundation of every successful event. 
            <br></br>
            <br></br>
            With a scrapbook of memorable events, we're all about turning clients' expectations into applause. Our commitment and expertise set the stage, while your vision creates the masterpiece. 
          </p>
          <Link to="/contact">
            <button id="btnabout" className="btn-1">
              CONTACT US
            </button>
          </Link>
        </div>
        <div className="col">
          <img src={newmmtImage} alt="About Banner" id="abtbelow"/>
        </div>
      </div>
    </div>
  );
};

export default LayoutSection;
