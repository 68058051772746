import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Artboard2ncopyImage from "../images/Artboard2ncopy.png";
import Artboard2ncopy3Image from "../images/Artboard2ncopy3.png";
import Artboard2copyn2Image from "../images/Artboard2copyn2.png";
import { Link } from 'react-router-dom';

const BlogComponent = () => {
  return (
    <div className="post-row">
      <style>{`
           
     .post-row .slick-dots li {
        left: -30px ;
      }
      @media only screen and (min-width: 1440px) and (max-width: 1440px){
        .post-row {
          margin: 20px 0;
          padding: 0px 0px !important;
        }
      }
      @media (max-width: 767px) {
        .des2{
          margin-left: -34px;
        }
        .des3{
          margin-left: -53px;
        }
      .post-row .slick-dots li {
        left: 0px !important;
      }
    }
         @media only screen and (min-width: 428px) and (max-width: 430px){
          .des2{
            margin-left: -3px;
          }
          .des3{
            margin-left: -3px !important;
          }
      .sdv {
        margin-left: 20px;
    }
  }
      @media only screen and (max-width: 360px) and (max-height: 800px) {
        .des3{
          margin-left: -73px !important          ;
        }
        .post-row .slick-dots li button:before{
          font-size:100px !important
        }
        .sdv {
          margin-left: -20px;
      }
      }

/* Media query for MacBook devices */
@media only screen and (min-width: 1280px) {
  .post-row {
    padding: 0px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    
    column-gap: 5%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .post {
    width: calc(33.33% - 10px); /* Adjust width for three posts in a row */
    margin-right: 10px; /* Adjust margin between posts */
  }

  .post-row .slick-list {
    display: block; /* Hide the slider on desktop */
  }

  .post-row .slick-list {
    height: 45vh !important;
    width: 100% !important;
  }

  .post-row .post-details {
    position: relative;
    z-index: 1;
    top: 50px;
    width: 40%;
  }

  .post-row .slick-slider {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }

  .post-row .contentbox {
    padding: 0px 0px 0px 0px;
    flex: 1;
  }

  .post-row #bgblog {
    position: relative !important;
    z-index: 2 !important;
  }
}


        /* Common styles for all devices */
        .post-row #bgblog{
          position:static !important;
          transform: translate(53%, 0%);
        }
        /* Ensure slick dots are visible */
        .post-row .slick-slider {
          padding-left: 4% !important;
          padding-right: 3% !important;
          padding-top:118px !important;
          height: 60vh !important;
          width:100% !important;
      }
        .post-row .slick-dots {
          display: block !important; /* Ensure dots are displayed */
          position:static !important; /* Adjust position if necessary */
          bottom: 10px; /* Adjust vertical position */
          left: 0; /* Adjust horizontal position */
          right: 0;
          text-align: center; /* Center dots */
          z-index: 999; /* Ensure dots are above other content */
        }
        .post-row .slick-dots li button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          padding: 5px;
          cursor: pointer;
          color: #d61717;
          border: 0;
          outline: none;
          background: #a0161600;
      }
      .post-row .slick-dots li button:before{
        position:static !important;
      }

        
        .post-row #bgblog {
          /* position: static !important; */
          /* transform: translate(3%, 0%); */
          display: none;
      }
      .post-row .Bloghpost12 {
        margin-top: 0px !important;
        margin-left: 72px;
        width: 100%;
        font-weight: bolder;
        color: #000000;
        font-size: 36px !important;
        /* display: flex; */
        line-height: 54px !important;
        padding-bottom: 100px;
        font-family: Helvetica;
    }
  //   .post-row {
  //     margin-top: 82% !important;
  // }
  .post-row .Bloghposth6 {
      margin-top: 13px !important;
      margin-left: 72px !important;
      width: 25%;
      font-weight: bolder;
      color: #ED2756;
      letter-spacing: 0.7px;
      font-size: 18px;
      /* display: flex; */
      font-family: Helvetica;
  }
        .post-row h5{
          font-family: 'Rubik';
          font-style: italic;
          font-weight: 400;
          font-size: 13.9283px;
          line-height: 17px;
          display: flex;
          align-items: stretch;
          letter-spacing: 1.39283px;
          color: #F56465;
        }

        .post h6{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 900;
          font-size: 17px;
          line-height: 23px;
          display: flex;
          color: #000000;
        }
        .post p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          align-items: center;
          color: #282828;
        }
        
        .post-row {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin: 20px 0;
          overflow:hidden; /* Enable horizontal scrolling */
          -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
          scrollbar-width: none; /* Hide scrollbar */
          -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
        }

        .post-row::-webkit-scrollbar {
          display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
        }

        .post {
          display: flex !important;
          flex-direction: row !important;
          width: 100% !important;
          box-sizing: border-box !important;
          /* border-radius: 5px !important; */
          column-gap: 9% !important;
           margin-right: 0px !important; 
      }
        .post img {
          width: 100%;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        .btnRm{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 12.9334px;
          z-index: 99 !important;
          position: relative !important;
          line-height: 26px;
          /* identical to box height, or 200% */
          display: flex;
          align-items: center;
          letter-spacing: 0.646672px;
          text-transform: uppercase;

          color: #000000;
        }

        .date-box {
          position: absolute;
          top: -44px;
          right: -44px;
          width: 52px;
          height: 52px;
          background: linear-gradient(180deg, #F56164 0%, #F05D77 100%);
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          text-align:center;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: -0.37308px;
          color: #FFFFFF;
        }

        .contentbox {
          padding: 0px 35px;
          flex: 1;
        }
        .post-row .slick-slide {
          /* height: 186px !important; */
          background: transparent;
          box-shadow: none;
          border-radius: 9.15849px;
          /* margin-right: 20px; */
          max-width: 359px !important;
          width: 369px !important;
          padding-left: 6px;
          padding-right: 24px;

      }

      .post-details {
        position: relative;
        z-index: 1;
        top: 50px;
        width: 30%;
    }
    .post-row .slick-list {
      height: 35vh !important;
      width: 100% !important;
      margin-top:-90px;
    }
        .post img {
          width: 148px !important;
          height: 79px !important;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
      }
      
      .post-row .slick-slide {
        /* height: 186px !important; */
        background: #FFFFFF;
        /* box-shadow: none; */
        /* border-radius: 9.15849px; */
        /* margin-right: 20px; */
        max-width: 370px !important;
        width: 370px !important;
        padding-left: 4px;
        padding-right: 0px;
    }
    #bottombloghead{
        margin-bottom:50px !important;
    }
    #bottomblog .slick-dots li button:before{
      position: static !important;
    }
    #bottomblog .slick-dots{
      position: static !important;
      
      transform: translate(0%, -30%);
    }

        /* Media query for desktop devices */
        @media only screen and (min-width: 768px) {
          .post-row {
            display: flex;
            flex-wrap: nowrap;
            height:70vh;
            justify-content: space-between;
            margin: 20px 0;
            padding: 0px 40px !important;
            column-gap: 5%;
            overflow:hidden;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            -ms-overflow-style: none;
            flex-direction: column;
        }
        .post {
          display: flex !important;
          flex-direction: row !important;
          width: 100% !important;
          box-sizing: border-box !important;
          margin-top: 30px;
          /* border-radius: 5px !important; */
          column-gap: 20% !important;
          margin-right: 0px !important;
      }
        .post-row .slick-slide {
          /* height: 186px !important; */
          background: transparent;
          box-shadow: none;
          border-radius: 9.15849px;
          /* margin-right: 20px; */
          max-width: 540px !important;
          width: 424px !important;
          padding-left: 6px;
          padding-right: 24px;
      }
          .post img {
            width: 217px !important;
            height: 108px !important;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .date-box {
          position: absolute;
          top: -75px;
          right: -66px;
          width: 75px;
          height: 75px;
          background: linear-gradient(180deg, #F56164 0%, #F05D77 100%);
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          text-align:center;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: -0.37308px;
          color: #FFFFFF;
        }
          
        .post {
          width: calc(33.33% - 10px); /* Adjust width for three posts in a row */
          margin-right: 10px; /* Adjust margin between posts */
        }
        
        .post-row .slick-list {
            display: block; /* Hide the slider on desktop */
            
          }
          .post-row .slick-list {
            height: 45vh !important;
            width: 100% !important;
            // z-index: -1 !important;
          }
          .post-row .post-details {
            position: relative;
            z-index: 1;
            top: 50px;
            width: 40%;
        }
        .post-row .slick-slider{
        padding-left: 4% !important;
        padding-right: 4% !important;  

        }
        .post-row .contentbox {
          padding: 0px 0px 0px 0px;
          flex: 1;
      }
      .post-row #bgblog{
        position: relative !important;
        z-index: 2 !important;
      }
    }
      .post-row{
        marhin-top:-150px !important;
      }
/* Default state: hide the dots */
.post-row .slick-dots {
    display: none !important;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .post-row .slick-dots {
        display: block !important;
    }
}
@media only screen and (min-width: 1280px) and (max-width: 1280px){

.post-row .slick-slider {
        padding-left: 0% !important;
        padding-right: 0% !important;
    }
	.post-row .slick-list {
        height: 45vh !important;
        width: 168vh !important;
        /* margin-left: -58px !important; */
    }
	    .post-row .slick-slide {
        padding-right: 31px;
		}
      }
   
      `}</style>

      {/* Rendering posts */}
      <PostSlider />
    </div>
  );
};

const PostSlider = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detect if the device is mobile
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    setIsMobile(isMobileDevice);
  }, []);

  if (!isMobile) {
    // Return slider for desktop devices
    return <DesktopSlider />;
  }

  // Return slider for mobile devices
  return <MobileSlider />;
};

const DesktopSlider = () => {
  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <></>, // Empty component to disable previous arrow
    nextArrow: <></>,  // Empty component to disable next arrow
    initialSlide: 0 // Start from the first slide
  };
  const sliderRef = useRef();
 
  return (
    <div>
      {/* <div  id="bottombloghead" className="col"><h6 className="Bloghposth6">INSIGHTS</h6>
        <h1 className="Bloghpost12">LATEST BLOG <br></br>NEWS & EVENT </h1></div>
        <h1 id="bgblog">blogs</h1> */}
    
    
      <Slider {...sliderSettings} ref={sliderRef}>
        <PostCard image={Artboard2ncopyImage} date="May 10th" title2="#Travel" title="How Hotel Management Ensures a Perfect Guest Experience"  context="When it comes to crafting truly memorable experiences for guests, there's a world of difference between the roles of hospitality service and hotel management ..." link="/blogdescription" />
        <PostCard image={Artboard2ncopy3Image} date="April 16th" title2="#Event" title="The World of Event Management Companies" context="Hosting unforgettable events is no easy feat in today's fast-paced world. Whether it's a high-powered corporate conference, a fairytale wedding, or a dazzling..." link="/theworldofevent" />
        <PostCard image={Artboard2copyn2Image} date="March 31st"  title2="#Travel" title="How Thoughtful Touches Can Transform a Guest's Stay " context="These days, people have a lot of choices when they travel or eat out. So, if you want them to pick your place, you need to show them amazing hospitality. That means ..." link="/gueststay"  />
      </Slider>
      </div>
   
  );
};

const MobileSlider = () => {
  const sliderSettings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <></>, // Empty component to disable previous arrow
    nextArrow: <></>  // Empty component to disable next arrow
  };

  return (
    
      <Slider {...sliderSettings}>
     <div className='des1'>   <PostCard image={Artboard2ncopyImage} date="May 10th" title2="#Travel" title="How Hotel Management Ensures a Perfect Guest Experience " context="When it comes to crafting truly memorable experiences for guests, there's a world of difference between the roles of hospitality service and hotel management ..." link="/blogdescription"  /></div>
     <div className='des2'>   <PostCard image={Artboard2ncopy3Image} date="April 16th" title2="#Event" title="The World of Event Management Companies" context="Hosting unforgettable events is no easy feat in today's fast-paced world. Whether it's a high-powered corporate conference, a fairytale wedding, or a dazzling ..." link="/theworldofevent"  /></div>
     <div className='des3'>    <PostCard image={Artboard2copyn2Image} date="March 31st" title2="#Travel" title="How Thoughtful Touches Can Transform a Guest's Stay " context="These days, people have a lot of choices when they travel or eat out. So, if you want them to pick your place, you need to show them amazing hospitality. That means ..." link="/gueststay"  /></div>
      </Slider>
    
  );
};

const PostCard = ({ image, date, title,title2, context, link }) => {
  return (
    <div className="post">
      <div className="post-details">
        <img src={image} alt="Post Image" />
        <div className="date-box">{date}</div>
      </div>
      <div className="contentbox">
        <h5>{title2}</h5>
        <h6>{title}</h6>
        <p>{context}</p>
        <div><Link to={link}> <h4 className="btnRm"> Read More</h4></Link></div>
      </div>
    </div>
  );
};

export default BlogComponent;
