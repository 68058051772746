import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';

const responsive = {
    0: { items: 2 },
    568: { items: 1 },
    1024: { items: 2 },
};

const items = [
    { id: 1, label: 'View all', to: '/gallery', hidden: false },
    { id: 2, label: 'CORPORATE EVENTS', to: '/corporate event', hidden: false },
    { id: 3, label: 'EXPERIENTIAL TRAVEL', to: '/corporate events2', hidden: false },
    { id: 4, label: 'CORPORATE GIFTING', to: '/Brandactivation', hidden: false  },
    { id: 5, label: 'DIGITAL MARKETING', to: '/hotelbooking', hidden: false  },
];

const TabSlide5 = () => {
    const digitalmarketingIndex = items.findIndex(item => item.label === 'DIGITAL MARKETING');
    const [activeIndex, setActiveIndex] = useState(digitalmarketingIndex);

    const handleItemClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <>
           <style>
                {`
                @media only screen and (max-width: 414px) and (max-height: 896px) {
                    .card-img-top {
                        /* height: 601px; */
                        /* max-height: 334px; */
                        /* padding-left: 7px; */
                        width: 100% !important;
                        max-width: 100%;
                        margin-left: 0px ;
                        height: 100% !important;
                        max-height: 100%;
                    }
                }
                .alice-carousel__next-btn {
                    display:none;
                }
                .alice-carousel__prev-btn {
                    display:none;
                }
                .alice-carousel__dots {
                    display: none;
                }
                .alice-carousel {
                    position: relative;
                    width: 100%;
                    margin: auto;
                    direction: ltr;
                }
                .alice-carousel__stage {
                    position: relative;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    white-space: nowrap;
                    transform-style: flat;
                    -webkit-transform-style: flat;
                    backface-visibility: hidden;
                    -webkit-backface-visibility: hidden;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    align-content: center;
                    align-items: center;
                }
                a#tbs {
                    width: max-content !important;
                }
                .item.active {
                    background: linear-gradient(90deg, #E66664 6.7%, #F99B62 47.97%, #EE3357 90.07%);
                    border-radius: 10px;
                    color: white;
                    
                }
                .item.active .nav-link {
                    color: white !important;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-weight: 500 !important;
                    font-size: 16px !important;
                }
                #tbs  {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600 !important;
                    font-size: 15px !important;
                    text-transform: uppercase;
                    color: rgba(0, 0, 0, 0.6) ;
                }
                .nav-link{
                    color: rgba(0, 0, 0, 0.6) !important;

                }
                @media (min-width: 768px) {
                    .hidden-desktop {
                        display: none !important;
                    }
                    
                    
                }
                
                @media (max-width: 767px) {
                    .hidden-mobile {
                        display: none !important;
                        
                    }

                }
                @media (min-width: 767px) {

                    img.card-img-top {
                        width: 100% !important;
        max-width: 100% !important;
                }

               
            }
            @media (max-width: 1280px) and (max-height: 800px) {
            #hotelb .card-img-top {
                height: 290px !important;
                max-height: 100% !important;
                padding-left: 0px;
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
                width: 100% !important;
            }
            .card-body.galdetail {
                margin-left: 0px;
            }
        }
                `}
            </style>
            <AliceCarousel
                mouseTracking
                items={items.map((item, index) => (
                    <div
                        key={item.id}
                        className={`item ${index === activeIndex ? 'active' : ''} ${item.hidden ? 'hidden-mobile' : 'hidden-desktop'}`}
                        data-value={item.id}
                        onClick={() => handleItemClick(index)}
                    >
                        <Link to={item.to} className="nav-link" id="tbs">
                            {item.label}
                        </Link>
                    </div>
                ))}
                responsive={responsive}
                controlsStrategy="alternate"
            />
        </>
    );
};

export default TabSlide5;
