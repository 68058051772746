import './App.css'; // Import CSS file

import React from 'react';
import Navbar from './component/Navbar';
import Textform from './component/Textform';
import About from './component/About';
import Contact from './component/contact';
import Gallery from './component/gallery';
import Footer from './component/footer'; // Corrected import statement
import Blog from './component/Blog';
import Bookappointment from './component/bookappointment';
import Exhibitionshowservices from './component/Exhibitionshowservices';
import Services from "./component/Services"; // Import Services component
import Brandactivationservices from './component/Brandactivationservices';
import Hotelbookingforcorporates from './component/hotelbookingforcorporates';
import ScrollToTop from './component/scrolltotop';
import Blogdescription from './component/blogdescription';
import CorporateEvent from './component/corporate events';
import CorporateEvent2 from './component/corporate events2';
import Brandactivation from './component/Brandactivation';
import Hotelbooking from './component/hotelbooking';
import Callback from './component/callback';
import Tabslide  from './component/tabslide';
import Tabslide2  from './component/tabslide2';
import Tabslide3  from './component/tabslide3';
import Tabslide4  from './component/tabslide4';
import Tabslide5  from './component/tabslide5';
import Tabslide6  from './component/tabslid';
import Theworldofevent from './component/theworldofevent';
import Gueststay from './component/gueststay';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <div>
        <Navbar title="React JS"/>
        <Routes>
          <Route path="/" element={<Textform />} /> {/* Set Textform as the homepage */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/bookappointment" element={<Bookappointment />} />
          <Route path="/services" element={<Services />} /> {/* Use Services component */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/Exhibitionshowservices" element={<Exhibitionshowservices />} />
          <Route path="/Brandactivationservices" element={<Brandactivationservices />} />
          <Route path="/blogdescription" element={<Blogdescription />} />

           <Route path="/hotelbookingforcorporates" element={<Hotelbookingforcorporates />} />
           <Route path="/corporate event" element={<CorporateEvent />} />
           <Route path="/corporate events2" element={<CorporateEvent2 />} />
           <Route path="/Brandactivation" element={<Brandactivation />} />
           <Route path="/hotelbooking" element={<Hotelbooking />} />
           <Route path="/callback" element={<Callback />} />
           <Route path="/tabslide" element={<Tabslide/>} />
           <Route path="/tabslide2" element={<Tabslide2/>} />
           <Route path="/tabslide3" element={<Tabslide3/>} />
           <Route path="/tabslide4" element={<Tabslide4/>} />
           <Route path="/tabslide5" element={<Tabslide5/>} />
           <Route path="/tabslid" element={<Tabslide6/>} />
           <Route path="/theworldofevent" element={<Theworldofevent />} />
           <Route path="/gueststay" element={<Gueststay />} />

        </Routes>
        <Footer /> {/* Placing Footer component inside the main div */}
      </div>
      
    </Router>
  );
}

export default App;
