import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MananImage from "../images/Manan.png";
import team2Image from "../images/teamimg2.jpeg";
import NitinImage from "../images/Nitin.png";
//import t1Image from "../images/t1.png";
import AkashImage from "../images/Akash.png";
import SakshiformalImage from "../images/Sakshiformal.png";

import Social1Image from "../images/Social1.png";
import Social2Image from "../images/Social2.png";
import Social3Image from "../images/Social3.png";
import vui31Image from "../images/vui31.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MyComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint as needed
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
  };

  const teamMembers = [
    {
      image: MananImage,
      name: "Manan Prashar",
      heading: [
        "Co-founder and Business Head at VUI Hospitality",
        <br />,
        "Helping clients successfully plan & execute prestigious events",
        <br />,
        "Strongly committed to customer satisfaction",
      ],
    },
    {
      image: AkashImage,
      name: "Akash Manchanda",
      heading: [
        "Head of Marketing ",
        <br />,
        "Expert in ATL and BTL marketing events",
      ],
    },
    {
      image: NitinImage,
      name: "Nitin Kohli",
      heading: [
        "Head of Corporate Affairs",
        <br />,
        "Veteran hospitality specialist",
      ],
    },
    {
      image: SakshiformalImage,
      name: "Sakshi Kansal",
      heading: [
        "Manager - Customer Experience",
        <br />,
        "Leads cross-functional operations",
      ],
    },
  ];

  return (
    <div id="Team">
      <style>
        {`
   @media only screen and (min-width: 428px) and (max-width: 430px){
        #teamCard li{
top:-50px
        }
      }
        @media only screen and (max-width: 360px) and (max-height: 800px) {
          #teamCard .slick-dots li button:before {
            font-size:100px !important
          }
        }

        div#Team{
          padding-bottom:20px !important;
        }
        #Team .col-4 {
          border: 2px solid #000000;
          padding: 0px;
          width: 24% !important;
      }
       
        #Team .row{
          gap: 0px !important;
              justify-content: space-evenly;
        }
        #teamCard .card-title {
          background: linear-gradient(285deg, #d86c6c, #d90b0b), linear-gradient(90deg, #b04241 6.7%, #F99B62 47.97%, #EE3357 90.07%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          color: transparent; /* Use 'color' instead of 'text-fill-color' */
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
              margin-top: 248px;
          
      }
      
        #Teamtxt h2 {
          color: rgb(0, 0, 0);
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          letter-spacing: 0px;
          width: 100% !important;
          padding: 0px 4%;
          margin-top:50px;
      }
      #Teamtxt p {
        color: rgba(0, 0, 0, 0.5);
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0px;
        width: 100% !important;
        padding: 0px 4%;
    }
        #teamCard li{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          color: #ffffff;
          list-style:none;
        }
        #teamcard .card.img-fluid {
          width: 100%;
          height: 100%;
      }
        #teamCard .card-img-overlay {
            position: absolute;
            top: 186px;
            text-align: center;
            bottom: 0;
            left: 0;
            padding: 0px;
            border-radius: 0px;
          }
          
          }

          #teamCard ::marker {
            unicode-bidi: isolate;
            font-variant-numeric: tabular-nums;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
          }
          #teamCard .card-img-top {
            height: 427px !important;
            max-height: 616px !important;
             padding: 0px;
             overflow: visible;
             border-top-left-radius: 0px;
             border-top-right-radius: 0px;
        }
        #teamCard .follow-section{
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-between;
            
            padding: 9% 2% 0% 2%;    
        }
       
        @media only screen and (max-width: 767px) {
          /* Styles for mobile devices */
          #teamCard .slick-slider{
            height:400px !important;
          }
         #liicon{
         padding-top:68px;
       }
          #teamCard .slick-dots li button:before{
            position: static !important;
          }
          #teamCard .slick-dots{
           position: static !important;
        transform: translate(0%, -100%);
        margin-top: 188px;
          }
          #teamCard .img-fluid{
            width: 300px !important;
            max-width: 300px !important;
            height: 250px !important;
            background: transparent;
          }
          #teamCard .follow-section{
             display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-between;
        p: 1;
        padding: 25% 2% 2% 2%;
        }
        #Teamtxt h2 {
          color: rgb(0, 0, 0);
          font-family: 'Helvetica';
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          letter-spacing: 0px;
          width: 100% !important;
          padding: 43px 4%;
          margin-top:-17px;
      }
        .card-title{
          margin-top:60px;
        }
        #s2 {
          height: 19px;
          margin-top: 3px;
          margin-left: 10px;
          margin-right: 10px;
      }
        #Team h5{
        font-size: 16px !important;
        padding-top: 17px;
        padding-left: 0px;
        }
        #teamCard li {
          font-family: 'Poppins' !important;
          font-style: normal !important;
          font-weight: 500 !important;
          font-size: 17px !important;
          color: #ffffff !important;
                  list-style: none;
      }
      #teamCard .container{
        margin-top:0px !important;
        }
     
        #Team .col-4 {
         border: 2px solid #000000;
        padding: 0px;
        width: 300px !important;
        transform: translate(24%, 5%);
        height: 576px;
      }
        
        #teamCard .card-img-overlay {
         position: absolute;
        top: 127px;
        text-align: center;
        bottom: 0;
        left: 0;
        padding: var(--bs-card-img-overlay-padding);
        border-radius: 0px;
        }
        #teamCard .follow-section{
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: space-between;
          p: 1;
          padding: 25% 2% 2% 2%;    
      }
          #teamCard .card-title {
          margin-top:262px}
      .slick-slide {
        /* height: 186px !important; */
        background: #FFFFFF;
        box-shadow: none; 
        border-radius: 9.15849px;
        /* margin-right: 20px; */
        max-width: 300px !important;
        width: 300px !important;
        padding-left: 6px;
        padding-right: 24px;
    }

      .slick-slide{
        width:100%;
      }
      div#Team {
        padding-bottom: 303px !important;
        background-color: #f7f7f7;
    }
    #teamCard li{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 11px !important;
      color: #ffffff;
              list-style: none;
    }
    #Team .slick-slide{
    //  background-color:#f7f7f7 !important;
    background-color: #f7f7f700 !important;
      }
      .selectcat:focus {
      color: #000000 !important;
    }
    option{
     color: rgba(84, 84, 84, 0.5) !important;
    }
      #teamCard .card-img-top {
       height: 382px !important;
        max-height: 400px !important;
        padding: 0px;
        overflow: visible;
        width: 296px !important;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        margin-left: 0px;
        OBJECT-FIT: cover;
        margin-top: 6px;
    }
        #Team  .slick-slider {
        padding-top: 0px;
    }
   }
    @media only screen and (min-width: 360px) and (max-width: 360px){
        #Team .slick-track{
        transform: translate3d(-396px, 0px, 0px) !important;
        }    
        }

  .containers{
  padding-left: 4%;
    padding-right: 4%;} 
    
    @media only screen and (min-width: 1440px) and (max-width: 1440px){
#teamCard .card-title { margin-top: 243px;
}
        }
 @media only screen and (min-width: 1280px) and (max-width: 1280px){
#teamCard .card-title { margin-top: 237px;
}
        }
        `}
      </style>
      <div id="teamCard">
        <div id="Teamtxt">
          <h2 style={{ textTransform: "uppercase" }}>
            Meet the Brains Behind VUI Hospitality
          </h2>
          <p>
            Our leadership team consists of seasoned professionals with over 15
            years of experience.
          </p>
        </div>
        <div className="containers">
          {isMobile ? (
            <Slider {...settings}>
              {teamMembers.map((member, index) => (
                <div className="col-4" key={index}>
                  <div className="card img-fluid">
                    <img
                      className="card-img-top"
                      src={member.image}
                      alt={member.name}
                    />
                    <div className="card-img-overlay">
                      <p
                        className="card-title"
                        style={{ textTransform: "uppercase" }}
                      >
                        {member.name}
                      </p>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>

                  <div id="followmob" className="follow-section">
                    <h5>{member.heading}</h5>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="row">
              {teamMembers.map((member, index) => (
                <div className="col-4" key={index}>
                  <div className="card img-fluid">
                    <img
                      className="card-img-top"
                      src={member.image}
                      alt={member.name}
                    />
                    <div className="card-img-overlay">
                      <p
                        className="card-title"
                        style={{ textTransform: "uppercase" }}
                      >
                        {member.name}
                      </p>
                    </div>
                  </div>
                  <div className="follow-section">
                    <h5>{member.heading}</h5>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyComponent;
