import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'; // Import emailjs library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import PhoneInput from 'react-phone-input-2';

function ContactForm() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // After the "API call" has finished, stop showing the loading icon
    setLoading(false);

    try {
      // Assuming sendForm returns a promise
      const result = await emailjs.sendForm('service_qx3i0t9', 'template_2htjzmp', form.current, {
        publicKey: 'gdODjJHS5A-JFuTFC',
      });
      console.log('Email successfully sent!', result.text);
      // Set form as submitted if email was sent successfully
      setSubmitted(true);
      
      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      // Reset the form here if needed
      form.current.reset();
    } catch (error) {
      console.error('Failed to send email:', error);
    }

    setLoading(false); // End loading
  };
  const filterServices = (category) => {
    // Define your service options based on category
    switch (category) {
      case 'Corporate Events':
        return [
          'Award Ceremonies',
          'Team Building Activities',
          'Exhibitions',
          'Product Launch',
          'Conferences & Seminars',
          'Corporate Offsites',
        ];
      case 'Experiential Travel':
        return [
          'Cultural Experience',
          'Local Cuisine',
          'Local Activities',
          'Customised Itinerary',
          'Flight Tickets',
          'Hotel Booking',
        ];
      case 'Corporate Gifting':
        return [
          'Budget Gifting',
          'Customised Gifting',
          'Occasional Gifting',
          'Festival Gifting',
          'Health and Safety Gifting',
          'Virtual Gifting',
        ];
      case 'Digital Marketing':
        return [
          'SMM',
          'SEO',
          'UI/UX Design',
          'Content Marketing',
          'Tech Solutions',
          'Creative Marketing',
        ];
      default:
        return [];
    }
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm('service_mhkkkwa', 'template_megbwh2', form.current, {
  //       publicKey: '20SA2U48taVusKc5H',
  //     })
  //     .then(
  //       () => {
  //         console.log('SUCCESS!');
  //         setSubmitted(true);
  //         // Reset form fields after submission
  //         form.current.reset();
  //       },
  //       (error) => {
  //         console.log('FAILED...', error.text);
  //       }
  //     );
  // };

  return (
    <section className="ftco-section img bg-hero ftcban" id="ctnform">
      <div className="mrg">
        <div className="row">
          <div className="col contcol">
            <div className="wrapper">
              <div className="row no-gutters justify-content-between mbct">
                <div className="col-lg-6 d-flex align-items-stretch gtbox mbct">
                  <div className="info" style={{paddingTop:'0px'}}>
                  <div id="cdiv"> <h3 className=" conth">Contact Us</h3>
                  {/*  <p className="getth">
                      Progressively incentivize cooperative systems through
                      technically{' '}
                    </p>
                    <p className="getth" style={{ paddingBottom: '9%' }}>
                      sound functionalities. The credibly productivate seamless
                      data.
  </p>*/}
                    <p className="getth" style={{ paddingBottom: '9%' }}> Let's turn your vision into a splendid reality. Contact VUI Hospitality to begin your bespoke event journey.
                      data.</p></div>
                      <div id="mbcontact">
                    <div className="dbox  d-flex align-items-start conicon">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone" style={{ lineHeight: 'inherit' }}></span>
                      </div>
                      <div className="text pl-4 contdetail">
                        <p>
                          {' '}
                          <a href="tel://+91-9899747959" className="contdetail">
                          +919899747959                          </a>
                         {/* <a href="tel://1234567920" className="contdetail">
                             +91-9123456789
</a>*/}
                        </p>
                      </div>
                    </div>
                    <div className="dbox  d-flex align-items-start conicon">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-envelope" style={{ lineHeight: 'inherit' }}></span>
                      </div>
                      <div className="text pl-4">
                        <p>
                          {' '}
                          <a href="mailto:manan.prashar@vuihospitality.com" className="contdetail">
                          manan.prashar@vuihospitality.com                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="dbox  d-flex align-items-start conicon">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-clock" style={{ lineHeight: 'inherit' }}></span>
                      </div>
                      <div className="text pl-4">
                        <p>
                          {' '}
                          <a href="#" className="contdetail">
                            10:00 am to 07:00 pm
                          </a>
                        </p>
                      </div>
                    </div>
                    <br></br>

                    <hr className='mobhr'></hr>
                    </div>
                  </div>
                </div>
                <div className="col contactcont">
                  <div className="contact-wrap contactgetcont">
                    <h3 className="mb-4 contform">Tell Us Your Requirement </h3>
                    <div id="form-message-warning" className="mb-4"></div>
                    <div id="form-message-success" className="mb-4 contformp">
                    Drop us your contact details and our expert will get in touch with you.
                    </div>
                    <form ref={form} onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="inputAddress" className="fname"  >
                          Full Name*{' '}
                        </label>

                        <input  
 type="text" name='fname' className="form-control " id="inputname" placeholder="Your Full Name " required style={{ width: '97%' }} />
                       
                      </div>
                      <div className="form-row" id="phn2">
                      <div className="form-group col-md-6 contactcontfeild">
                          <label htmlFor="phoneNumber" className="fphone" >
                            Phone Number*
                          </label>
                          <div className="input-group contactfrmph">
                          <PhoneInput
                            country={'in'}
                            inputProps={{
                              name: 'fphone',
                              required: true,
                              className: 'form-control contactfc',
                              id: 'phoneNumber',
                              placeholder: 'Enter Your Phone Number',
                            }}
                            disableCountryCode={false} // Show country code
                          />
                            <input type="tel" name='fphone' className="form-control contactfc" id="phoneNumber" placeholder="9999****** " required style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: '0.88px solid' }} />
                          </div>
                        </div>
                        <div className="form-group col-md-6 contfeild">
                          <label htmlFor="inputEmail4" className="femail">
                            Your Email ID
                          </label>
                          <input type="email" name='femail' className="form-control" id="inputEmail4" placeholder="Enter Your Email Id " />
                        </div>
                      </div>
                      <div className="form-row" id="srv">
                        <div className="form-group col-md-6 contfeild">
                          <label htmlFor="inputState" className="fcategary">
                            Category’s{' '}
                          </label>
                          <select name='fcategary' id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
  <option value="" disabled={selectedCategory !== ''} style={{color:'red'}} id="sdg">Select Your Category</option>
  <option value="Corporate Events">Corporate Events</option>
  <option value="Experiential Travel">Experiential Travel</option>
  <option value="Corporate Gifting">Corporate Gifting</option>
  <option value="Digital Marketing">Digital Marketing</option>
</select>
                        </div>
                        <div className="form-group col-md-6 contfeild">
                          <label htmlFor="inputState" className="fservices">
                            Services{' '}
                          </label>
                          <select name='fservices' id="inputServices" className="form-select selectcat">
                            <option selected>Select Your Services </option>
                            {filterServices(selectedCategory).map((service, index) => (
                              <option key={index}>{service}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                            <label className="form-check-label frcheckbox" htmlFor="invalidCheck2">
                              By Clicking The Button Below, You Agree To Our Terms Of Service And Acknowledge Our Global Privacy Policy.
                            </label>
                          </div>
                        </div>
                      </div>
                      <button type="submit" id="sbbtn" className="btn sub" disabled={loading}>
                        SUBMIT {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                      </button>
                    </form>
                     {submitted && (
                      <div className="alert alert-success" role="alert" style={{marginTop: '60px'}}>
                        Your Form is submitted Successfully.
                      </div>
                    )}
                  </div>
                </div>


              
              </div>
            </div>
          </div>
        </div>
        <section className="section hide-desktop">
    <div >
        
        <div className="row mt-5 pt-5" id="loctionrow">
          <p id="ourlocation">Our Location</p>
            <div className="col-lg-4 contlocation">
              <p className='in'>India</p>
                <div className="service-box  text-center p-4" id="sbtc">
                    <h5 className="fw-bold mt-2" id="fbm">office location</h5>
                    <div className="plan-line mx-auto my-3" ></div>
                    <p className="text-muted f-16" id="plm">8th Floor, A - Tower,Picasso Center, Sector - 61,Golf Course Extension Road, Gurgaon</p>
                </div>
            </div>
            
        </div>
    </div>
</section>
      </div>
    </section>
  );
}

export default ContactForm;

