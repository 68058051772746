/*import React from 'react';
import { Link } from 'react-router-dom';
import F1Image from "../images/F1.png";
import F2Image from "../images/F2.png";
import F3Image from "../images/F3.png";
import F4Image from "../images/F4.png";
import F5Image from "../images/F5.png";
import F6Image from "../images/F6.png";
import { height } from '@fortawesome/free-solid-svg-icons/fa0';

// Define background image style
const bgStyle = {
  backgroundImage: `url(${require("../images/Bg12.png")})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // height: '50vh',
  padding:'5% 5%',
};

// Functional Component
const Imagelayout = () => {
  return (
    <div id="Imagelayout" style={bgStyle}>
        <div className="row">
            <div className="container" >
                <p style={{color:'white'}}>PARTNERS</p>
                <h1 style={{
                  color:'white', 
                  fontFamily: 'Helvetica',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '46px',
                  lineHeight: '120%',
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',

                  color: '#FFFFFF',
                  width: '95vw',
                  paddingBottom: '20px',
              }}>Partners who trust vui<br></br>hospitality</h1>
                    <div className="row">
                        <div className="col"><img src={F1Image} alt="F1"/></div>
                        <div className="col"><img src={F2Image} alt="F2"/></div>
                        <div className="col"><img src={F3Image} alt="F3"/></div>
                        <div className="col"><img src={F4Image} alt="F4"/></div>
                        <div className="col"><img src={F5Image} alt="F5"/></div>
                        <div className="col"><img src={F6Image} alt="F6"/></div>
                    </div>
            </div>
        </div>
    </div>
  );
}

export default Imagelayout;
*/

import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import logo1Image from "../images/logo1.png";
import logo21Image from "../images/logo21.png";
import logo3Image from "../images/logo3.png";
import logo4Image from "../images/logo4.png";
import logo5Image from "../images/logo5.png";
import logo6Image from "../images/logo6.png";
import BgImage from "../images/Bg12.png";

// External style
const externalStyle = `
  .homesdpera {
    position: relative !important;
    top: -1px !important;
  }
`;

const Imagelayout = () => {
  // Define background image style
  const bgStyle = {
    backgroundImage: `url(${BgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "2% 0%",
  };

  // Settings for react-slick carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 images at a time
    slidesToScroll: 1,
    variableWidth: true, // Add this property to allow variable width for slides
    centerMode: true, // Add this property for centered mode
    centerPadding: "0px", // Adjust the padding between slides
    responsive: [
      // Responsive settings
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 image at a time in mobile view
          centerMode: false, // Disable center mode in mobile view
          centerPadding: "5px", // Adjust padding between slides in mobile view
        },
      },
    ],
  };

  // Array of F1 to F6 images
  const images = [logo1Image, logo21Image, logo3Image, logo4Image, logo5Image, logo6Image];

  return (
    <>
      <style>{externalStyle}</style>
      <style>{`
        @media (max-width: 768px) {
          #Imagelayout h1{
            color: white !important;
            font-family: Helvetica !important;
            font-style: normal !important;
            font-weight: 700 !important;
            font-size: 23px !important;
           
            display: flex !important;
            align-items: center !important;
            text-transform: uppercase !important;
            width: 100% !important;
            padding-bottom: 20px !important;
          }
          #Imagelayout .slick-slide {
            background: #ffffff00 !important;
            box-shadow: none !important;
            height: auto !important; /* Adjust height */
            width: 95px !important;
            margin-right: 18px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
          }
          #Imagelayout .slick-slider {
            padding-left: 7% !important;
            padding-right: 0% !important;
            height: 323px !important;
        }
        #Imagelayout .slick-list {
            margin: 0 -15px; /* Adjust margin */
          }
          #Imagelayout .slick-slide img {
            max-width: 200px;
            height: 70px !important;
            margin-top: 30px;
          }
          div#Imagelayout {
            margin-top: -8px;
            height: 340px !important;
        }
        #Imagelayout {
          margin: 75px 0px;
      }
        }
      `}</style>
      <div id="Imagelayout" style={bgStyle}>
        <div className="container svz">
          <p style={{ color: "white" }}>PARTNERS</p>
          <h1
            style={{
              color: "white",
              fontFamily: "Helvetica",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "46px",
              lineHeight: "120%",
              display: "flex",
              alignItems: "center",
              textTransform: "uppercase",
              width: "650px",
              paddingBottom: "90px",
            }}
          >
            Partners who trust vui hospitality
          </h1>
          {/* Carousel for mobile view */}
          <div className="row d-md-none" id="gh">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`F${index + 1}`} />
                </div>
              ))}
            </Slider>
          </div>
          {/* Individual images for desktop view */}
          <div className="row d-none d-md-flex">
            {images.map((image, index) => (
              <div className="col" key={index}>
                <img src={image} alt={`F${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Imagelayout;
