import React from "react";
import { Link } from "react-router-dom";
import gpImage from "../images/gp.png"; // Adjusted import path
import layoutbannermobImage from "../images/layoutbannermob.png"; // Adjusted import path
import layoutbannerImage from "../images/layoutbanner.png"; // Adjusted import path

// Functional Component

const Layoutbannersection = () => {
  return (
    <div id="layoutbannersection">
      <style>
        {`
        #toprow h1{
            padding-top:15px;
             margin-bottom:20px;
             text-transform:uppercase;
             font-weight:700;
             font-size:48px;
             font-family:'Helvetica';
        }
        #layoutbannersectionmain {
            padding: 60px 0px;
            background-image: url("${layoutbannerImage}");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 85vh;
            padding: 0vh;
        }
        
          #layoutbannersection p {
            padding-right: 10%;
            font-size: 16px;
            font-family: 'poppins';
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
          #layoutbannersection2 {
            background: #FFFFFF;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            width: 818px;
            z-index: 1;
            padding: 25px 15px;
            position: relative;
            margin-top: -5%;
        }
          @media only screen and (max-width: 767px) {
            #toprow h1{
                font-size:35px;
                letter-spacing:0.7px;
                margin-top: -15px;
            }
            div#toprow {
                --bs-gutter-x: 1.5rem;
                --bs-gutter-y: 0;
                display: flex;
                flex-wrap: wrap;
                margin-top: calc(-1* var(--bs-gutter-y));
                margin-right: calc(-.5* var(--bs-gutter-x));
                margin-left: calc(-.5* var(--bs-gutter-x));
                flex-direction: column;
            }
            #Vour {
              font-size: 16px;
              font-weight: 700;
          }
          #Vour2 {
            font-size: 16px;
            font-weight: 700;
        }
            #layoutbannersection2 {
              background: #FFFFFF;
              box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              width: 90%;
              display: flex;
              z-index: 1;
              padding: 27px 12px;
              position: relative;
              margin-top: 0px;
              flex-direction: column;
              top: -80px !important;
              left: 28px;
              height: 345px;
              /* column-gap: 121px !important; */
              gap: 31px;
            }
            #layoutbannersection2  .col-6 {
                flex: 0 0 auto;
                width: 95%;
            }
            #layoutbannersection p {
                padding-right: 0px;
                font-size: 12px;
                font-family: 'poppins';
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
            }
            #topinnerrow{
                padding:0px 35px;
            }
            #layoutbannersectionmain {
                padding: 60px 0px;
                background-image: url("${layoutbannermobImage}") !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center !important;
                height: 131vh !important;
                padding: 0vh !important;
                margin-top:35% !important;
            }
            #layoutbannersection{
              background-image:none !important;
            }
            .ovimg{
              margin-top: 130px;
            }
          }
          @media only screen and (min-width: 1280px) and (max-width: 1600px) {
            /* Styles for MacBook devices */
            #layoutbannersectionmain {
                padding: 60px 0px !important;
                background-image: url("${layoutbannerImage}") !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                background-position: center !important;
                height: 100% !important;
                padding: 5vh !important;
            }
          }
          
        `}
      </style>
      <div className="container ovimg">
        <div id="toprow" className="row">
          <div id="topinnerrow" className="col">
            <div className="row">
              <h1>
                Mission for VUI<br></br> Hospitality.
              </h1>
            </div>
            <div className="row">
              <p>
                Our mission is dedicated to creating moments that linger in the
                memory, bridging the gap between the ordinary and the
                extraordinary.
              </p>
              {/* <p>Our mission is to create lasting memories for our clients through exceptional service and unique experiences..</p> */}
            </div>
          </div>
          <div className="col">
            <img src={gpImage} alt="" />
          </div>
        </div>
        <div className="row" id="layoutbannersection2">
          <div className="col-6">
            <h4 id="Vour" style={{ marginBottom: "20px" }}>
              Our Vision
            </h4>
            {/* <h6 style={{marginBottom:'10px'}}>Crafting unforgettable  guest experiences.</h6> */}
            <p>
            Our aim is to flip expectations and sprinkle magic dust, creating occasions that sizzle with quality and resonate with our esteemed clients. 
            </p>
          </div>
          <div className="col-6">
            <h4 id="Vour2" style={{ marginBottom: "20px" }}>
              Our Goal
            </h4>
            <p>
              We're committed to delivering engaging experiences to our clients
              that capture their goals, while staying updated with market
              trends.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layoutbannersection;
