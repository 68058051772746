import React from 'react';
import logo from './images/logo.png';
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from 'react-router-dom';
import twitterImage from "./images/twitter.png";

const Footer = () => {
  return (
    <div id="srt">
      {/* Footer */}
      <footer className="text-center text-lg-start text-white" style={{ backgroundColor: '#000000',paddingTop:'2%' }}>
        {/* Grid container */}
        <div className="container p-4 pb-0" id="ftnew">
          {/* Section: Links */}
          <section className="">
            {/* Grid row */}
            <div className="row custom-row ftht">
              {/* Grid column */}
              <div className="col-md-3 col-lg-3 col-xl-3 btnfooter" >
              <Link to="/">   <img src={logo} alt="Company Logo" className="mb-4 ftlogo" /></Link>
                
                <div className="footer">
      <a href="https://www.facebook.com/profile.php?id=100091998467476&mibextid=LQQJ4d" target="_blank" className="social-icon"><i className="fab fa-facebook-f fb" style={{color:'white'}}></i></a>
     <div className="fticon"> <a href=" https://www.linkedin.com/company/vui-hospitality-pvt-ltd/" target="_blank" ><i className="fab fa-linkedin-in fb" style={{color:'white'}}></i></a></div>
     <div className="fticon1"  ><a href="https://www.instagram.com/vui_hospitality" target="_blank" ><i  className="fab fa-instagram fb"  style={{color:'white'}}></i></a></div>
     <div className="fticon2" > <a href="https://www.linkedin.com/login" target="_blank" ><i className="fab fa-linkedin-in fb" style={{color:'white'}}></i></a></div>
    </div>
    <div className='ftdiv'></div>
   <div className='bussiness'> <p className='ftdivp'>
    looking to scale your business  ?<br></br>Contact
                </p> 
                <a href="mailto:manan.prashar@vuihospitality.com">
                    <p className='ftdivh'>manan.prashar@vuihospitality.com</p>
                    </a>
                    </div>
                </div>
              {/* Grid column */}

              <hr className="w-100 clearfix d-md-none" />

              {/* Grid column */}
              <div className="col-md-2 col-lg-2 col-xl-2 services" style={{  width: '21%'}}>
               <h6 className="text mb-5 fthead">Our Services</h6>
               <ul className='ftlist'> 
                <li  className=" ftxt"><Link to="/Services" className=" text-white ftxt" >Corporate Events</Link></li>
                <li className=" ftxt"><Link to="/Exhibitionshowservices" className=" text-white ftxt">Experiential Travel</Link></li>
                <li className=" ftxt"><Link to="/Brandactivationservices" className="text-white ftxt">Corporate Gifting</Link></li>
                <li className=" ftxt"><Link to="/hotelbookingforcorporates" className="text-white ftxt">Digital Marketing</Link></li>
               </ul>

              </div>
              {/* Grid column */}

              <hr className="w-100 clearfix d-md-none" />

              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 company" style={{ width:'23%'}}>
                <h6 className="text mb-5  fthead1">Our Company</h6>
               <ul className='ftlist2'>
                
               <li className=" ftxt"><Link to="/About" className="text-white">About Us</Link></li>
                <li className=" ftxt"><Link to="/contact" className="text-white">Join Us </Link></li>
                <li className="ftxt"><Link to="/gallery" className="text-white"> Gallery</Link>
</li>
                <li className=" ftxt"><Link to="/Blog" className="text-white">News and Events​</Link></li>
                </ul>
              </div>
              {/* (<p style={{ color: '#56BDFF',display:'unset' }}>REP 360</p>) */}

              {/* Grid column */}
              <hr className="w-100 clearfix d-md-none" />

              {/* Grid column */}
              <div className="col-md-4 col-lg-3 col-xl-3 contact" style={{  width: '22%'}}>
                <h6 className="text mb-5 fthead2">Our Location</h6>
                <p className=" ftxt1" id="tps">  8th Floor, A - Tower, Picasso </p>
                <p className=" ftxt11">Center, Sector-61, Golf Course</p>
                <p className=" ftxt1"> Extension Road, Gurgaon, </p>
                <p className=" ftxt1"> Haryana - 122102</p>

              </div>
             
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </section>
          {/* Section: Links */}

        <center>  <hr className="my-3 fthr" /></center>

          {/* Section: Copyright */}
          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">
              {/* Grid column */}
              <div className="col-md-7 col-lg-8 text-center text-md-start">
                {/* Copyright */}
                {/*<div className="p-3">
                  © 2020 Copyright:
                  <a className="text-white" href="https://mdbootstrap.com/">MDBootstrap.com</a>
  </div>*/}
                {/* Copyright */}
              </div>
              {/* Grid column */}

              {/* Grid column */}
             
              {/* Grid column */}
            </div>
          </section>
          {/* Section: Copyright */}
        </div>
        {/* Grid container */}
      </footer>
      {/* Footer */}
     
    </div>
    /* End of .container */
    
  );
};

export default Footer;
