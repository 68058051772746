import React, { useState, useRef } from "react";
import "./Brandactivationservices.css";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

import B1Image from "./images/B1.png";
import B2Image from "./images/B2.png";
import B3Image from "./images/B3.png";
import B4Image from "./images/B4.png";
import B5Image from "./images/B5.png";
import B6Image from "./images/B6.png";

import L1Image from "./images/L1.png";
import L2Image from "./images/L2.png";
import L3Image from "./images/L3.png";
import L4Image from "./images/L4.png";
import L5Image from "./images/L5.png";
import L6Image from "./images/L6.png";

import V1Image from "./images/V1.png";
import V2Image from "./images/V2.png";
import V3Image from "./images/V3.png";
import V4Image from "./images/V4.png";

import Gallery1Image from "./images/Gallery1.png";
import Gallery2Image from "./images/Gallery2.png";
import Gallery3Image from "./images/Gallery3.png";
import Gallery4Image from "./images/Gallery4.png";
import Gallery5Image from "./images/Gallery5.png";
import PersonalisationImage from "./images/Personalisation.png";
import EmployeeAppreciationImage from "./images/EmployeeAppreciation.png";
import BrandVisibilityImage from "./images/BrandVisibility.png";
import ClientRetentionImage from "./images/ClientRetention.png";
import CustomisationOptionsImage from "./images/CustomisationOptions.png";
import CorporateResponsibilityImage from "./images/CorporateResponsibility.png";

import ProcessImage from "./images/Process.png";
import ProcessVerticalImage from "./images/ProcessVertical.png";

import Hbac1Image from "./images/Hbac1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Slide from "./slide";
import BlogCom  from './post';
import Callback  from './callback';
import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import PhoneInput from 'react-phone-input-2';
import BudgetgiftingImage from "./images/Budget gifting.png";
import CustomisedgiftingImage from "./images/Customised gifting.png";
import ocassionalImage from "./images/ocassional.jpg";
import festivalImage from "./images/festival.jpg";
import HealthandSafetyGiftingImage from "./images/Health and Safety Gifting.png";
import sustainableImage from "./images/sustainable.jpg";
import ourwork1Image from './images/ourwork1.png'
import ourwork2Image from './images/ourwork2.png'
import ourwork3Image from './images/ourwork3.png'
import ourwork4Image from './images/ourwork4.png'
import ourwork5Image from './images/ourwork5.png'
import ourwork6Image from './images/ourwork6.png'
const Services = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // After the "API call" has finished, stop showing the loading icon
    setLoading(false);

    try {
      // Assuming sendForm returns a promise
      const result = await emailjs.sendForm(
        "service_mhkkkwa",
        "template_megbwh2",
        form.current,
        {
          publicKey: "20SA2U48taVusKc5H",
        }
      );
      console.log("Email successfully sent!", result.text);
      // Set form as submitted if email was sent successfully
      setSubmitted(true);

      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      // Reset the form here if needed
      form.current.reset();
    } catch (error) {
      console.error("Failed to send email:", error);
    }

    setLoading(false); // End loading
  };
  const filterServices = (category) => {
    switch (category) {
      case "Corporate Events":
        return [
          "Award Ceremonies",
          "Team Building Activities",
          "Exhibitions",
          "Product Launch",
          "Conferences & Seminars",
          "Corporate Offsites",
        ];
      case "Experiential Travel":
        return [
          "Cultural Experience",
          "Local Cuisine",
          "Local Activities",
          "Customised Itinerary",
          "Flight Tickets",
          "Hotel Booking",
        ];
      case "Corporate Gifting":
        return [
          "Budget Gifting",
          "Customised Gifting",
          "Occasional Gifting",
          "Festival Gifting",
          "Health and Safety Gifting",
          "Virtual Gifting",
        ];
      case "Digital Marketing":
        return [
          "SMM",
          "SEO",
          "UI/UX Design",
          "Content Marketing",
          "Tech Solutions",
          "Creative Marketing",
        ];
      default:
        return [];
    }
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm("service_mhkkkwa", "template_megbwh2", form.current, {
  //       publicKey: "20SA2U48taVusKc5H",
  //     })
  //     .then(
  //       () => {
  //         console.log("SUCCESS!");
  //         setSubmitted(true);
  //         // Reset form fields after submission
  //         form.current.reset();
  //       },
  //       (error) => {
  //         console.log("FAILED...", error.text);
  //       }
  //     );
  // };
  return (
    <div>
        <div>
        <Callback/>
        </div>
      <section className="ftco-section-img-bg-hero ftcbannew" id="SftcbannewB">
        <div className="mrg">
          <div className="row">
            <div className="col contcol">
              <div className="wrapper">
                <div className="row no-gutters justify-content-between">
                  <div className="col-lg-6 d-flex align-items-stretch gtbox">
                    <div className="info">
                      <h3 className=" conth1" id="conth1">
                        CORPORATE
                      </h3>
                      <h3 className=" conth2BA" id="conth2BA">
                        GIFTING
                      </h3>
                      <h3 className=" conth3" id="conth3">
                        SERVICES
                      </h3>
                    </div>
                  </div>
                  <div className="col cont" id="col-cont">
                    <div className="contact-wrap getcont">
                      <h3 className="mb-4 contform">
                        Create Your Requirement{" "}
                      </h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4 contformp">
                        Get in touch with us for more information on any of the
                        products or services we offer{" "}
                      </div>
                      <form ref={form} onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="inputAddress" className="fname">
                            Full Name*{" "}
                          </label>
                          <input
                            type="text"
                            name="fname"
                            className="form-control "
                            id="inputname"
                            placeholder="Your Full Name "
                            required
                            style={{ width: "97%" }}
                          />
                        </div>
                        <div className="form-row" id="phn1">
                        <div className="form-group col-md-6 contactcontfeild">
                          <label htmlFor="phoneNumber" className="fphone" >
                            Phone Number*
                          </label>
                          <div className="input-group contactfrmph">
                          <PhoneInput
                            country={'in'}
                            inputProps={{
                              name: 'fphone',
                              required: true,
                              className: 'form-control contactfc',
                              id: 'phoneNumber',
                              placeholder: 'Enter Your Phone Number',
                            }}
                            disableCountryCode={false} // Show country code
                          />
                            <input type="tel" name='fphone' className="form-control contactfc" id="phoneNumber" placeholder="9999****** " required style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: '0.88px solid' }} />
                          </div>
                        </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputEmail4" className="femail">
                              Your Email ID
                            </label>
                            <input
                              type="email"
                              name="femail"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Enter Your Email Id "
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fcategary">
                              Category’s{" "}
                            </label>
                            <select name='fcategary' id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
  <option value="" disabled={selectedCategory !== ''} style={{color:'red'}} id="sdg">Select Your Category</option>
  <option value="Corporate Events">Corporate Events</option>
  <option value="Experiential Travel">Experiential Travel</option>
  <option value="Corporate Gifting">Corporate Gifting</option>
  <option value="Digital Marketing">Digital Marketing</option>
</select>

                          </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              name="fservices"
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              {filterServices(selectedCategory).map(
                                (service, index) => (
                                  <option key={index} name="fservices">
                                    {service}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          {/* <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              <option>...</option>
                            </select>
                          </div> */}
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="invalidCheck2"
                                required
                              />
                              <label
                                className="form-check-label frcheckbox"
                                htmlFor="invalidCheck2"
                              >
                                By Clicking The Button Below, You Agree To Our
                                Terms Of Service And Acknowledge Our Global
                                Privacy Policy.{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn sub"
                          disabled={loading}
                        >
                          SUBMIT{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </form>
                      {submitted && (
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginTop: "60px" }}
                        >
                          Your Form is submitted Successfully.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Section 1 started*/}
      <section className="mainServ1" id="mainServ">
        <h3 className="Serv" id="Serv">
          Services
        </h3>
        <h1 className="ServHead-1" id="ServHeadB">
          all CORPORATE GIFTING SERVICES
        </h1>
        <p className="ServPara" id="ServPara">
          Corporate gifting is all about creating stronger bonds within the
          client’s ecosystem through thoughtful expressions of gratitude,
          celebration, and acknowledgement. From custom-tailored keepsakes and
          exclusive luxury items to functional pieces adorned with your emblem,
          these gifts are gestures woven with appreciation and intent to bolster
          goodwill and enrich connections.
        </p>
        {/*main div for black section*/}
        <div className="DivSerMain" id="DivSerMain">
          {/*>>>>>>>>>>Sec1<<<<<<<<<<<*/}
          <div className="CODivsec1" id="CODivsec1">
            <img className="COBI1" id="COBI1" src={BudgetgiftingImage} alt="" />
            <div id="COorangeheading1">
              <img className="L1" id="L1" src={L1Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac1" id="Hbac1" src={Hbac1Image} alt="" />
              <h4 className="PBhead-1" id="PBhead-1">
                Budget gifting
              </h4>
              <p className="DivPara-1" id="DivPara-1">
                Selecting gifts within predetermined spending limits to ensure
                cost-effectiveness and alignment with financial constraints
                without compromising quality or sentiment.
              </p>
              <Link to="/contact">   <h1 className="DivInq-1" id="DivInq-1">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec2<<<<<<<<<<<*/}
          <div className="CODivsec2" id="CODivsec2">
            <img className="COBI2" id="COBI2" src={CustomisedgiftingImage} alt="" />
            <div id="COorangeheading2">
              <img className="L2" id="L2" src={L2Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac2" id="Hbac2" src={Hbac1Image} alt="" />
              <h4 className="PBhead-2" id="PBhead-2">
                Customised gifting
              </h4>
              <p className="DivPara-2" id="DivPara-2B">
                Tailoring gifts with personalised touches such as names, logos,
                or specific preferences to add a unique and thoughtful element.
              </p>
              <Link to="/contact">   <h1 className="DivInq-2" id="DivInq-2">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec3<<<<<<<<<<<*/}
          <div className="CODivsec3" id="CODivsec3">
            <img className="COBI3" id="COBI3" src={ocassionalImage} alt="" />
            <div id="COorangeheading3">
              <img className="L3" id="L3" src={L3Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac3" id="Hbac3" src={Hbac1Image} alt="" />
              <h4 className="PBhead-3" id="PBhead-3">
                Occasional gifting
              </h4>
              <p className="DivPara-3" id="DivPara-3">
                Giving gifts to mark special events or milestones, enhancing the
                significance of the occasion and strengthening relationships.
              </p>
              <Link to="/contact">  <h1 className="DivInq-3" id="DivInq-3">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec4<<<<<<<<<<<*/}
          <div className="CODivsec4" id="CODivsec4">
            <img className="COBI4" id="COBI4" src={festivalImage} alt="" />
            <div id="COorangeheading4">
              <img className="L4" id="L4" src={L4Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac4" id="Hbac4" src={Hbac1Image} alt="" />
              <h4 className="PBhead-4" id="PBhead-4">
                Festival gifting
              </h4>
              <p className="DivPara-4" id="DivPara-4B">
                Presenting gifts during festive seasons or cultural celebrations
                to share joy, promote camaraderie, and acknowledge cultural
                diversity.
              </p>
              <Link to="/contact">   <h1 className="DivInq-4" id="DivInq-4">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec5<<<<<<<<<<<*/}
          <div className="CODivsec5" id="CODivsec5">
            <img className="COBI5" id="COBI5" src={HealthandSafetyGiftingImage} alt="" />
            <div id="COorangeheading5">
              <img className="L5" id="L5" src={L5Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac5" id="Hbac5" src={Hbac1Image} alt="" />
              <h4 className="PBhead-5" id="PBhead-5">
                Health and Safety Gifting
              </h4>
              <p className="DivPara-5" id="DivPara-5">
                Prioritising employee well-being by providing essential items
                for personal protection and hygiene like hand sanitizer kits and
                reusable face masks.
              </p>
              <Link to="/contact">   <h1 className="DivInq-5" id="DivInq-5">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec6<<<<<<<<<<<*/}
          <div className="CODivsec6" id="CODivsec6">
            <img className="COBI6" id="COBI6" src={sustainableImage} alt="" />
            <div id="COorangeheading6">
              <img className="L6" id="L6" src={L6Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac6" id="Hbac6" src={Hbac1Image} alt="" />
              <h4 className="PBhead-6" id="PBhead-6">
              Sustainable Gifting              </h4>
              <p className="DivPara-6" id="DivPara-6B">
              Make a positive impact with our affordable range of sustainable gifts. Choose from eco-friendly options that are perfect for every occasion, allowing you to express your care for both loved ones and the environment. 
              </p>
              <Link to="/contact">  <h1 className="DivInq-6" id="DivInq-6">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
        </div>
      </section>
      {/*>>>>>>>>>>New Section 2 Starte<<<<<<<<<<<<< */}
      <section className="NewSection2" id="bdsec">
        <h3 className="COWhattopHead1" id="COWhattopHead1">
          WHAT WE PROMISE{" "}
        </h3>
        <h1 className="COWhattopHead2" id="COWhattopHead2">
          Our Corporate Gifting USP
        </h1>
        <p className="WhatPara3" id="WhatPara3">
          When it comes to corporate gifting, we believe in creating lasting
          impressions.
          <span className="WhatButton1" id="WhatButton1">
            <Link to="/contact" preventScrollReset={true}>
              <button className="COENQUIRENOW">ENQUIRE NOW</button>
            </Link>
          </span>
        </p>
        {/*>>>>>>>>>>>>>>>>Particluar Section<<<<<<<<<<<<<< */}
        <div className="COmain1" id="COmain1">
          <img className="V1" id="V1" src={PersonalisationImage} alt="" />
          <h2 className="COParHead1" id="COParHead1">
            Personalisation
          </h2>
          <p className="COPartiPara1" id="COPartiPara1">
            Tailoring gifts creates emotional connections, reflecting recipient
            uniqueness and fostering stronger relationships.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 2<<<<<<<<<<<<<< */}
        <div className="COmain2" id="COmain2">
          <img className="V2" id="V2" src={BrandVisibilityImage} alt="" />
          <h2 className="COParHead2" id="COParHead2">
            Brand Visibility
          </h2>
          <p className="COPartiPara2" id="COPartiPara2">
            Branded gifts enhance brand recognition, visibility, and loyalty
            among recipients and their networks.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="COmain3" id="COmain3">
          <img className="V2" id="V3" src={EmployeeAppreciationImage} alt="" />
          <h2 className="COParHead3" id="COParHead3">
            Employee Appreciation
          </h2>
          <p className="COPartiPara3" id="COPartiPara3">
            Acknowledging staff efforts boosts morale, motivation, and
            retention, fostering a positive work culture.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 4<<<<<<<<<<<<<< */}
        <div className="COmain4" id="COmain4">
          <img className="V4" id="V4" src={ClientRetentionImage} alt="" />
          <h2 className="COParHead4" id="COParHead4">
            Client Retention
          </h2>
          <p className="COPartiPara4" id="COPartiPara4">
            Thoughtful gifts reinforce client relationships, encouraging repeat
            referrals, and enhancing long-term partnerships.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="COmain5" id="COmain5">
          <img className="V5" id="V5" src={CustomisationOptionsImage} alt="" />
          <h2 className="COParHead5" id="COParHead5">
            Customisation Options
          </h2>
          <p className="COPartiPara5" id="COPartiPara5">
            Diverse gift options cater to various tastes, preferences, and
            occasions, ensuring suitability and satisfaction.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="Comain6" id="COmain6">
          <img className="V6" id="V6" src={CorporateResponsibilityImage} alt="" />
          <h2 className="COParHead6" id="COParHead6">
            Corporate Responsibility
          </h2>
          <p className="COPartiPara6" id="COPartiPara6">
            Ethical and sustainable gifting practices demonstrate social and
            environmental consciousness, enhancing brand reputation.
          </p>
        </div>
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Process>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        {/*  <div>
          <h1 className="COprocessMain" id="COprocessMain">
            How We Function?
          </h1>
          <h2 className="COprocessMain2" id="COprocessMain2">
            Below is our functioning process from filling the form to execution.
          </h2>
          <img className="COProcess" id="COProcess" src={ProcessImage} alt="" />
          <img
            className="COVertical"
            id="COVerticalS"
            src={ProcessVerticalImage}
            alt=""
          />

          <h2 className="SubCOprocess1" id="SubCOprocess1">
            FILL IN THE FORM
          </h2>
          <p className="SubCOprocess11" id="SubCOprocess11">
          To best assist you, please share your needs and contact information.             </p>
          <h2 className="SubCOprocess2" id="SubCOprocess2">
            REVIEW
          </h2>
          <p className="SubCOprocess22" id="SubCOprocess22">
          Our team reviews your details thoroughly for better understanding of your preferences. 
          </p>
          <h2 className="SubCOprocess3" id="SubCOprocess3">
            CONNECT
          </h2>
          <p className="SubCOprocess33" id="SubCOprocess33">
          Our team will connect with you for further requirement gathering and presentation of plans. 
          </p>
          <h2 className="SubCOprocess4" id="SubCOprocess4">
            EXECUTE
          </h2>
          <p className="SubCOprocess44" id="SubCOprocess44">
          Finally, we’ll successfully execute the planned events. 
          </p>
        </div>
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Process-end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
      </section>



      
<section id="Cobg">
  <h3 className="COPortHeading" id="COPortHeading">
    PORTFOLIO
  </h3>
  <h1 className="OurHeading" id="OurHeading">
    OUR WORK
  </h1>
  {/*>>>>>>>>>>>>>>>>>>>>>GALLERY<<<<<<<<<<<<<<<<<<<<<<<*/}
  <div class="row-Gallery" style={{ position: "static" }}>
    <div class="col-galler1">
      <div class="gallery-group">
        <img
          class="COGallery1"
          id="COGallery1"
          src={ourwork1Image}
          alt="Gallery 1"
        />
        <img
          class="COGallery2"
          id="COGallery2"
          src={ourwork2Image}
          alt="Gallery 2"
        />
         <img
          class="COGalleryn12"
          id="COGalleryn12"
          src={ourwork3Image}
          alt="Gallery 2"
        />
      </div>
    </div>
    <div class="col">
      <img
        class="COGallery3"
        id="COGallery3"
        src={ourwork4Image}
        alt="Gallery 3"
      />
    </div>
    
    <div class="col">
      <div class="gallery-group">
        <img
          class="COGallery4"
          id="COGallery4"
          src={ourwork5Image}
          alt="Gallery 4"
        />
        <img
          class="COGallery5"
          id="COGallery5"
          src={ourwork6Image}
          alt="Gallery 5"
        />
      </div>
    </div>
  </div>
</section>
      {/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Blogs Section<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
      <section className="Oranservices" id="OranMain">
        <div className="COBlogS" id="COBlogS">
          <h3 className="COBlogh1" id="COBlogh1">
            INSIGHTS{" "}
          </h3>
          <h1 className="COBlogh2" id="COBlogh2">
            LATEST BLOG & NEWS EVENT
          </h1>
          <div className="BlogSC" id="BlogSC">
            blogs
          </div>

          {/* <div className="BlogsS" id="BlogsS">
            <img className="BL1image" src={BL1Image} alt="" />
            <div>
              <img className="Date1" id="Date1" src={Date1Image} alt="" />
            </div>
            <div>
              <h3 className="BT-1" id="BT-1">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-1" id="ES-1">
                Engineering Survey Week
              </h1>
              <p className="LO-1" id="LO-1">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-1" id="RM-1">
                  Read More
                </h4>
              </Link>
            </div>

            <img className="BL2image" id="BL2image" src={BL2Image} alt="" />
            <div>
              <img className="Date2" id="Date2" src={Date2Image} alt="" />
            </div>
            <div>
              <h3 className="BT-2" id="BT-2">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-2" id="ES-2">
                Sharing Your Knowledge
              </h1>
              <p className="LO-2" id="LO-2">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-2" id="RM-2">
                  Read More
                </h4>
              </Link>
            </div>
            <img className="BL3image" id="BL3image" src={BL3Image} alt="" />
            <div>
              <img className="Date3" id="Date3" src={Date3Image} alt="" />
            </div>
            <div>
              <h3 className="BT-3" id="BT-3">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-3" id="ES-3">
                Lightning Talks Metric Meetup
              </h1>
              <p className="LO-3" id="LO-3">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-3" id="RM-3">
                  {" "}
                  Read More
                </h4>
              </Link>
            </div>
                    </div>*/}
        </div>
      </section>
     {/* <div className="cpblog" id="cpblog">
        <Slide />
                  </div>*/}
                                  <div id="mainblog" className='blogn3'><BlogCom/></div>

    </div>
  );
};

export default Services;
