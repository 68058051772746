import React, { useState, useEffect, useRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import logo from './images/logo.png';
import Popup from './Popup'; // Import the Popup component
import hamburger from './images/hamburger.png';

const MenuItem = ({ to, children, onClick }) => (
  <NavLink
    to={to}
    className="text-black px-3 py-2 rounded-md nvmenu"
    activeClassName="active"
    style={{ textDecoration: 'none' }}
    onClick={onClick} // Close menu when clicked
  >
    {children}
  </NavLink>
);

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();

  const servicesDropdownRef = useRef(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsServicesDropdownOpen(false);
  };

  const handleServicesClick = (e) => {
    e.stopPropagation();
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const handleSubMenuItemClick = () => {
    setIsServicesDropdownOpen(false); // Close the services dropdown
    setIsMenuOpen(false); // Close the main menu
  };
  

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    setIsServicesDropdownOpen(false);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        servicesDropdownRef.current &&
        !servicesDropdownRef.current.contains(event.target) &&
        !event.target.closest('.nvservices')
      ) {
        setIsServicesDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <nav className={`navbar bg-white px-6 py-3 lg:px-12 lg:py-3 flex justify-between items-center top-0 left-0 right-0 navdivn ${isMenuOpen ? 'mobile-menu-open' : ''}`} id="hmnav" style={{ position: 'fixed', paddingLeft: '5%', paddingRight: '4%', zIndex: '100', boxShadow: '0px 10px 15px 0px rgba(0, 0, 0, 0.10)' }}>
      {/* Left-aligned Logo */}
      <div className="flex items-center">
        <NavLink to="/" className="logo-link" activeClassName="logo-active" style={{ textDecoration: 'none' }}>
          <img src={logo} alt="Logo" className='logo' />
        </NavLink>
      </div>

      {/* Right-aligned Hamburger Menu (for mobile view) */}
      <div className="lg:hidden">
       {/* <button className="menu-toggle" onClick={handleToggleMenu}>
          {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
  </button>*/}
        <button className="menu-toggle" onClick={handleToggleMenu}>
  {isMenuOpen ? (
    <img src={hamburger} alt="Close Menu" className='lgham'/>
  ) : (
    <img src={hamburger} alt="Open Menu" className='lgham'/>
  )}
</button>

      </div>

      {/* Center-aligned Menu (for desktop view) */}
      <div className={`menu-items lg:flex lg:items-center lg:justify-center ${isMenuOpen ? 'block' : 'hidden'}`} style={{ textAlign: 'center' }} id="ovrfl">
        <MenuItem exact to="/" onClick={handleCloseMenu}>Home</MenuItem>
        <div className="relative">
          <button style={{ display: 'inline-flex' }} className="text-black  py-2 rounded-md nvservices" onClick={handleServicesClick}>
            <span className="text-black  py-2 rounded-md " id="hmservice" activeClassName="active" style={{ textDecoration: 'none', display: 'inline-flex' }}> Services</span>
            <FontAwesomeIcon icon={faAngleDown} className="ml-1 fnticon" style={{ position: 'relative', top: '15px' }} />
          </button>
          {isServicesDropdownOpen && (
            <div ref={servicesDropdownRef} className="menu-dropdown absolute mt-2 w-full lg:w-96 xl:w-120 bg-white border rounded-md shadow-lg z-10 srvsmegamenu" style={{ textDecoration: 'none' }} onClick={(e) => e.stopPropagation()}>
              <div className="grid lg:grid-cols-4 md:grid-cols-2 main-corpo">
    
    <div class="corporate-1">
                <Link to="/services" style={{color:'black'}}  id="newmnu">  <h3 className="text-lg font-bold mb-2 mghead"    onClick={handleSubMenuItemClick} >Corporate Events</h3></Link>
                  <ul>
                  
                  <li id="newmnu1">
    <NavLink
      to="/services"
      className="text-black block mb-2 mgmenu"
      style={{ textDecoration: 'none' }}
      onClick={handleSubMenuItemClick}  id="newmnu1" 
    >Exhibitions</NavLink></li>
                 <li id="newmnu1"> <NavLink to="/services" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }}  onClick={handleSubMenuItemClick} id="newmnu1">Product Launches</NavLink></li>
                <li id="newmnu1">  <NavLink to="/services" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none', }} onClick={handleSubMenuItemClick}  id="newmnu1">Conferences and Seminars</NavLink></li>
                <li id="newmnu1">  <NavLink to="/services" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none', }} onClick={handleSubMenuItemClick}  id="newmnu1">Award Ceremonies </NavLink></li>
                <li id="newmnu1">  <NavLink to="/services" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none', }} onClick={handleSubMenuItemClick}  id="newmnu1">Team Building Activities</NavLink></li>
                <li id="newmnu1">  <NavLink to="/services" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none', }} onClick={handleSubMenuItemClick}  id="newmnu1">Corporate Offsites</NavLink></li>


                </ul>
                </div>
                <div class="corporate-2">
                 <Link to='/Exhibitionshowservices'style={{color:'black'}}  id="newmnu"> <h3 className="text-lg font-bold mb-2 mghead"  onClick={handleSubMenuItemClick}  id="newmnu2">Experiential Travel</h3></Link>
                  <ul>
                 <li id="newmnu2"> <NavLink to="/Exhibitionshowservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu2" >Cultural Experience </NavLink></li>
                <li id="newmnu2">  <NavLink to="/Exhibitionshowservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick}  id="newmnu2">Local Cuisine</NavLink></li>
               <li id="newmnu2">   <NavLink to="/Exhibitionshowservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',}} onClick={handleSubMenuItemClick}  id="newmnu2">Local Activities </NavLink></li>
               <li id="newmnu2">   <NavLink to="/Exhibitionshowservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',}} onClick={handleSubMenuItemClick}  id="newmnu2">Customised Itinerary</NavLink></li>
               <li id="newmnu2">   <NavLink to="/Exhibitionshowservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',}} onClick={handleSubMenuItemClick}  id="newmnu2">Flight Tickets Booking</NavLink></li>

               <li id="newmnu2">   <NavLink to="/Exhibitionshowservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',}} onClick={handleSubMenuItemClick}  id="newmnu2">Hotel Booking</NavLink></li>


              </ul>
                </div>
                
                {/* Add more columns here if needed */}
                <div class="corporate-3">
                <Link to="/Brandactivationservices" style={{color:'black'}} id="newmnu">   <h3 className="text-lg font-bold mb-2 mghead"  onClick={handleSubMenuItemClick} >Corporate Gifting</h3></Link>
                  <ul>
                 <li id="newmnu1"> <NavLink to="/Brandactivationservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu1">Budget gifting</NavLink></li>
                 <li id="newmnu1"> <NavLink to="/Brandactivationservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu1">Customised gifting</NavLink></li>
                <li id="newmnu1">  <NavLink to="/Brandactivationservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu1">Occasional gifting</NavLink></li>
                <li id="newmnu1">  <NavLink to="/Brandactivationservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu1">Festival gifting </NavLink></li>
                <li id="newmnu1">  <NavLink to="/Brandactivationservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu1">Health and Safety Gifting</NavLink></li>
                <li id="newmnu1">  <NavLink to="/Brandactivationservices" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu1">Sustainable Gifting</NavLink></li>


               </ul>
                </div>
                <div class="corporate-4">
                <Link to="/hotelbookingforcorporates" style={{color:'black'}} id="newmnu">  <h3 className="text-lg font-bold mb-2 mghead"  onClick={handleSubMenuItemClick} id="newmnu2">Digital Marketing</h3></Link>
                  <ul>
                 <li id="newmnu2"> <NavLink to="/hotelbookingforcorporates" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none', }} onClick={handleSubMenuItemClick} id="newmnu2">SMM</NavLink></li>
                 <li id="newmnu2"> <NavLink to="/hotelbookingforcorporates" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu2">SEO</NavLink></li>
                 <li id="newmnu2"> <NavLink to="/hotelbookingforcorporates" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu2">UI/UX Design</NavLink></li>
                 <li id="newmnu2"> <NavLink to="/hotelbookingforcorporates" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu2">Content Marketing </NavLink></li>
                 <li id="newmnu2"> <NavLink to="/hotelbookingforcorporates" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu2">Creative Marketing</NavLink></li>
                 <li id="newmnu2"> <NavLink to="/hotelbookingforcorporates" className="text-black block mb-2 mgmenu" style={{ textDecoration: 'none',  }} onClick={handleSubMenuItemClick} id="newmnu2">Tech Solutions</NavLink></li>


               </ul>
                </div>              </div>
            </div>
          )}
        </div>
        <MenuItem to="/gallery" className="slide-down " id="hmmenu" onClick={handleCloseMenu}><div className='menugll'>Gallery</div></MenuItem>
        <MenuItem to="/about" className="slide-down" onClick={handleCloseMenu}>About Us</MenuItem>
        <MenuItem to="/blog" className="slide-down" onClick={handleCloseMenu}>Blog</MenuItem>
        <MenuItem to="/contact" className="slide-down" onClick={handleCloseMenu}>Contact Us</MenuItem>
      </div>

      {/* "Book an Appointment" button */}
      {!isServicesDropdownOpen && ( // Conditionally render the button if services dropdown is not open
        <div className={`absolute bottom-0 left-0 right-0 flex items-center justify-center ${isMenuOpen ? 'block slide-down' : 'hidden'}`} onClick={handleCloseMenu}>
          <div className={`btn px-3 py-2 bts book-appointment-button`} style={{ textDecoration: 'none', color: '#ED2456', borderColor: '#ED2456', borderRadius: '0px', fontWeight: '700', fontSize: '15px', marginBottom: '10px' }} onClick={handleOpenPopup}>BOOK AN APPOINTMENT</div>
        </div>
      )}

      {/* Right-aligned "Book an Appointment" button (for desktop view) */}
      <div className="hidden lg:flex items-center justify-end">
        <div className={`btn px-3 py-2`} style={{ textDecoration: 'none', color: '#ED2456', borderColor: '#ED2456', borderRadius: '0px', fontWeight: '700', fontSize: '15px' }} onClick={handleOpenPopup}>BOOK AN APPOINTMENT</div>
      </div>
      {/* Popup */}
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </nav>
  );
}

export default Navbar;
