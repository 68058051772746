import React from 'react';
import contactnew from './images/contactnew.png'; // Import your image
import contactnew2 from './images/contactnew2.png';
import contactnew3 from './images/contactnew3.png';
import {Link} from 'react-router-dom';
import Callback  from './callback';
import Tabslide5  from './tabslide5';

function BootstrapCardsGallery() {
  return (
    
    <section className="py-5 gallery" id="gle">
       <div >
        <Callback/>
        </div>


      <div className='galmain' id="hotelb">
        <h2 className="galh"> Explore Our Collection of Events </h2>
         <p className="galp"> Discover captivating visuals in our gallery </p> 
        <center>

        <div id="tslid"><Tabslide5/></div>

        <div className="tab mt-4">
          <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
              <Link to ="/gallery" className="nav-link  gltb"  >View all</Link>
            </li>
            <li className="nav-item">
             <Link to="/corporate event" className="nav-link gltb">CORPORATE EVENTS</Link>
            </li>
            <li className="nav-item">
            <Link to="/corporate events2" className="nav-link gltb" >EXPERIENTIAL TRAVEL</Link>
            </li>
            <li className="nav-item">
            <Link to="/Brandactivation" className="nav-link gltb"   >CORPORATE GIFTING</Link>
            </li>
            <li className="nav-item">
              <Link to="/hotelbooking" className="nav-link active"  >DIGITAL MARKETING</Link>
            </li>
          </ul>
        </div>
</center>
        <div className="row mt-4 gx-4" id="mgleft">
          {/* Card 1 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={contactnew} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
                <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img  src={contactnew2} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={contactnew3} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 4 and other cards go here */}

        </div>
        <div className="row gx-4"  id="mgleft">
          {/* Card 1 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd" >
              <img src={contactnew} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd">
              <img src={contactnew2} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd">
              <img src={contactnew3} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 4 and other cards go here */}

        </div>
        <div className="row gx-4"  id="mgleft">
          {/* Card 1 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd" >
              <img src={contactnew}  className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd">
              <img src={contactnew2} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={contactnew3} className="card-img-top" alt="Thumbnail" />
              <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          {/* Card 4 and other cards go here */}

        </div>

      </div>

    </section>
  );
}

export default BootstrapCardsGallery;
