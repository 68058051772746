
/*import React from 'react';
import { Link } from 'react-router-dom';
import "./blogdescription.css";
import blogd1Image from './images/blogd1.png'; // Import sample blog image



const BlogDetail = () => {
  return (
    <div className="blog-detail-container">
      <div className="breadcrumbs">
        <Link to="/" className='blogdesbcrumb'>Home</Link> / <Link to="/blog" className='blogdesbcrumb'>blogs</Link> / <span className='blogdesp'>Richard Norton photorealistic rendering as real photos</span>  </div>
        <Link to="/blog"><p className='blogdesp2'>&#60;  &nbsp; All Posts</p></Link>
      <div className='blogdescg'>
      <div className="blog-category">Category</div>
      <div className="blog-category">Category</div>
      <div className="blog-category">Category</div>
</div>
      <h1 className="blog-title">Richird Norton photorealistic rendering as real photos</h1>

      <img src={blogd1Image} alt="Blog" className="blog-image" />


      <div id="blogtitlebanner" className="row">
    
    <div id="authname" className="col">
      <p>Written by</p>
      <h5>Full Name</h5>
    </div>
    <div className="col">
    <p>Published on</p>
    <h5>22 January 2021</h5>
    </div>
    
    <div id="empty" className="col-7">
    
    </div>
    
    <div id="social-icon" className="col">
    <p>Share on</p>
    <div className="row row-cols-auto">
    <div className="col"><i className="fa-solid fa-link"></i></div>
    <div className="col"><i className="fa-brands fa-linkedin"></i></div>
    <div className="col"><i className="fa-brands fa-x-twitter"></i></div>
    <div className="col"><i className="fa-brands fa-facebook"></i></div>
  </div>
    </div>
  </div>
     
      <div className="blog-description">
        <p className='blogdt1'>Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.</p>
        <p className='blogdt1'>Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.</p>
        <p className='blogdt2'><b>Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.</b></p>
      <p className='blogdt1'>Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.</p>
      </div>
      

   
    </div>
  );
};

export default BlogDetail;*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./blogdescription.css";
import EventManagementImage from './images/EventManagement.jpg'; // Import sample blog image
import Callback  from './callback';

const BlogDetail = () => {
  const [showMobileSocialIcons, setShowMobileSocialIcons] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    // Update isMobileView state whenever the window is resized
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to toggle the visibility of mobile social media icons
  const toggleMobileSocialIcons = () => {
    setShowMobileSocialIcons(!showMobileSocialIcons);
  };

  return (
    <div><div>
    <Callback/>
    </div>
    <div className="blog-detail-container">
      
      <div className="breadcrumbs">
        <Link to="/" className='blogdesbcrumb'>Home</Link> / <Link to="/blog" className='blogdesbcrumb'>blogs</Link> / <span className='blogdesp'>The World of Event Management Companies: Turning Dreams into Reality  </span>
      </div>
      <Link to="/blog"><p className='blogdesp2'>&#60;  &nbsp; All Posts</p></Link>
      <div className='blogdescg'>
        <div className="blog-category">Category</div>
        <div className="blog-category">Category</div>
        <div className="blog-category">Category</div>
      </div>
      <h1 className="blog-title">The World of Event Management Companies: Turning Dreams into Reality  </h1>

      <img src={EventManagementImage} alt="Blog" className="blog-image" />

      <div id="blogtitlebanner" className="row">
        <div id="authname" className="col">
          <p>Published on</p>
          <h5>16th April, 2023</h5>
        </div>
        {/*<div className="col">
          <p>Published on</p>
          <h5>22 January 2021</h5>
        </div>*/}
        <div id="empty" className="col-4"></div>
        <div id="social-icon" className="col">
          {/* Toggle button for mobile view */}
          {isMobileView && (
            <>
              <p onClick={toggleMobileSocialIcons} className="share-toggle">Share <i className='fa-solid fa-share-alt'></i></p>
              {/* Social media icons */}
              <div className={`share-icons ${showMobileSocialIcons ? '' : 'hidden'}`}>
              <div className="row row-cols-auto">
                  <div className="col socialbg"><Link to="https://www.instagram.com/vui_hospitality" target="_blank"><i className="fab fa-instagram" style={{color:'black'}}></i></Link></div>
                  <div className="col socialbg">   <Link to="https://www.linkedin.com/company/vui-hospitality-pvt-ltd/" target="_blank"><i className="fa-brands fa-linkedin"></i></Link></div>
                  <div className="col socialbg"><Link to="https://www.facebook.com/profile.php?id=100091998467476&mibextid=LQQJ4d" target="_blank"><i className="fa-brands fa-facebook"></i></Link></div>
                {/*  <div className="col socialbg"> <Link to="https://www.facebook.com/" > <i className="fa-brands fa-facebook"></i>fa-solid fa-link </Link></div>*/}
                </div>
              </div>
            </>
          )}
          {/* For non-mobile view, only display "Share on" */}
          {!isMobileView && (
            <>
              <p className='share'>Share on</p>
              {/* Social media icons */}
              <div className="row row-cols-auto">
                <div className="col socialbg"><Link to="https://www.instagram.com/vui_hospitality" target="_blank"><i className="fab fa-instagram" style={{color:'black',    fontSize:'20px'}}></i></Link></div>
                <div className="col socialbg"><Link to="https://www.linkedin.com/" target="_blank"><i className="fa-brands fa-linkedin"></i></Link></div>
                <div className="col socialbg"><Link to="https://www.facebook.com/profile.php?id=100091998467476&mibextid=LQQJ4d" target="_blank"><i className="fa-brands fa-facebook"></i></Link></div>
             {/*   <div className="col socialbg"><Link to="https://www.facebook.com/" ><i className="fa-brands fa-facebook"></i></Link></div>*/}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="blog-description">
        <p className='blogdt1'>Hosting unforgettable events is no easy feat in today's fast-paced world. Whether it's a high-powered corporate conference, a fairytale wedding, or a dazzling entertainment spectacle, the need for top-notch event planning and execution is growing rapidly. Enter event management companies, the experts in crafting extraordinary experiences. </p>
        <p className='blogdt1'>Event management companies are like the conductors of an orchestra, bringing together creativity, accurate planning, and attention to detail to ensure every event hits all the right notes. They've become the go-to solution for individuals and businesses alike who want to create truly memorable occasions.  </p>
        <p className='blogdt2'><b>Why Event Management Companies Are Taking Centre Stage  </b></p>
        <p className='blogdt1'>As the significance of making striking occasions has soaked in for organisations and people, the board business has detonated. Occasion-arranging organisations have moved forward to fill the hole, offering their ability to deal with the difficulties that accompany coordinating various kinds of occasions. </p>
        <p className='blogdt1'>These organisations are like clockwork, with different groups of occasion organisers, planners, advertisers, and strategy specialists cooperating to convey consistent and striking occasions. </p>
       <br></br>        <p className='blogdt2'><b>The Services That Make Events Extraordinary  </b></p>

       <br></br> <p className='blogdt1'>Event management companies are multi-talented, offering a wide range of services to meet diverse client needs. Some of their key offerings include:   </p>
<ul>
  <li className='blogdt1 '><b>Event Planning: </b> &nbsp;  From the initial brainstorming session to the final farewell, event planners orchestrate every detail, ensuring the theme, objectives, timeline, and budget align perfectly with the client's vision. They act as a sounding board, helping clients refine their ideas and translate them into a cohesive plan. Event planners are also ace project managers, keeping track of deadlines, vendor contracts, and logistical arrangements. 
 </li>
  <li className='blogdt1 '><b>Venue Selection and Management: </b> &nbsp;With their extensive network of contacts, event management companies can find the perfect venue to match the client's needs and preferences. Whether it's a grand ballroom, a trendy art gallery, or a rustic outdoor space, they have the expertise to find the ideal location. They also handle all the logistics related to the venue, such as contract negotiations, permits, and insurance. 
 </li>
  <li className='blogdt1 '><b>Creative Design: </b> &nbsp; Event planners are the masterminds behind the visual magic of an event. They collaborate with designers to create visually stunning environments that bring the client's vision to life. This can include everything from selecting the colour palette and décor to designing invitations and signage. They also use lighting and audio-visual elements to create a desired atmosphere and enhance the overall experience. 
 </li>
  <li className='blogdt1 '><b>Vendor Management: </b> &nbsp;Event management companies have a Rolodex of trusted vendors, including caterers, florists, photographers, entertainment companies, and more. They take care of soliciting quotes, negotiating contracts, and managing all communication with vendors. This ensures that all the different pieces of the event puzzle come together seamlessly on the big day. 
</li>
  <li className='blogdt1 '><b>Event Marketing and Promotion: </b> &nbsp;In today's digital age, promoting an event effectively requires a multi-pronged approach. Event management companies create strategic marketing and promotion plans to generate excitement and attract attendees. This may involve using a mix of digital marketing techniques, social media campaigns, traditional advertising, and public relations outreach. 
</li>
<li className='blogdt1 '><b>On-Site Coordination: </b> &nbsp;The event doesn't stop after the last guest arrives. Event management companies have a team on-site to oversee every detail and ensure the event runs smoothly according to plan. They act as the quarterback, managing vendors, troubleshooting any unexpected issues, and making sure everything flows perfectly from start to finish. 
</li>
</ul>

<br></br>        <p className='blogdt2'><b>Why Hiring an Event Management Company is a Smart Move  </b></p>

<br></br> <p className='blogdt1'>Choosing to work with an event management company offers a host of benefits that can significantly impact the success of any event.   </p>
<ul>
<li className='blogdt1 '><b>Expertise and Experience: </b> &nbsp; They bring a wealth of knowledge and experience, staying on top of trends, best practices, and potential pitfalls.  
</li>
<li className='blogdt1 '><b>Cost-effectiveness: </b> &nbsp; They often have established relationships with vendors, allowing them to negotiate better rates and save clients money. 
</li>
<li className='blogdt1 '><b>Stress Reduction:</b> &nbsp; They take the burden of planning off the client's shoulders, so they can focus on other important aspects of their life or business.  
</li>
<li className='blogdt1 '><b>Creativity and Innovation: </b> &nbsp; They thrive on creativity, coming up with unique ideas and fresh approaches to make each event truly special. 
</li>
<li className='blogdt1 '><b>Time-saving: </b> &nbsp; They handle the time-consuming tasks, freeing up clients to focus on their daily responsibilities. 
</li>
</ul>
<br></br><p className='blogdt2'><b>Event management companies and technology </b></p>

<p className='blogdt1'>In today's digital age, event management companies are embracing technology to enhance their services. Event registration platforms, management software, and virtual event solutions are now key tools in their arsenal. These innovations streamline planning, improve attendee experiences, and extend the reach of events globally. </p>
<br></br><p className='blogdt2'><b>Closure! </b></p>
<p className='blogdt1'>In conclusion, event management companies play a pivotal role in shaping extraordinary experiences for their clients. They're the experts at turning visions into reality, leaving attendees with lasting memories. This is precisely where VUI Hospitality shines. Whether you're planning a corporate conference, a dream wedding, or a community festival, VUI Hospitality is your partner in creating unforgettable events.  </p>
<p className='blogdt1'>Our team of professionals is dedicated to understanding your unique vision and exceeding your expectations. We take pride in our ability to handle every aspect of event planning, from venue selection and creative design to vendor management and on-site coordination. Our commitment to excellence and passion for crafting exceptional experiences make VUI Hospitality the premier choice for event management in Gurugram. So, when you're ready to host an event that will be talked about for years to come, trust VUI Hospitality to weave its magic and make your event an astounding success. </p>
       </div>
    </div>
    </div>
  );
};

export default BlogDetail;
