

import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import micImage from './images/mic.png'; // Assuming you have the image import
import bannerImage from './images/banner.png'
import iconImage from './images/Icon.png'
import icon2Image from './images/Icon2.png'
import icon3Image from './images/Icon3.png'
import icon4Image from './images/Icon4.png'
import icon5Image from './images/Icon5.png'
import icon6Image from './images/Icon6.png'
import icon7Image from './images/Icon7.png'
import vectorImage from './images/Vector.png'
import teambondingImage from './images/teambonding.png'
import exhibition1Image from './images/exhibition1.png'
import productImage from './images/product.png'
import conference1Image from './images/conference1.png'
import vector6Image from './images/Vector6.png'
import corporate1Image from './images/corporate1.png'


import 'bootstrap/dist/css/bootstrap.min.css';
import ourwork1Image from './images/ourwork1.png'
import ourwork2Image from './images/ourwork2.png'
import ourwork3Image from './images/ourwork3.png'
import ourwork4Image from './images/ourwork4.png'
import ourwork5Image from './images/ourwork5.png'
import ourwork6Image from './images/ourwork6.png'
import { Row, Col, Button } from 'react-bootstrap'; // Import Row, Col, and Button from react-bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderAll, faPlay } from '@fortawesome/free-solid-svg-icons';
import F1Image from "./images/F1.png";
import F2Image from "./images/F2.png";
import F3Image from "./images/F3.png";
import F4Image from "./images/F4.png";
import F5Image from "./images/F5.png";
import F6Image from "./images/F6.png";

import BL1Image from "./images/BL1.png";
import BL2Image from "./images/BL2.png";
import BL3Image from "./images/BL3.png";

import Date1Image from "./images/Date1.png";
import Date2Image from "./images/Date2.png";
import Date3Image from "./images/Date3.png";

import contactlastImage from "./images/contactlast.png";
import sp1Image from "./images/sp1.png";
import usp1Image from "./images/usp1.png";
import usp2Image from "./images/usp2.png";
import usp3Image from "./images/usp3.png";
import usp4Image from "./images/usp4.png";
import Testimonial from './testimonial'; // Adjust the path as per your file structure
import Bg12Image from "./images/Bg12.png";
import post11Image from "./images/post11.png";
import post12Image from "./images/post12.png";
import post13Image from "./images/post13.png";
import Homeslider from './Homeslider';
import twitterImage from "./images/twitter.png";
import Slide from './slide';
import Imagelayouts from "./imagelayouthome";
import Socialmedia from './socialmedia';
/*import blogsn1Image from "./images/blogsn1.png";*/
import BlogCom  from './post';
import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import PhoneInput from 'react-phone-input-2';
import sp2Image from "./images/sp2.png";
import sp3Image from "./images/sp3.png";
import sp4Image from "./images/sp4.png";
import sp5Image from "./images/sp5.png";
import sp6Image from "./images/sp6.png";
import endImage from "./images/end.png";
import keyeyeImage from "./images/keyeye.png";
import trophy1Image from "./images/trophy1.png";
import culturalImage from "./images/cultural.png";
import cuisineImage from "./images/cuisine.png";
import activitiesImage from "./images/activities.png";
import ticketflight1Image from "./images/ticketflight1.png";
import hotel1Image from "./images/hotel1.png";
import itinerary1Image from "./images/itinerary1.png";
import socialmedia1Image from "./images/socialmedia1.png";
import seo1Image from "./images/seo1.png";
import uxdesign1Image from "./images/uxdesign1.png";
import writer1Image from "./images/writer1.png";
import solution1Image from "./images/solution1.png";
import creative1Image from "./images/creative1.png";
import BudgetgiftingImage from "./images/Budgetgifting.png";
import CustomizedGiftImage from "./images/CustomizedGift.png";
import OccasionalgiftingImage from "./images/Occasionalgifting.png";
 import FestivalImage from "./images/Festival.png";
 import  HealthInsuranceImage from "./images/HealthInsurance.png";
  import  virtualreality1Image from "./images/virtualreality1.png";
    import  sustainable1Image from "./images/sustainable1.png";
    import uspn1Image from "./images/uspn1.jpg";
    import uspn2Image from "./images/uspn2.jpg";
    import uspn3Image from "./images/uspn3.jpg";
    import uspn4Image from "./images/uspn4.jpg";



function ThumbnailSlider() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // After the "API call" has finished, stop showing the loading icon
    setLoading(false);

    try {
      // Assuming sendForm returns a promise
      const result = await emailjs.sendForm('service_qx3i0t9', 'template_2htjzmp', form.current, {
        publicKey: 'gdODjJHS5A-JFuTFC',
      });
      console.log('Email successfully sent!', result.text);
      // Set form as submitted if email was sent successfully
      setSubmitted(true);
      
      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      // Reset the form here if needed
      form.current.reset();
    } catch (error) {
      console.error('Failed to send email:', error);
    }

    setLoading(false); // End loading
  };
  const filterServices = (category) => {
    switch (category) {
      case 'Corporate Events':
        return [
          'Award Ceremonies',
          'Team Building Activities',
          'Exhibitions',
          'Product Launch',
          'Conferences & Seminars',
          'Corporate Offsites',
        ];
      case 'Experiential Travel':
        return [
          'Cultural Experience',
          'Local Cuisine',
          'Local Activities',
          'Customised Itinerary',
          'Flight Tickets',
          'Hotel Booking',
        ];
      case 'Corporate Gifting':
        return [
          'Budget Gifting',
          'Customised Gifting',
          'Occasional Gifting',
          'Festival Gifting',
          'Health and Safety Gifting',
          'Virtual Gifting',
        ];
      case 'Digital Marketing':
        return [
          'SMM',
          'SEO',
          'UI/UX Design',
          'Content Marketing',
          'Tech Solutions',
          'Creative Marketing',
        ];
      default:
        return [];
    }
    
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm('service_mhkkkwa', 'template_megbwh2', form.current, {
  //       publicKey: '20SA2U48taVusKc5H',
  //     })
  //     .then(
  //       () => {
  //         console.log('SUCCESS!');
  //         setSubmitted(true);
  //         // Reset form fields after submission
  //         form.current.reset();
  //       },
  //       (error) => {
  //         console.log('FAILED...', error.text);
  //       }
  //     );
  // };

  return (
    <div id="thumbnailSlider" className="carousel slide" data-interval="false" style={{ marginTop: '-9px', position: 'relative', top: '00px' }}>
      <div>
        <Homeslider />
      </div>

      { /*  <div className="carousel-inner" >
      <div className="carousel-item active">
  <img src={micImage} className="d-block w-100" id="slideheight" alt="Slide 1" />
  <div className="carousel-caption d-none d-md-block" style={{position:'absolute',left:'4%',marginTop:'-8%',paddingTop:'5%'}}>
    <p className='mice1' >your</p>
        <h5 className='servic1' > event </h5>
        <p className='partner1' >partner</p>

  </div>
</div>
        <div className="carousel-item">
          <img src={bannerImage} className="d-block w-100" id="slideheight" alt="Slide 2" />
          <p className='mice'>  mice </p>
          <p className='servic'>  services </p>
          <p className='partner'>  partner </p>

        </div>
     
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#thumbnailSlider" data-bs-slide="prev" style={{height:'50px',position:'absolute',top: '653px'}}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#thumbnailSlider" data-bs-slide="next" style={{height:'50px',position:'absolute',top: '653px'}}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
      <div className="carousel-indicators">
      <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="0" className="active" aria-current="true" id="slideindicator">
     <div> <img src={iconImage} className="d-block imgcarosel" alt="Thumbnail 1" />
         <p className='carouselhead'>All Hotels </p><p className='carouselhead'>and Resorts</p> </div>
        </button>
      
        <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="1" id="slideindicator1">
         <div> <img src={icon2Image} className="d-block imgcarosel" alt="Thumbnail 2" />
          <p className='carouselhead'>mice </p><p className='carouselhead'> services</p> </div>

        </button>
        <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="2" id="slideindicator2">
         <div> <img src={icon3Image} className="d-block imgcarosel" alt="Thumbnail 3" />
          <p className='carouselhead'>Private </p><p className='carouselhead'>Retreats</p> 
</div>
        </button>
        <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="3" id="slideindicator3">
        <div>  <img src={icon4Image} className="d-block imgcarosel" alt="Thumbnail 3" />
          <p className='carouselhead'>Shop </p>
</div>
        </button>
        <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="4" id="slideindicator4">
        <div>  <img src={icon5Image} className="d-block imgcarosel" alt="Thumbnail 4" />
          <p className='carouselhead'>Meetings and </p><p className='carouselhead'>Events</p> 
</div>
        </button>
        <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="5" id="slideindicator5">
        <div>  <img src={icon6Image} className="d-block imgcarosel" alt="Thumbnail 5" />
          <p className='carouselhead'>Dining </p></div>
        </button>
        <button type="button" data-bs-target="#thumbnailSlider" data-bs-slide-to="6" id="slideindicator">
        <div>  <img src={icon7Image} className="d-block imgcarosel" alt="Thumbnail 6" />
          <p className='carouselhead'>Private Jet </p>
</div>
        </button>
     </div>
  */}
      <br />
      {/*<nav className='followdiv'>
        <ul>
          <div className='follow'>Follow us </div>
          <hr className='followhr'></hr>
          <Link to="https://www.facebook.com/" >  <li className='fbfollow'> <i className="fab fa-facebook-f sdfb"style={{ color: 'white', fontSize: '14px' }}></i></li></Link>
          <Link to="https://twitter.com/?lang=en"> <li className='twitterfollow'><img src={twitterImage} className="fab fa-twitter" style={{ color: 'white', fontSize: '14px' }} ></img></li></Link>
          <Link to="https://www.instagram.com/"><li className='instafollow'><i className="fab fa-instagram" style={{ color: 'white', fontSize: '14px' }}></i></li></Link>
          <Link to="https://www.linkedin.com/"><li className='linkfollow'><i className="fab fa-linkedin-in" style={{ color: 'white', fontSize: '14px' }}></i></li></Link>

        </ul>
</nav>*/}




<div>
  <Socialmedia/>
</div>






      <div className='nvdiv'>
        <div className='homedv'>
          <h1 className='followabout'>about us</h1>
          <p className='followp'>Think of us not as regular event planners, but as creators of experiences. We don't just plan events; we create stories<br></br> that stay with you long after the success. How do we do it? With a bit of creativity, a touch of fun, and endless imagination. 
          </p>
          <Row style={{ paddingBottom: '193px' }}>
          {/*  <Col xs={6} md={6} className="text-right" style={{ position: 'relative', top: '282px', left: '-43px' }}>
              <Button variant="dark" className="play-button pbtnmain" style={{ width: '65px', height: '65px', borderRadius: '100px', zIndex: '50' }}><FontAwesomeIcon icon={faPlay} className='videobtn'/></Button>
</Col>*/}
            <Col xs={6} md={6}>
              <Link to="/About"> <Button variant="success" className='abtbutton'>about us</Button></Link>
            </Col>
          </Row>
          <p className='homeabout'>about</p>
          <p className='weare'> who we are </p>
        </div>
      </div>
      <p className='ouroffering' style={{ marginTop: '-4%' }}>our offerings</p>
      <p className='ourserv' style={{ marginTop: '-17.5%' }}>OUR SERVICES </p>
      <div className="row border-row"  >
      <Link to="/Services" className="col-lg border-column1" >
            <div className="row mb-3"><p className='cpevent'>CORPORATE EVENTS </p>
              <p className='cpevpera'>Transforming corporate events with bespoke, innovative solutions that turn visions into momentous experiences. </p>
              {  /* <p className='cpevpera' style={{lineHeight:'0%'}}>lorem quis ...</p>*/}
              <div className='alloffering'>all offerings</div>
            </div>
          <div className="row mb-3 mbtable" style={{ marginTop: '3%', paddingBottom: '2.5%' }}><p className='vcnumber'>01</p>
            <div className="col-lg vc" id="cv11">
              <img src={trophy1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt1'>	Award <br></br>Ceremonies</span>
            </div>
            <p className='vcnumber2'>02</p>
            <div className="col-lg vc" id="cv12">
              <img src={teambondingImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt2'>Team Building <br></br>Activities</span>
            </div>
            <p className='vcnumber3'>03</p>
            <div className="col-lg vc" id="cv13">
              <img src={exhibition1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt3'>	 Exhibitions</span>
            </div>
          </div>
          <div className="row mb-3 mbtable2" style={{ marginTop: '3%' }}><p className='vcnumber6'>06</p>
            <div className="col-lg vc" id="cv14">
              <img src={productImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt4'>	 Product Launch </span>
            </div>
            <p className='vcnumber5'>05</p>
            <div className="col-lg vc" id="cv15">
              <img src={conference1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt5'>	 Conferences &<br></br> Seminars</span>
            </div>
            <p className='vcnumber4'>04</p>
            <div className="col-lg vc" id="cv16">
              <img src={corporate1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt6'> Corporate<br></br> Offsites</span>
            </div>
          </div>
        </Link>
        <Link to="/Exhibitionshowservices" className="col-lg border-column2" >
        
            <div className="row mb-3"><p className='cpevent'>	Experiential Travel</p>
              <p className='cpevpera'>Experience more than sightseeing – immerse in the local flavour for journeys that weave lasting memories. </p>
              {/*<p className='cpevpera' style={{lineHeight:'0%'}}>lorem quis ...</p>*/}
              <div className='alloffering'>all offerings</div>
            </div>
          
          <div className="row mb-3 mbtable" style={{ marginTop: '3%', paddingBottom: '2.5%' }}><p className='vcnumber21'>01</p>
            <div className="col-lg vc1" id="cv31">
              <img src={culturalImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt11'>	Cultural <br></br>Experience </span>
            </div>
            <p className='vcnumber22'>02</p>
            <div className="col-lg vc" id="cv32">
              <img src={cuisineImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt12'>	Local Cuisine</span>
            </div>
            <p className='vcnumber23'>03</p>
            <div className="col-lg vc" id="cv33">
              <img src={activitiesImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt13'>	Local Activities</span>
            </div>


          </div>
          <div className="row mb-3 mbtable2" style={{ marginTop: '3%' }}><p className='vcnumber26'>06</p>
            <div className="col-lg vc" id="cv34">
              <img src={itinerary1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt14' style={{ position: 'relative', }}>	Customised <br></br>Itinerary</span>
            </div>
            <p className='vcnumber25'>05</p>
            <div className="col-lg vc" id="cv35">
              <img src={ticketflight1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt15'>	Flight Tickets</span>
            </div>
            <p className='vcnumber14'>04</p>
            <div className="col-lg vc" id="cv36">
              <img src={hotel1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt16'>	Hotel Booking </span>
            </div>


          </div>

        </Link>
      </div>
      <div className="row border-row" style={{ marginTop: '48px' }}>
      <Link to="/Brandactivationservices" className="col-lg border-column3" >
         
            <div className="row mb-3"><p className='cpevent'>Corporate Gifting</p>
              <p className='cpevpera'>Uplifting corporate relationships with thoughtful, customised gifts that leave a lasting impression. </p>
              { /*  <p className='cpevpera' style={{lineHeight:'0%'}}>lorem quis ...</p>*/}
              <div className='alloffering'>all offerings</div>
            </div>
          <div className="row mb-3 mbtable" style={{ marginTop: '3%', paddingBottom: '2.5%' }}><p className='vcnumbern1'>01</p>
            <div className="col-lg vc" id="cv21">
              <img src={BudgetgiftingImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt21'>	Budget Gifting</span>
            </div>
            <p className='vcnumbern2'>02</p>
            <div className="col-lg vc" id="cv22">
              <img src={CustomizedGiftImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt22'>	Customised <br></br>Gifting</span>
            </div>
            <p className='vcnumbern3'>03</p>
            <div className="col-lg vc" id="cv23">
              <img src={OccasionalgiftingImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt23'>	Occasional <br></br>Gifting </span>
            </div>


          </div>
          <div className="row mb-3 mbtable2" style={{ marginTop: '3%' }}><p className='vcnumber6' id='v6'>06</p>
            <div className="col-lg vc" id="cv24">
              <img src={FestivalImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt24' style={{ position: 'relative', left: '5%' }}>	Festival <br></br>Gifting   </span>
            </div>
            <p className='vcnumber5' id="v5">05</p>
            <div className="col-lg vc" id="cv25">
              <img src={HealthInsuranceImage} alt="Description of image" className='vcimg1' />
              <span className='vctxt25'>	Health and <br></br>Safety Gifting</span>
            </div>
            <p className='vcnumber24'>04</p>
            <div className="col-lg vc" id="cv26">
              <img src={sustainable1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt26'>	Sustainable <br></br>Gifting</span> 
            </div>


          </div>

        </Link>

        <Link to="/hotelbookingforcorporates" className="col-lg border-column4" >
         
            <div className="row mb-3"><p className='cpevent'>Digital Marketing </p>
              <p className='cpevpera'>Enhance your brand's digital voyage with tailored marketing tactics aimed at captivating audiences and achieving measurable growth. </p>
              { /* <p className='cpevpera' style={{lineHeight:'0%'}}>lorem quis ...</p>*/}
              <div className='alloffering'>all offerings</div>
            </div>
          <div className="row mb-3 mbtable" style={{ marginTop: '3%', paddingBottom: '2.5%' }}><p className='vcnumber11'>01</p>
            <div className="col-lg vc1" id="cv2">
              <img src={socialmedia1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt31'>	SMM</span>
            </div>
            <p className='vcnumber12'>02</p>
            <div className="col-lg vc" id="cv1">
              <img src={seo1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt32'>	SEO  </span>
            </div>
            <p className='vcnumber13'>03</p>
            <div className="col-lg vc" id="cv3">
              <img src={uxdesign1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt33'>	UI/UX Design</span>
            </div>


          </div>
          <div className="row mb-3 mbtable2" style={{ marginTop: '3%' }}><p className='vcnumber16'>06</p>
            <div className="col-lg vc" id="cv4">
              <img src={writer1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt34'>	Content<br></br> Marketing</span>
            </div>
            <p className='vcnumber15'>05</p>
            <div className="col-lg vc" id="cv5">
              <img src={solution1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt35'>	Tech Solutions</span>
            </div>
            <p className='vcnumber4'>04</p>
            <div className="col-lg vc" id="cv6">
              <img src={creative1Image} alt="Description of image" className='vcimg1' />
              <span className='vctxt36'>	Creative <br></br>Marketing</span>
            </div>


          </div>

        </Link>
      </div>
      {/*our speciality start*/}
      <div className="row border-rown" id="pdbottom" style={{ marginTop: '5%' }}>
        <div className="col border-column1n" >
            <p className='cpevperan'>OUR USP </p>
          <div className="row mb-3">
            <p className='cpeventn'>What Makes Us Stand Out?  </p>
            {/*  <p className='cpeventn'>speciality </p>*/}

          </div>



        </div>

        <div className="col-lg border-column2n" >
          <div className="row mb-3">
          </div>
          <div className="row mb-3" style={{ marginTop: '3%', paddingBottom: '2.5%' }}>

            <div className="col-lg-4 col-md-6 mbboxwith" style={{ margin: '0px', padding: '0px' }}>
              <img src={endImage} alt="Description of image" className='vcimg1n' />
              <span className='vctxt1n'>End-to-End Solutions </span>
              <p className='vcpn'>We provide end-to-end solutions, managing everything so that our clients experience a smooth experience for your events or travel.  </p>
            </div>
            <div className="col-lg-4 col-md-6 mbboxwith1">
              <img src={keyeyeImage} alt="Description of image" className='vcimg1n' />
              <span className='vctxt1n'>	Keen Eye for Detail </span>
              <p className='vcpn'>We provide expert support and keep a keen eye for the smallest details because expertise is our main forte.  </p>

            </div>

            <div className="col-lg mbboxwith3">
              <img src={sp3Image} alt="Description of image" className='vcimg1n' />
              <span className='vctxt1n' id="clisp">	24*7 Client Support</span>
              <p className='vcpn' id="whop">We hope for nothing but the best experience for our clients and we are available all around the clock for them.  </p>

            </div>


          </div>
          <div className="row mb-3" style={{ marginTop: '3%' }}>
            <div className="col-lg mbboxwith4" style={{ margin: '0px', padding: '0px' }}>
              <img src={sp4Image} alt="Description of image" className='vcimg1n' />
              <span className='vctxt1n' id="dsm1">	Industry Experts at Your Service </span>
              <p className='vcpn'id="whop1">Our team consists of seasoned industry experts ready to serve our clients with the best experience and guidance possible.  </p>

            </div>
            <div className="col-lg mbboxwith5">
              <img src={sp5Image} alt="Description of image" className='vcimg1n' />
              <span className='vctxt1n' id="dsm2">	Dedicated Service Managers</span>
              <p className='vcpn' id="osm">Our service managers are extremely dedicated towards out clients ensuring a seamless and smooth event experience.  </p>

            </div>
            <div className="col-lg mbboxwith6">
              <img src={sp6Image} alt="Description of image" className='vcimg1n' />
              <span className='vctxt1n' id="dsm3">	Vast Network of Global Suppliers & Vendors</span>
              <p className='vcpn'>We have a vast network of global suppliers and vendors at our disposal so that we can provide the best services to our clients.  </p>

            </div>


          </div>

        </div>
        <p className='usp'>usp</p>
      </div>/
      <div class="row no-gutters uspcol">
        <div class="col">
          <img src={uspn1Image} />
        </div>
        <div class="col">
          <img src={uspn2Image} />
        </div>
        <div class="col">
          <img src={uspn3Image} />
        </div>
        <div class="col">
          <img src={uspn4Image} />
        </div>
      </div>


      {/*Orange Cotainer Section*/}

      <div>
      {/*  <img src={Bg12Image} class="Bg12n" alt="" />*/}
     {/*   <section>
          <div className="totalOrange">
            <h2 className="Obh1n">PARTNERS</h2>
            <h1 className="Obh2n">Clients Who Trust Us</h1>
            <div>
              <img className="F11n" src={F1Image} alt="" />
            </div>
            <div>
              <img className="F12n" src={F2Image} alt="" />
            </div>
            <div>
              <img className="F13n" src={F3Image} alt="" />
            </div>
            <div>
              <img className="F14n" src={F4Image} alt="" />
            </div>
            <div>
              <img className="F15n" src={F5Image} alt="" />
            </div>
            <div>
              <img className="F16n" src={F6Image} alt="" />
            </div>
          </div>
</section>*/}


<div id="Imagelayout" className="ig" style={{marginTop:'-149px'}}>
          <Imagelayouts/>
        </div>
          

        {/*portfoloi start*/}
        <div className='ourbg' >
          <div>
            <p className='ouroffering2'>PORTFOLIO </p>
            <p className='ourserv2'>Our Work </p>
          </div>
          <div className="row masonry-grid" style={{ marginTop: '2.5%' }}>
            <div className="col-md-4 masonry-column">
              <div className="masonry-item"><img src={ourwork1Image} className="ourworkmb" style={{ width: '156.7%', maxWidth: '854px', height: '380px' }} /> </div>
              <div class="masonry-container">
                <div class="masonry-item1"><img src={ourwork2Image} className="ourworkmb2" style={{ maxWidth: '162%', position: 'relative', top: '1.9%', width: '155%', height: '379px' }}></img></div>
                <div class="masonry-item1"><img src={ourwork3Image} className="ourworkmb3" style={{ width: '155.9%', height: '379px', maxWidth: '162%', position: 'relative', left: '57.5%', top: '2%' }} /></div>
              </div>
            </div>
            <div className="col-md-4 masonry-column">
              <div className="masonry-item"><img src={ourwork4Image} className="ourworkmb4" style={{ height: '766px', width: '76.7%', position: 'relative', left: '53%' }} /> </div>
            </div>
            <div className="col-md-4 masonry-column">
              <div className="masonry-item"><img src={ourwork5Image} className="ourworkmb5" style={{ width: '74%',  position: 'relative', left: '26%', height: '379px' }} /></div>
              <div className="masonry-item"><img src={ourwork6Image} className="ourworkmb6" style={{ width: '74%', position: 'relative', left: '26%', top: '9px', height: '379px' }} /> </div>
            </div>
          </div>
        </div>

        { /*testimonial*/}
        <section className="testimonial-section">

          <Testimonial />
        </section>
<div className='blogsec'>
        <div className="macblog">



          {/************blog************* */}
          <div className='nvevent'><h3 className="Blogh11">INSIGHTS </h3>
            <h1 className="Blogh12">Latest Blogs & <br></br>News Coverage</h1>
          </div>
         {/* <div className="row row-cols-1 row-cols-lg-3 g-3 rcolnew" >
            <div className="col">
              <div className="card postbg1" style={{ backgroundColor: '#fff0' }}>
                <img src={post11Image} className="card-img-top BL1imagepost1" alt="Hollywood Sign on The Hill" />
                <div className='Date1post'>Jun<br></br>15th</div>
                <div className="card-body blogbox">
                  <p className="BT-blog1">Business, Technolog </p>
                  <h5 className="card-title ES-blog1new">Engineering Survey Week</h5>
                  <p className="card-text LO-blog1new">
                    Lorem Ipsum dolor sit amet quid
                    dolormentum. Proin gravida nibh
                    vel velit auctor aliquet. Aenean
                    sollicitudin, lorem quis bibendum
                    auctorelit consequat ipsum, nec
                    sagittis sem ...       </p>
                  <Link to="/Blog">  <h4 className="RM-blog1new"> Read More</h4></Link>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card postbg2" style={{ backgroundColor: '#fff0' }}>
                <img src={post12Image} className="card-img-top BL2imagepost2" alt="Palm Springs Road" />
                <div className='Date2post2'>Jun<br></br>14th</div>

                <div className="card-body blogbox2">
                  <p className="BT-blog2">Business, Technology</p>

                  <h5 className="card-title ES-blog2new">Sharing Your Knowledge</h5>
                  <p className="card-text LO-blog2new">
                    Lorem Ipsum dolor sit amet quid
                    dolormentum. Proin gravida nibh
                    vel velit auctor aliquet. Aenean
                    sollicitudin, lorem quis bibendum
                    auctorelit consequat ipsum, nec
                    sagittis sem ...</p>
                  <Link to="/Blog">  <h4 className="RM-blog2new"> Read More</h4></Link>

                </div>
              </div>
            </div>
            <div className="col">
              <div className="card postbg3" style={{ backgroundColor: '#fff0' }}>
                <img src={post13Image} className="card-img-top BL3imagepost3" alt="Palm Springs Road" />
                <div className='Date3post3'>Jun<br></br>14th</div>

                <div className="card-body blogbox3">
                  <p className="BT-blog2">Business, Technology </p>

                  <h5 className="card-title ES-blog3new">Lightning Talks Metric Meetup</h5>
                  <p className="card-text LO-blog3new">
                    Lorem Ipsum dolor sit amet quid
                    dolormentum. Proin gravida nibh
                    vel velit auctor aliquet. Aenean
                    sollicitudin, lorem quis bibendum
                    auctorelit consequat ipsum, nec
                    sagittis sem ... </p>
                  <Link to="/Blog">  <h4 className="RM-blog3new"> Read More</h4></Link>

                </div>
              </div>
            </div>
          </div>*/}
         
      {/*<img src={blogsn1Image} className='blogsn1'/>*/}
          <div >
{/*<div className='blogsn2' id='blg2'>
<Slide />
        </div>*/}
        <div id='bn3'>
<div className='blogn3' id="bnth"><BlogCom/></div>
</div>
</div>
</div>
          {/**********blog end********* */}
          <div className="formend" style={{ background: 'white', borderRadius: '40px', paddingBottom: '4%', }}>

            {/*form*/}

            <div className="mrg" style={{ marginTop: '-7%' }}>

              <div className="row">
                <div className="col contcol">
                  <div className="wrapper">
                    <div className="row no-gutters justify-content-between">
                      <div className="col-lg-6 d-flex align-items-stretch gtboxn">
                        <div className="info">
                          <img src={contactlastImage} className='cntimg' />

                        </div>
                      </div>
                      <div className="col contn">
                        <div className="contact-wrap getcont">
                          <h3 className="mb-4 contform">Tell Us Your Requirement </h3>
                          <div id="form-message-warning" className="mb-4"></div>
                          <div id="form-message-success" className="mb-4 contformp" style={{ lineHeight: '39px' }}>
                            Drop us your contact details and our expert will get in touch with you.   </div>
                          <form ref={form} onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label htmlFor="inputAddress" className='fname'>Full Name* </label>
                              <input type="text" name='fname' className="form-control hmform" id="inputname" placeholder="Your Full Name " required style={{ width: '97%', height: '49px', borderRadius: '6px' }} />
                            </div>
                            <div className="form-row" style={{ marginTop: '1%' }}>

                            <div className="form-group col-md-6 contactcontfeild">
                          <label htmlFor="phoneNumber" className="fphone" id="phn">
                            Phone Number*
                          </label>
                          <div className="input-group contactfrmph">
                          <PhoneInput
                            country={'in'}
                            inputProps={{
                              name: 'fphone',
                              required: true,
                              className: 'form-control contactfc',
                              id: 'phoneNumber',
                              placeholder: 'Enter Your Phone Number',
                            }}
                            disableCountryCode={false} // Show country code
                          />
                            <input type="tel" name='fphone' className="form-control contactfc" id="phoneNumber" placeholder="9999****** " required style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: '0.88px solid' }} />
                          </div>
                        </div>

                              <div className="form-group col-md-6 contfeild">
                                <label htmlFor="inputEmail4" className='femail'>Your Email ID</label>
                                <input type="email" name='femail' className="form-control hmemail" id="inputEmail4" placeholder="Enter Your Email Id" style={{ height: '49px', borderRadius: '6px' }} required />

                              </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6 contfeild">
  <label htmlFor="inputState" className="fcategary">
    Category’s{' '}
  </label>
  <div className="custom-select">
  <select name='fcategary' id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
  <option value="" disabled={selectedCategory !== ''} style={{color:'red'}} id="sdg">Select Your Category</option>
  <option value="Corporate Events">Corporate Events</option>
  <option value="Experiential Travel">Experiential Travel</option>
  <option value="Corporate Gifting">Corporate Gifting</option>
  <option value="Digital Marketing">Digital Marketing</option>
</select>

   {/* <span className="custom-arrow">&#9660;</span> {/* Unicode arrow */}
  </div>
</div>

                              <div className="form-group col-md-6 contfeild">
  <label htmlFor="inputState" className="fservices">
    Services{' '}
  </label>
  <div className="custom-select">
    <select name="fservices" id="inputServices" className="form-select selectcat">
      <option selected>Select Your Services </option>
      {filterServices(selectedCategory).map((service, index) => (
        <option key={index} name="fservices">{service}</option>
      ))}
    </select>
  {/*  <span className="custom-arrow">&#9660;</span> {/* Unicode arrow */}
  </div>
</div>
                              <div className="form-group">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                                  <label className="form-check-label frcheckbox" htmlFor="invalidCheck2">
                                    By Clicking The Button Below, You Agree To Our Terms Of Service And Acknowledge Our Global Privacy Policy.        </label>
                                </div>
                              </div>
                            </div>
                            <button type="submit" className="btn sub" disabled={loading}>
                              SUBMIT {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                              </button>
                          </form>
                          {submitted && (
                            <div className="alert alert-success" role="alert" style={{marginTop: '60px'}}>
                            Your Form is submitted Successfully.
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/*<div>
  <BlogCarousel/>
</div>*/}

      

    </div>
  );
}

export default ThumbnailSlider;