import React from 'react';
import ProcessImage from "../images/Process.png"; 
import ProcessVerticalImage from "../images/ProcessVertical.png"; 

const ProcessComponent = () => {
  return (
    
    <div>
         <style>
        {`
          #aboutdd {
          margin-top:-68px;
          height:530px
          }
          #aboutdd .COprocessMain {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 32px;
    word-spacing: 8px;
    font-weight: 400;
    left: 600px;
}
   #aboutdd  .COprocessMain2 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 23px;
    font-size: 20px;
    font-weight: 500;
    left: 441px;
}
    #aboutdd .COProcess {
    position: relative;
    width: 1063px;
    height: 150px;
    left: 13%;
    top: 0px;
    margin-top: 65px;
}
   #aboutdd  .SubCOprocess1 {
    position: relative;
    align-items: center;
    color: #ECA46C;
    margin-top: 29px;
    font-size: 20px;
    font-weight: 400;
    left: 239px;
    width: 97px;
    text-align: center;
    top: -13px;
}
    #aboutdd .SubCOprocess11 {
  position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 199px;
    width: 187px;
    line-height: 21px;
    text-align: center;
    top: -3px;
}
  #aboutdd   .SubCOprocess2 {
   left: 536px;
    position: relative;
    align-items: center;
    color: #ECA46C;
    margin-top: 29px;
    font-size: 20px;
    font-weight: 400;
    /* left: 234px; */
    width: 97px;
    text-align: center;
    top: -160px;
}
 #aboutdd .SubCOprocess22 {
   position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 497px;
    width: 187px;
    line-height: 24px;
    text-align: center;
    top: -129px;
}
   #aboutdd  .SubCOprocess3 {
   position: relative;
    align-items: center;
    color: #ECA46C;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 400;
    left: 840px;
    width: 105px;
    top: -305px;
}
   #aboutdd  .SubCOprocess33 {
   position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 797px;
    width: 187px;
    line-height: 20px;
    text-align: center;
    top: -272px;
}
   #aboutdd  .SubCOprocess4 {
    position: relative;
    align-items: center;
    color: #ECA46C;
    margin-top: 0px;
    font-size: 20px;
    font-weight: 400;
    left: 1147px;
    top: -452px;
}
  #aboutdd  .SubCOprocess44 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 1099px;
    width: 187px;
    line-height: 24px;
    text-align: center;
    top: -422px;
}
     @media (max-width: 767px) {
#aboutdd {
    margin-top: 188px;
    height: 830px;
}
    #aboutdd .COprocessMain {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 32px;
    word-spacing: 8px;
    font-weight: 400;
    left: 4%;
}
    #aboutdd .COprocessMain2 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 23px;
    font-size: 20px;
    font-weight: 500;
    left: 4%;
}
      #aboutdd  img#COVerticalS {
        display: block;
        z-index: 1;
        position: relative;
        top: 00px;
        left: 0px;
        padding-top: 39px;
    }
        #aboutdd .SubCOprocess11 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: -21px;
    font-size: 14px;
    font-weight: 500;
    left: 0px;
    width: 187px;
    line-height: 21px;
    text-align: center;
    top: -3px;
    margin-left: 55%;
    top: 0px;
    width: 159px;
}
    #aboutdd .SubCOprocess1 {
    position: relative;
    align-items: center;
    color: #ED2456;
    margin-top: -913px !important;
    font-size: 16px;
    font-weight: 700;
    left: 0%;
    width: 97px;
    text-align: left;
    top: -13px;
    margin-left: 55%;
}
    #aboutdd .SubCOprocess2 {
    left: 0px;
        position: relative;
        align-items: left;
        color: #ED2456;
        margin-top: 0px;
        font-size: 16px;
        font-weight: 700;
        /* left: 234px; */
        width: 97px;
        text-align: left;
        top: 244px;
        margin-left: 55% !important;
}
        #aboutdd .SubCOprocess22 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 0px;
    width: 159px;
    line-height: 24px;
    text-align: center;
    top: 258px;
    margin-left: 55% !important;
}

#aboutdd .SubCOprocess33 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 0px !important;
    width: 159px;
    line-height: 20px;
    text-align: center;
    top: 259px !important;
    margin-left: 55% !important;
}
    
#aboutdd .SubCOprocess3 {
    position: relative;
    align-items: center;
    color: #ED2456;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 700;
    left: 840px;
    width: 105px;
    top: 255px !important;
    margin-left: 55% !important;
}
    #aboutdd .SubCOprocess44 {
    position: relative;
    align-items: center;
    color: #000000;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    left: 0px !important;
    width: 187px;
    line-height: 24px;
    text-align: center;
    top: 309px !important;
    margin-left: 55% !important;
            width: 162px;
}
    #aboutdd .SubCOprocess4 {
    position: relative;
    align-items: center;
    color: #ED2456;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 700;
    left: 0px;
    top: 307px !important;
    margin-left: 55% !important;
}
        }
@media only screen and (min-width: 360px) and (max-width: 360px){

    #aboutdd .SubCOprocess2 {
                top: 210px;
        }
                    #aboutdd .SubCOprocess22 {
                top: 212px;
        }
                    #aboutdd .SubCOprocess4{
                top: 296px !important;
        }
                    #aboutdd .SubCOprocess44 {
                top: 294px !important;
        }

        }
        @media only screen and (min-width: 1280px) and (max-width: 1280px){

 #aboutdd .SubCOprocess1 {
    left: 206px; 
        }
    #aboutdd .SubCOprocess11 {
    left: 168px; 
        }

#aboutdd .SubCOprocess2 {
    left: 503px; 
        }
    #aboutdd .SubCOprocess22 {
    left: 469px; 
        }   
    #aboutdd .SubCOprocess3 {
    left: 809px; 
        }
    #aboutdd .SubCOprocess33 {
    left: 770px; 
        }  
     #aboutdd .SubCOprocess4 {
left: 1120px;
            }
    #aboutdd .SubCOprocess44 {
    left: 1069px; 
        }        
        }
        @media only screen and (min-width: 428px) and (max-width:428px){
    #aboutdd .SubCOprocess2 {
        top: 208px;
        }
         #aboutdd .SubCOprocess22 {
        top: 212px;
        }
        }
       @media only screen and (min-width: 1440px) and (max-width: 1440px){
#aboutdd .SubCOprocess1 {
   
    left: 225px;
}
#aboutdd .SubCOprocess11 {
  
    left: 182px;
}
        #aboutdd .SubCOprocess2 {
top:-182px;
    left: 524px;}



#aboutdd .SubCOprocess3{
    top:-326px;
    left: 828px;}
    #aboutdd .SubCOprocess4{
        top:-474px;
    left: 1133px;}
        #aboutdd .SubCOprocess22{
            top:-150px;
            left: 484px;}
            #aboutdd .SubCOprocess33{
                top:-292px;
            left: 787px;}
                #aboutdd .SubCOprocess44{
                    top:-443px;
                    left: 1086px;}
        }          
          `}
      </style>
      <h1 className="COprocessMain" id="COprocessMain">
        How We Function?
      </h1>
      <h2 className="COprocessMain2" id="COprocessMain2">
        Below is our functioning process from filling the form to execution.
      </h2>
      <img className="COProcess" id="COProcess" src={ProcessImage} alt="Process Image" />
      <img className="COVertical" id="COVerticalS" src={ProcessVerticalImage} alt="Vertical Process Image" />

      <h2 className="SubCOprocess1" id="SubCOprocess1">
        FILL IN THE FORM
      </h2>
      <p className="SubCOprocess11" id="SubCOprocess11">
      Start by filling out our simple form to tell us about your event needs and preferences.       </p>
      <h2 className="SubCOprocess2" id="SubCOprocess2">
        REVIEW
      </h2>
      <p className="SubCOprocess22" id="SubCOprocess22">
      Our team carefully reviews your details to understand your vision and tailor a plan that's just right for you.       </p>
      <h2 className="SubCOprocess3" id="SubCOprocess3">
        CONNECT
      </h2>
      <p className="SubCOprocess33" id="SubCOprocess33">
      We'll reach out to discuss your event in more detail, answer any questions you have, and present you with customized solutions.       </p>
      <h2 className="SubCOprocess4" id="SubCOprocess4">
        EXECUTE
      </h2>
      <p className="SubCOprocess44" id="SubCOprocess44">
      With your approval, we'll execute your event, ensuring every detail aligns with your expectations and leaves a lasting impression.       </p>
    </div>
  );
};

export default ProcessComponent;
