

import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is properly imported
import blogsliderImage from './images/blogslider.png';
import {Link} from 'react-router-dom';
import post11Image from "./images/post11.png";
import post12Image from "./images/post12.png";
import post13Image from "./images/post13.png";
import post14Image from "./images/post14.png";

import Date1Image from "./images/Date1.png";
import Date2Image from "./images/Date2.png";
import Date3Image from "./images/Date3.png";
import "./Blog.css";
import Callback  from './callback';
import overlay2Image from './images/overlay2.png'; 

import overlay1Image from './images/overlay1.png'; 
import overlay3Image from './images/overlay3.png'; 
import Artboard2ncopyImage from "./images/Artboard2ncopy.png";
import Artboard2ncopy3Image from "./images/Artboard2ncopy3.png";
import Artboard2copyn2Image from "./images/Artboard2copyn2.png";
import { Container, Row, Col } from 'react-bootstrap';
const BlogCarousel = () => {
  return (
    <>
      <style>
        {`
        @media only screen and (min-width: 1440px) and (max-width: 1440px){

          #mn2  .carousel-indicators {
              position: relative !important;
              top: 557px !important;
              margin-left: 75px !important;
              float: left;
          }
          .sdp5{
            left:-246px
          }
          .sdp4{
            left:-339px
          }
          .slidebtn{  
            margin-left: -180px !important;
        margin-top: 59px !important;
        }
      }
        @media only screen and (max-width: 390px) and (max-height: 844px) {
          .Bloghpost12{
            top: -100px !important;   
          padding-bottom:10px       }
          }

@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .bgmrg {
            /* padding-left: 0px !important; */
            margin-left: -20px;
        }
          .Bloghpost12 {
            position: relative;
        top: -9px ;
        padding-bottom: 122px;
        }
      }
        @media only screen and (max-width: 414px) and (max-height: 896px) {
          .bgmrg{
            margin-left:-4px !important;
          }
          .Bloghpost12 {
            margin-left: 72px !important;   
                     margin-top: 98px !important;
                     top:-86px ;
                     padding-bottom:20px;
        }
        .rcol {
         
          margin-top: -135px;
      }
          .card-img-top {
              
              margin-left: 0% !important;
             
          }
          .BL13imagepost3 {
            left: 0px !important;
    }
    .BL1imagepost1 {
    
      left: 0px !important;
  }
      
  .BL11imagepost1 {
   
    left: 0px !important;
}}
      
      
          .carousel-indicators {
            position: relative !important;
            top: 534px !important;
            margin-left: 80px !important;
            float: left;
        }
        .carousel-indicators [data-bs-target] {
         
       background-color: rgba(255, 255, 255, 0.2) !important; 
          background-clip: content-box;
          
          opacity: .5;
          width: 10px !important;
          height: 10px !important;
          border-radius: 100%;
      }
      .carousel-indicators .active {
        border-top: 0px;
        background:white !important;
        padding-top: 0%;
        padding-bottom: 0%;
        margin-top: 10px;
        border: none !important;
        opacity:1 !important
    }

          .carousel-indicators li {
            background-color: #888; /* Color of inactive indicators */
            border-radius: 50%; /* Make indicators circular */
            border: none; /* Remove border */
            width: 10px !imporatnt; /* Size of indicators */
            height: 10px !important; /* Size of indicators */
            margin: 0 5px !important; /* Adjust spacing between indicators */
          }
          .carousel.slide{
            margin-top:-33px;

          }
          @media (max-width: 768px) {
           #mn2 .carousel-indicators {
              
              margin-left: 30px !important;
              float: left;
          }
            .slidebtn2 {
              padding-right: 4.5%;
        position: relative;
        left: -35px !important;
        top: -190px !important;
        /* margin-top: -400px !important; */
        padding-bottom: 2px;
            }
            .bgmrg{
              margin-left:-16px ;
            }
            .BL13imagepost3 {
            
              left: 0px !important;
              margin-left: 0px;
          }
          .BL12imagepost2 {
         
            left: 0px;
          
            margin-left: 0px;
        }
        .BL2imagepost2 {
          margin-left: 0px;
      }
      .Bloghpost12{
        margin-left: 85px;
        margin-top: 241px !important;
        padding-bottom: 111px;
    }
            .carousel-indicators [data-bs-target]{
              position:relative;
              top:148px;
              left:-10px
            }
          .carousel-indicators {
            position: relative !important;
            top: 750px !important;
            margin-left: 24px !important;
            float: left;
        }
      }
      @media (max-width: 1280px) and (max-height: 800px) {
        .carousel-indicators {
          position: relative !important;
          top: 572px !important;
          margin-left: 50px !important;
          float: left;
      }
    }
        `}
      </style>
      <div>
        <Callback/>
        </div>
      <Carousel indicators={true} interval={null} nextIcon="" prevIcon="" id="mn2">        <Carousel.Item>
          <img className="d-block w-100" src={overlay1Image} alt="First slide" />
          <Carousel.Caption className="text-start">
       {/*   <button type="button" className="slidebtn2">ADVENTURE </button>*/}

            <div className='sdp'>

       <p className='sdp3'> How Hotel Management Ensures a Perfect Guest Experience </p>
</div>    
<p className='sdp4'>10.05.2023</p>
 <p className='sdp5'>When it comes to crafting truly memorable experiences for guests, there's a world of difference between the roles of hospitality service and hotel management.
</p>
  <Link to="/blogdescription" className="slidebtn" style={{ display: 'inline-block', padding: '10px 20px', textDecoration: 'none', color: '#FFF', backgroundColor: '#007bff', border: 'none', borderRadius: '5px' }}>Know More</Link>

          </Carousel.Caption>
        </Carousel.Item>
       
        <Carousel.Item>
        <img className="d-block w-100" src={overlay3Image} alt="First slide" />
          <Carousel.Caption className="text-start">
         {/* <button type="button" className="slidebtn2">ADVENTURE </button>*/}

            <div className='sdp'>

       <p className='sdp3'> The World of Event Management Companies: Turning Dreams into Reality </p>
</div>    
<p className='sdp4'>16.04.2023</p>

                     
 <p className='sdp5'>Hosting unforgettable events is no easy feat in today's fast-paced world.<br></br> Whether it's a high-powered corporate conference...</p>
  <Link to="/theworldofevent" className="slidebtn" style={{ display: 'inline-block', padding: '10px 20px', textDecoration: 'none', color: '#FFF', backgroundColor: '#007bff', border: 'none', borderRadius: '5px' }}>Know More</Link>

          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img className="d-block w-100" src={overlay2Image} alt="First slide" />
          <Carousel.Caption className="text-start">
        {/*  <button type="button" className="slidebtn2">ADVENTURE </button>*/}

            <div className='sdp'>

       <p className='sdp3'> How Thoughtful Touches Can Transform a Guest's Stay </p>
</div>    
<p className='sdp4'>31.03.2023</p>

 <p className='sdp5'>These days, people have a lot of choices when they travel or eat out. So, if <br></br>you want them to pick your place, you need to show them amazing hospitality.
</p>
  <Link to="/gueststay" className="slidebtn" style={{ display: 'inline-block', padding: '10px 20px', textDecoration: 'none', color: '#FFF', backgroundColor: '#007bff', border: 'none', borderRadius: '5px' }}>Know More</Link>

          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className='bgmrg'>
                        <h1 className="Bloghpost12">All Blogs </h1>

         <div className="row row-cols-1 row-cols-lg-3 g-3 rcol" >
  <div className="col">
    <div className="card postbg">
      <img src={Artboard2copyn2Image} className="card-img-top BL1imagepost1" alt="Hollywood Sign on The Hill" />
      <div className='Date1post'>March<br></br>31st</div> 
      <div className="card-body blogbox">
        <p className="BT-blog1">#Travel  </p>
        <h5 className="card-title ES-blog1">How Thoughtful Touches Can Transform a Guest's Stay </h5>
        <p className="card-text LO-blog1">
        These days, people have a lot of choices when they travel or eat out. So, if you want them to pick your place, you need to show That means... 
   </p>
        <Link to="/gueststay"> <h4 className="RM-blog1"> Read More</h4></Link>

      </div>
    </div>
  </div>
  <div className="col">
    <div className="card postbg">
      <img src={Artboard2ncopy3Image} className="card-img-top BL2imagepost2" alt="Palm Springs Road" />
      <div className='Date2post2'>April<br></br>16th </div>

      <div className="card-body blogbox2">
      <p className="BT-blog2">#Events </p>

        <h5 className="card-title ES-blog2">The World of Event Management Companies: Turning Dreams into Reality </h5>
        <p className="card-text LO-blog2">
        Hosting unforgettable events is no easy feat in today's fast-paced world. Whether it's a high-powered corporate conference, a...    </p>
        <Link to="/theworldofevent"> <h4 className="RM-blog2"> Read More</h4></Link>

      </div>
    </div>
  </div>
  <div className="col">
    <div className="card">
      <img src={Artboard2ncopyImage} className="card-img-top BL3imagepost3" alt="Palm Springs Road" />
      <div className='Date3post3'>May <br></br>10th  </div>

      <div className="card-body blogbox3">
      <p className="BT-blog2">#Travel  </p>

        <h5 className="card-title ES-blog3">How Hotel Management Ensures a Perfect Guest Experience </h5>
        <p className="card-text LO-blog3">
        When it comes to crafting truly memorable experiences for guests, there's a world of difference between the roles of hospitality...       </p>
        <Link to="/blogdescription"> <h4 className="RM-blog3"> Read More</h4></Link>

      </div>
    </div>
  </div>
</div>

{/*<div className="row row-cols-1 row-cols-lg-3 g-3 rcol11" >
  <div className="col">
    <div className="card">
      <img src={post11Image} className="card-img-top BL11imagepost1" alt="Hollywood Sign on The Hill" />
      <div className='Date11post'>Jun<br></br>15th</div>
      <div className="card-body blogbox11">
        <p className="BT-blog11">#Events </p>
        <h5 className="card-title ES-blog11">Tips for Planning a Successful Meeting or Conference in</h5>
        <p className="card-text LO-blog11">
        Everyone likes a change of space once in a while and the meetings tend to get more productive when they are not in a regular place.        </p>
        <Link to="/blogdescription">  <h4 className="RM-blog11"> Read More</h4></Link>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card postbg">
      <img src={post12Image} className="card-img-top BL12imagepost2" alt="Palm Springs Road" />
      <div className='Date12post2'>Jun<br></br>14th</div>

      <div className="card-body blogbox12">
      <p className="BT-blog12">#Events </p>

        <h5 className="card-title ES-blog12">The Top Meeting and Conference Venues in Bahrain</h5>
        <p className="card-text LO-blog12">
        Everyone likes a change of space once in a while and the meetings tend to get more productive when they are not in a regular place.        </p>
        <Link to="/blogdescription"> <h4 className="RM-blog12"> Read More</h4></Link>

      </div>
    </div>
  </div>
  <div className="col">
    <div className="card postbg">
      <img src={post13Image} className="card-img-top BL13imagepost3" alt="Palm Springs Road" />
      <div className='Date13post3'>Jun<br></br>14th</div>

      <div className="card-body blogbox13">
      <p className="BT-blog12">#Events </p>

        <h5 className="card-title ES-blog13">The Top Meeting and Conference Venues in Bahrain</h5>
        <p className="card-text LO-blog13">
        Everyone likes a change of space once in a while and the meetings tend to get more productive when they are not in a regular place.        </p>
        <Link to="/blogdescription"> <h4 className="RM-blog13"> Read More</h4></Link>

      </div>
    </div>
  </div>
</div>*/}
</div>

    </>
  );
};

export default BlogCarousel;
