import React, { useState, useEffect } from 'react';
import Popup from './Popup'; // Import the Popup component

const Callback = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      // Update state based on viewport width
      setShowButton(window.innerWidth <= 768); // Adjust the breakpoint as per your design
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize once to set initial state
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to ensure useEffect runs only once

  return (
    <>
    <style>
      {`
    @media (max-width: 767px) {
      #clback button.btn.btn-block.btn-success {
        position: fixed !important;
        top: 88%;
        z-index: 3;
        width: 89%;
        background: linear-gradient(90deg, #E66664 6.7%, #F99B62 47.97%, #EE3357 90.07%), #936648;
        -webkit-backdrop-filter: blur(2.46816px);
        backdrop-filter: blur(2.46816px);
        border-radius: 10px;
        border: none;
        height: 60px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        text-transform: uppercase;
        color: #FFFFFF;
        box-shadow: none !important;
        z-index: 9;
    }
}
      `}
    </style>
    <div>
      <div className="d-grid gap-2" id="clback">
        {showButton && (
          <button
            type="button"
            className="btn btn-block btn-success"
            onClick={handleOpenPopup}
          >
            Request a call back
          </button>
        )}
      </div>
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </div>
    </>
  );
};

export default Callback;
