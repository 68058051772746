import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import test1Image from './images/test1.png';
import test2Image from './images/test2.png';
import test3Image from './images/test3.png';
import test4Image from './images/test4.png';
import tstcolimgImage from './images/tstcolimg.png';

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    swipeToSlide: true, // Enable touch/swipe support
    afterChange: (current) => setCurrentSlide(current),
    speed: 500 
  };


  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="testimonial" id="review">
      <style>{`
      .author1 {
          padding-top: 12px;

      }
        #review .slick-slide{
    height: 238px !important;
    padding-top: 11px;
          }
      #review .slick-list{
        height:78vh !important

      }
     
      @media only screen and (min-width: 428px) and (max-width:428px){

      #review .slick-slider {
        margin-left: 3%!important;
       
    }
  }
  @media only screen and (min-width: 430px) and (max-width: 430px){

    #review .slick-slider {
      margin-left: 3%!important;
     
  }
}


      .MuiAvatar-colorDefault {
    color: #fafafa;
    background-color: #bdbdbd00;
}
            @media only screen and (max-width: 767px) {
            
            #review  .slick-dots li button.active {
              font-size: 0;
              line-height: 0;
              display: block;
              width: 20px;
              height: 20px;
              padding: 5px;
              cursor: pointer;
              color: transparent;
              border: 0;
              outline: none;
              background: green;
              border-radius: 100px;
          }

              .sdbackground {
                height: 540px !important;
                /* margin-left: 0px !important; */
                /* left: 0px !important; */
                margin-left: -50px ;
            }
              #review ul.slick-dots {
                margin-left: 62px !important;
                height: 0px !important;
                margin-top: -23px !important;
                top: -45px !important;
            }
            .author1{
              margin-top: 172px;
            }
            #review .slick-slide {
              max-width: 309px !important;
              width: 291px !important;
              margin-right: 25px !important;
              background: white !important;
              height: 314px !important;
              padding-right: 11px !important;
          }
          .triangle {
            content: '';
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 15px solid rgb(255 255 255);
            position: absolute;
            top: 313px !important;
            margin-left: 39px;
        }
        
            
            }
            #review ul.slick-dots {
              margin-left: 17px !important;
              height: 0px !important;
              margin-top: -47px !important;
          }
         
        .triangle {
          content: '';
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid rgb(255 255 255);
          position: absolute;
          top: 237px;
          margin-left: 39px;
      }
            }
            @media only screen and (max-width: 414px) and (max-height: 896px) {
              .sdbackground {
                height: 427px !important;
                /* margin-left: 0px !important; */
                /* left: 0px !important; */
                margin-left: -8px !important;
                paddin-l: 10px !important;
                padding-left: 3px;
            }
          }
          @media only screen and (min-width: 1440px) and (max-width: 1440px){

          .tsttext{
            font-size: 11.6536px !important;
          }
        }
      `}</style>
      <div className="tstm">
        <div className="row tstrownew">
          <div className="col" style={{ width: '40%' }}>
            <p className="tstcolp">TESTIMONIAL </p>
            <p className="tstcolhead">Our Clients Speak For Us </p>
          </div>
          <div className="col-md-6 tstcolimg" style={{ width: '60%' }}>
            <img src={tstcolimgImage} className="testmainimage" alt="Testimonial Column Image" />
          </div>
        </div>
        <div className="row sdbackground">
          <Slider {...sliderSettings}>
            <Card
             // date="12 September, 2023"
              text="VUI Hospitality provided exceptional arrangements for our Ed-tech seminar in Jaipur on very short notice. Their efficiency and attention to detail ensured a smooth and successful event. Highly recommend their services! "
             //img={test1Image}
              text1="Mr. Sayyam Agarwal"
              text2="Academic Head, Aspire Now global "
            />
            <Card
            //  date="15 October, 2023"
              text="Our corporate offsite to Thailand for 3 nights and 4 days with a group of 180 employees was a phenomenal success, thanks to VUI Hospitality. Our entire team was super excited, and VUI Hospitality managed that excitement perfectly by arranging the trip in the best possible way. From conceptualizing to executing, it was a remarkable experience.   "
            //  img={test2Image} 
              text1= "Mr. Sahil Bagati"
              text2="CTO, Sveltetech Technologies Pvt. Ltd. "
            //  txt="<br/>"
            />
            <Card
            //  date="20 March, 2023"
              text="The Tri-city Investor Summit for IIT startups in Delhi, Bangalore, and Mumbai was superbly organized by VUI Hospitality. Their meticulous planning and seamless execution ensured the event was a resounding success across all three cities. We couldn't have asked for a better partner to handle this important summit. "
             // img={test3Image}
              text1="Mr. Ankit Saxena"
              text2="Assistant Vice President of Business Promotion and Investments, IIT, Kanpur "
            />
            <Card
             // date="15 October, 2023"
              text="They have organized multiple events for us, including a cricket tournament for our employees and theme-based high tea and dinner for our clients. Each event was executed flawlessly, showcasing their expertise and dedication. We look forward to partnering with them for many more events in the future. "
             // img={test4Image}
              text1="Mr. Rajat Khare"
              text2="Director of Operations, CorporatEdge "
            />
             <Card
             // date="25 June, 2023"
              text="VUI Hospitality orchestrated an outstanding product launch for our apparel brand. From the initial concept to the final execution, their attention to detail and professionalism were evident in every aspect. The event was a huge success, leaving a lasting impression on our guests and setting the perfect stage for our new collection. We couldn't have asked for a better partner for this important milestone.  "
             // img={test4Image}
              text1="Ms. Sonia Chawla"
              text2="Founder, Lauren & Ethan "
            />
             <Card
             // date="2 March, 2023"
              text="VUI Hospitality has been our trusted MICE partner, flawlessly executing all our events, from CME Seminars to our Annual Kick-off meetings. Their professionalism, attention to detail and commitment to excellence have consistently exceeded our expectations. We are thankful to the entire VUI Team for their expertise and look forward to continuing our successful partnership.  "
             // img={test4Image}
              text1="Mr. Harender Singh Rana"
              text2="Sr. Admin Associate, Bio-Rad Laboratories (India), Private Limited  "
            />
          </Slider>
          {/* Add the line indicator here */}
         {/* <div style={{ textAlign: 'center', marginTop: '10px' }}>
            {Array.from({ length: 4 }, (_, i) => (
              <div key={i} style={{ display: 'inline-block', width: '20px', height: '4px', margin: '0 5px', background: i === currentSlide ? '#000' : '#ccc', cursor: 'pointer' }} onClick={() => handleIndicatorClick(i)}></div>
            ))}
          </div>*/}
        </div>
      </div>
    </div>
  );
};

const Card = ({ img, date, text, text1, text2,txt }) => {
  

  return (
    <div className="avtn">
      <Avatar src={img} className="avt" style={{ width: '67px', height: '67px' }} />
      <p className="tstdate">{date}</p>
      <p className="tsttext">{text}</p>
      <p className="author2" style={{ display: window.innerWidth > 767 ? 'block' : 'none' }}>{txt}</p>

      <div className="triangle"></div>
      <p className="author1">{text1}</p>
      <p className="author2">{text2}</p>

    </div>
  );
};

export default Testimonial;
