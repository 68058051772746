import React from 'react';
import blogd1 from './images/blogd1.png'; // Import your image
import gp from './images/gp.png';
import gall3 from './images/gall3.png';
import {Link} from 'react-router-dom';
import Callback  from './callback';
import Tabslide2  from './tabslide2';
import TabSlide2 from './tabslide2';
import galevnew from './images/galevnew.png';
import galevnew6 from './images/galevnew6.png';
import galevnew2 from './images/galevnew2.png';
import galevnew3 from './images/galevnew3.png';
import galevnew4 from './images/galevnew4.png';
import galevnew5 from './images/galevnew5.png';

function BootstrapCardsGallery() {
  return (
    
    <section className="py-5 gallery" id="gle">
       <div >
        <Callback/>
        </div>


      <div className='galmain' id="hotelb">
        <h2 className="galh">Explore Our Collection of Events </h2>
         <p className="galp">Discover captivating visuals in our gallery </p> 
        <center>

        <div id="tslid"><TabSlide2/></div>

        <div className="tab mt-4">
          <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
              <Link to ="/gallery" className="nav-link  gltb"  >View all</Link>
            </li>
            <li className="nav-item">
             <Link to="/corporate event" className="nav-link active gltb">CORPORATE EVENTS</Link>
            </li>
            <li className="nav-item">
            <Link to="/corporate events2" className="nav-link gltb" >EXPERIENTIAL TRAVEL</Link>
            </li>
            <li className="nav-item">
            <Link to="/Brandactivation" className="nav-link gltb"   >CORPORATE GIFTING</Link>
            </li>
           {/* <li className="nav-item">
              <Link to="/hotelbooking" className="nav-link"  >DIGITAL MARKETING</Link>
            </li>*/}
          </ul>
        </div>
</center>
        <div className="row mt-4 gx-4"  id="mgleft">
          {/* Card 1 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={galevnew} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>*/}
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={galevnew2} className="card-img-top" alt="Thumbnail" />
               {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>*/}
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={galevnew3} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>*/}
            </div>
          </div>

          {/* Card 4 and other cards go here */}

        </div>
        <div className="row gx-4"  id="mgleft">
          {/* Card 1 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd" >
              <img src={galevnew4} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>*/}
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd">
              <img src={galevnew5} className="card-img-top" alt="Thumbnail" />
               {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>*/}
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd">
              <img src={galevnew6} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>*/}
            </div>
          </div>

          {/* Card 4 and other cards go here */}

        </div>
      {/*  <div className="row gx-4"  id="mgleft">
          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd" >
              <img src={blogd1} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3"id="crd">
              <img src={cpeve3} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 column-gap-20">
            <div className="card my-3" id="crd">
              <img src={gall3} className="card-img-top" alt="Thumbnail" />
              {/*  <div className="card-body galdetail">
              <h3 className="card-title text-secondary gacpeve">CORPORATE EVENTS</h3>
                <p className="card-text">Blog title heading will go here</p>
              </div>
            </div>
          </div>


        </div>*/}

      </div>

    </section>
  );
}

export default BootstrapCardsGallery;
