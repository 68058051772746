import React, { useEffect, useState } from 'react';
import $ from 'jquery'; // Import jQuery here
import iconImage from './images/Icon.png';
import icon2Image from './images/Icon2.png';
import icon3Image from './images/Icon3.png';
import icon4Image from './images/Icon4.png';
import icon5Image from './images/Icon5.png';
import icon6Image from './images/Icon6.png';
import icon7Image from './images/Icon7.png';
import bannerImage from './images/banner.png';
import micImage from './images/mic.png';
import charteredImage from './images/chartered.jpg';
import diningImage from './images/dining.jpg';
import hotelImage from './images/hotel.jpg';
import meetingImage from './images/meeting.jpg';
import miceImage from './images/mice.jpg';
import productionImage from './images/production.jpg';
import retreatImage from './images/retreat.jpg';

const Homeslider = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0); // Define totalSlides here
  useEffect(() => {
    function updateIndicators() {
      var windowWidth = $(window).width();
      if (windowWidth < 768) {
        $('#carouselIndicators button').hide(); // Hide all buttons
        var activeIndex = $('#carouselExampleIndicators .active').index();
        $('#carouselIndicators button').eq(activeIndex).show(); // Show active button
        $('#carouselIndicators button').eq(activeIndex + 1).show(); // Show next button
      } else {
        $('#carouselIndicators button').show(); // Show all buttons
      }
    }
  
    updateIndicators();
    setTotalSlides($('.carousel-indicators button').length);
  
    $('.carousel-control-prev').click(function () {
      if (activeSlideIndex === 0) {
        // If the first slide is active, stop at the first slide
        return;
      }
      // Move to the previous slide
      setActiveSlideIndex(activeSlideIndex - 1);
      // Enable the next button
      $('.carousel-control-next').prop('disabled', false);
      // Remove active class from all indicators
      $('#carouselIndicators button').removeClass('active');
      // Add active class to the indicator corresponding to the updated active slide index
      $('#carouselIndicators button').eq(activeSlideIndex - 1).addClass('active');
    });
  
    $('.carousel-control-next').click(function () {
      if (activeSlideIndex === totalSlides - 1) {
        // If the last slide is active, stop at the last slide
        return;
      }
      // Move to the next slide
      setActiveSlideIndex(activeSlideIndex + 1);
      // Enable the previous button
      $('.carousel-control-prev').prop('disabled', false);
      // Remove active class from all indicators
      $('#carouselIndicators button').removeClass('active');
      // Add active class to the indicator corresponding to the updated active slide index
      $('#carouselIndicators button').eq(activeSlideIndex + 1).addClass('active');
    });
  }, [activeSlideIndex, totalSlides]);
  

  
  
  return (
    <>
      <style>
        {`
@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .carousel-indicators .active img{
padding-top:7% !important;
          }
        }


        #sage .carousel-indicators{
          z-index:10;
        }
        #sage .thumbnail-btn img {
          margin-top: -20px !important;
          margin-left: -5px;
      }
        @media only screen and (max-width: 360px) and (max-height: 800px) {
          #hsld {
            height: 20px;
            margin-top: 87px;
            position: relative;
            top: 40px;
        }
          #sage .carousel-inner {
            position: relative !important;
            top: -181px !important;
            width: 100% !important;
            overflow: hidden !important;
            padding-bottom: 60px;        }


             #sage .carousel-indicators {
        position: relative;
        bottom: 0px !important;
        top: 632px !important;
        margin-bottom: 244px;
        margin-top: -16px;
        padding-bottom: 29px;
    }
    #sage  .carousel-control-next-icon {
      height: 20px !important;
        top: 123px !important;
        background-size: 29px !important;
        margin-left: -63px !important;
        margin-top: 21px;
    }
    #sage  .carousel-control-prev-icon {
      height: 20px !important;
      top: 119px !important;
      background-size: 29px !important;
      margin-left: 12px;
      margin-top: 21px;
    }
    #dtid {
      display: inline-flex;
      color: #B4B4B4 !important;
      z-index: 999 !important;
      /* margin-top: 0px; */
      position: relative;
      top: -562px !important;
      left: 38%;
  }
      }
        @media only screen and (max-width: 414px) and (max-height: 896px) {
          span#nst1 {
            background-size: 20px !important;
            top: 81px !important;
            left: -39px !important;
        }
        span#nst {
          background-size: 20px !important;
          top: 81px !important;
          left: 50px !important;
          margin-left:0px !important;
      }
      div#carouselIndicators{
            position: relative;
            bottom: 0px !important;
            top: 533px !important;
            margin-top: 130px !important;
        }
       
         .dt{
          display: inline-flex;
          color: #B4B4B4 !important;
          z-index: 999 !important;
          /* margin-top: 0px; */
          position: relative;
          top: -611px !important;
           left: 38%;
      }
    }
        @media (min-width: 768px) {
       
          .dt {
            display: none; /* Hide the container on desktop */
          }
        }
        #micimg{
          object-fit: cover;
    object-position: 0px 72px;
        }
        div#sage{
          margin-top:-230px
        }
        .homesdpera{
          position: relative !important;
          top: -1px !important;
      
        }
        .carousel-indicators .active .hmslide {
          margin-left:-7px;
      }
      .carousel-indicators .active .hmslide2 {
        margin-left:-5px;
    }
        .carousel-indicators div {
          padding-left: 10%;
          padding-bottom: 0px;
          margin-top: 14%;
      }
        .carousel-item img {
          height:830px !important;
          
        }
       
        .carousel-indicators button {
          border-radius:0px !important;
          color:#fff0;
          background: rgba(255, 255, 255, 0) !important;
        }
        *{
          margin:0px;
          padding:0px;
        }
        #sage .carousel-caption.d-none.d-md-block{
          position: absolute !important;
            left: 4% !important;
            margin-top: -37% !important;
            padding-top: 5%!important;
        }
        #sage .carousel-inner {
          position: relative !important;
          top: -59px !important;
          width: 100% !important;
          overflow: hidden !important;
      }
        #sage .carousel{
          // height:100vh;
          overflow: hidden;
          margin-top: -9px;
          position: relative;
          top: 0px;
        }
        #sage .carousel-indicators {
         
          position: relative;
          bottom: 0px !important;
          top: 763px !important;
      }
        
        #sage .carousel-indicators .active {
          background: rgba(255, 255, 255, 1) !important;
          backdrop-filter: blur(50px) !important;
        }

        #sage .thumbnail-btn {
            width: 100%;
            height:116px !important;
            border-radius: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            // border-top: 3px solid #fff; 
        }

        
        #sage .thumbnail-btn img {
          margin-top: -20px !important;
        }
        
        #sage .thumbnail-btn p {
            text-indent: 0;
            color: #ffffff;
            font-weight: 600;
            margin-top: -11px;
            margin-bottom: 0px;
            font-size: 12px;
        }
        #sage .carousel-control-next{
          position: relative !important;
          top: -684px !important;
          bottom: 0px;
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15%;
          padding: 0;
          color: #fff;
          text-align: center;
          background: 0 0;
          border: 0;
          opacity: .5;
          transition: opacity .15s ease;
        }
        #sage .carousel-control-prev{
          position: relative !important;
          top: -631px !important;
          bottom: 0px;
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15%;
          padding: 0;
          color: #fff;
          text-align: center;
          background: 0 0;
          border: 0;
          opacity: .5;
          transition: opacity .15s ease;
        }
        #sage .carousel-indicators .active div {
          
          padding-bottom: 3%;
          height: 120px;
          padding-left: 7%;
          font-weight: 700 !important;
          background: rgba(255, 255, 255, 0) !important;
      }

      
        #sage .carousel-indicators [data-bs-target] {
            width: 100%;
            height: 17vh;
            margin-top: 0px;
            background: rgba(255, 255, 255, 0) !important;
            
            border-radius: 0px;
        }

        #sage .carousel-indicators {
            border-top: 1px solid #ffffff;
        }

        #sage .test img {
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
        }
        #sage .carousel-indicators .active div {
          padding-bottom: 3%;
          height: 44px;
          margin-top: -10px;
          padding-left: 7%;
          font-weight: 700 !important;
          opacity: 1;
          background: rgba(255, 255, 255, 0) !important;
        
            // border-top: 3px solid #ffffff;
      }
      #sage .carousel-indicators .active div {
        background:  rgba(0, 0, 0, 0); /* Change background color to red */
        padding-bottom: 3%;
        height: 44px;
        margin-top: -10px;
        padding-left: 7%;
        font-weight: 700 !important;
        opacity: 1;
        
      }
        sage .carousel-indicators [data-bs-target] {
          width: 100%;
          height: 17vh;
          background: rgba(255, 255, 255, 1) !important;
          backdrop-filter: blur(10px) !important;        }

          .carousel-indicators .active img {
            padding-top: 26%;
            padding-bottom: 10%;
            width: 300px !important;
            max-width: 27px;
            margin-left: -3px;
        }


        @media (max-width: 767px) {
          h1#slide1.active {
            color: white;
        }
        h1#slide2.active {
          color: white;
      }
      h1#slide3.active {
        color: white;
    }
    h1#slide4.active {
      color: white;
  }
  h1#slide6.active {
    color: white;
}
h1#slide5.active {
  color: white;
}
h1#slide1.active {
  color: white;
}
h1#slide7.active {
color: white;
}
          .carousel-indicators .active img {
            padding-top: 7% !important;
            padding-bottom: 10%;
            width: 300px !important;
            max-width: 27px;
            margin-left: -3px;
        }
       
          .dt {
            display: inline-flex;
            color: #B4B4B4 !important;
            z-index: 999 !important;
            /* margin-top: 0px; */
            position: relative;
            top: -552px;
            left: 38%;
        }
          #hsld{
          height: 20px;
    margin-top: 87px;}

          #sage .carousel-item .w-100 {
            width: 100% !important;
            height: 103vh !important;
        }
          #sage .thumbnail-btn img {
            margin-top: -20px !important;
            margin-left: -5px;
        }
          span#nst {
          
            background-size: 20px !important;
            top: 136px;
            left: 50px !important;
            margin-left: 48px;
        }
        span#nst1 {
          background-size: 20px !important;
          top:136px ;
          left: -39px !important;
      }
          #sage .carousel-indicators {
            position: relative;
            bottom: 0px !important;
        top: 69vh !important;
        margin-top: 130px !important;
        }
          #sage .carousel-inner {
            position: relative !important;
            top: 20px !important;
            width: 100% !important;
            overflow: hidden !important;
        }
          .carousel-indicators .active p.vui2 {
            margin-top: -2px !important;
          }
         
          .carousel-indicators .active p.vui {
            padding-bottom: 3%;
    height: 44px;
  #sage .carousel-indicators .active div {  margin-top: -31px;
    padding-left: 7%;
    font-weight: 700 !important;
    opacity: 1;
    background: #ffffff00 !important;
    backdrop-filter: none !important;
    -webkit-backdrop-filter: none !important;
    margin-top: 23px !important;
          }
          h1#slide1.active {
            color: white;
        }
        h1#slide2.active {
          color: white;
      }
      h1#slide3.active {
        color: white;
    }
    h1#slide4.active {
      color: white;
  }
  h1#slide5.active {
    color: white;
}
h1#slide6.active {
  color: white;
}
h1#slide7.active {
  color: white;
}
          .dt{
          display: inline-flex;
          color: #B4B4B4 !important;
          z-index: 999 !important;
          /* margin-top: 0px; */
          position: relative;
          top: -611px;
           left: 38%;
      }
          div#sage{
            margin-top:0px
          }
          #micimg{
            object-position: -465px 0px;
          }
            #carouselIndicators button {
                width: 50%;
                height: 10vh;
                padding-bottom: 17px;
            }
            #sage .carousel-control-next, .carousel-control-prev{
              position: absolute;
              top: 0;
              bottom: 0px;
              z-index: 10;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 15%;
              padding: 0;
              color: #fff;
              text-align: center;
              background: 0 0;
              border: 0;
              opacity: .5;
              transition: opacity .15s ease;
              z-index:19px !important
          }
          #sage .carousel-indicators {
            position: relative;
            bottom: 0px !important;
            top: 536px !important;
        }
        sage .test img {
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
      }
        
            #sage .thumbnail-btn p {
              text-indent: 0;
    color: #ffffff;
    margin-top: 18px;

          
            }
            #sage .test {
                position: relative;
                text-align: center;
                color: #ffffffff;
                top: 60px;
            }
            #sage .carousel-item .w-100 {
                width: 100% !important;
                height: 101vh !important;
                object-fit: cover;
            }
            #sage .carousel-indicators .active div {
              padding-bottom: 3%;
              height: 44px;
              margin-top: -31px;
              padding-left: 7%;
              font-weight: 700 !important;
              opacity: 1;
              background: rgba(255, 255, 255, 0) !important;
          }
          .carousel-indicators .active img {
            padding-top: 0%;
            padding-bottom: 0%;
        }
        .carousel-indicators .active p {
          padding-top: 6px;
          padding-bottom: 6%;
      
      }
      img.hmslide {
        /* position: relative; */
        /* margin-top: -30px !important; */
        position: relative;
        top: -35px !important;
        /* width: 80px !important; */
        height: 27px !important;
        max-width: 50px;
        left: 53px !important;
    }
    .carousel-indicators .active .hmslide {
      margin-left: 5px;
      top: 22px !important;
  
  
  }
  img.hmslide2 {
    position: relative;
    top: -61px !important;
    max-width: 50px;
    height: 27px;
    left: 25px !important;
}
.carousel-indicators .active .hmslide2 {
  margin-left: 1px;
  top: 27px !important;
}
img.hmslide3 {
  position: relative;
  top: -35px !important;
  max-width: 50px;
  height: 27px;
  left: 55px !important;
}
img.hmslide7 {
  position: relative;
  top: -45px !important;
  max-width: 50px;
  height: 27px;
  left: 59px !important;
}
img.hmslide6 {
  position: relative;
  top: -50px !important;
  max-width: 50px;
  height: 27px;
  left: 59px !important;
}
img.hmslide5 {
  position: relative;
  top: -31px !important;
  max-width: 50px;
  height: 27px;
  left: 59px !important;
}
img.hmslide4 {
  position: relative;
  top: -31px !important;
  max-width: 50px;
  height: 27px;
  left: 59px !important;
}
.carousel-indicators .active .hmslide3 {
  margin-left: 1px;
    top: 21px !important;

}
.carousel-indicators .active p.hmslidep3 {
  position: relative;
  top: -10px !important;
}
.carousel-indicators .active .hmslide7 {
  margin-left: 1px;
  top: 25px !important;

}
.carousel-indicators .active .hmslide6 {
  margin-left: 1px;
  top: 20px !important;

}
.carousel-indicators .active .hmslide5 {
  margin-left: 1px;
    top: 25px !important;

}
.carousel-indicators .active .hmslide4 {
  margin-left: 1px;
  top: 43px !important;
}
p.hmslidep4 {
  position: relative;
  top: -20px;
}

p.hmslidep3 {
  position: relative;
  top: 6px;
}

p.hmslidep5 {
  position: relative;
  top: -20px;
}
p.hmslidep6 {
  position: relative;
  top: -20px;
}
p.hmslidep7 {
  position: relative;
  top: -20px;
}

#sage .thumbnail-btn {
  width: 100%;
  height: 76px !important;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.carousel-indicators [data-bs-target] {
  width: 138px !important;
  opacity: 1 !important;
}
.carousel-control-prev-icon {
  position: relative;
  left: -32px;
  width: 17px !important;
  height: 17px !important;
  top: 15px;
  background-image:white !important;
}
.carousel-control-next-icon {
  position: relative;
  left: -22px;
  width: 17px !important;
  height: 17px !important;
  top: 47px;
  left: 34px !important;
  background-image:white !important;

}
.dining{
  position: relative;
  top: 1px;

}
.carousel-indicators .active .dining{
  position: relative;
  top: 2px !important;}
   }

   @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance: none) {
   .carousel-indicators .active img {
    padding-top: 11px;
    padding-bottom: 10%;
    width: 300px !important;
    max-width: 27px;
    margin-left: -3px;
    }
    .slick-dots li button:before {
      content: "."!important;
      font-family: slick!important;
      font-size: 50px!important;
      height: 20px!important;
      left: -64px!important;
      line-height: 20px!important;
      position: relative!important;
      top: 66px!important;
      width: 0!important;
      }
      .slick-dots li, .slick-dots li button {
        cursor: pointer;
        height: 20px;
        width: 7px;
        }
      }}
        @media not all and (min-resolution: .001dpcm) { 
          @media (min-width: 1280px) and (max-width: 1440px) and (min-height: 800px) and (max-height: 900px) {
              /* Safari-specific styles for 13-inch MacBook */
              .carousel-indicators .active img {
                padding-top: 11px;
                padding-bottom: 10%;
                width: 300px !important;
                max-width: 27px;
                margin-left: -3px;
                }
                .slick-dots li button:before {
                  content: "."!important;
                  font-family: slick!important;
                  font-size: 50px!important;
                  height: 20px!important;
                  left: -64px!important;
                  line-height: 20px!important;
                  position: relative!important;
                  top: 66px!important;
                  width: 0!important;
                  }
                  .slick-dots li, .slick-dots li button {
                    cursor: pointer;
                    height: 20px;
                    width: 7px;
                    }
          }
      }
      
        
  }
}
        `}
      </style>

      <div  id="sage">
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-interval="false">
          <div id="hsld">
          <div className="carousel-indicators" id="carouselIndicators">
          <button type="button" id="slide1" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className={`thumbnail-btn ${activeSlideIndex === 0 ? 'active' : ''}`} aria-label="Slide 1" onClick={() => setActiveSlideIndex(0)}>
            <div className="container">
              <div className="test"><img src={iconImage} alt="a1" className='hmslide'/></div>
              <p className='homesdpera'>All HOTELS <br></br>AND RESORTS</p>
            </div>
          </button>

          <button type="button" id="slide2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className={`thumbnail-btn ${activeSlideIndex === 1 ? 'active' : ''}`} aria-label="Slide 2" onClick={() => setActiveSlideIndex(1)}>
            <div className="container">
              <div className="test"><img src={icon2Image} alt="a2" className='hmslide3'/></div>
              <p className='hmslidep3'>MICE <br></br>SERVICES</p>
            </div>
          </button>

          <button type="button" id="slide3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" className={`thumbnail-btn ${activeSlideIndex === 2 ? 'active' : ''}`} aria-label="Slide 3" onClick={() => setActiveSlideIndex(2)}>
            <div className="container">
              <div className="test"><img src={icon3Image} alt="a3" className='hmslide5'/></div>
              <p className='vui'>PRIVATE<br></br> RETREATS</p>
            </div>
          </button>

          <button type="button" id="slide4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" className={`thumbnail-btn ${activeSlideIndex === 3 ? 'active' : ''}`} aria-label="Slide 4" onClick={() => setActiveSlideIndex(3)}>
            <div className="container">
              <div className="test"><img src={icon4Image} alt="a1" className='hmslide6'/></div>
              <p className='vui2'>PRODUCTION <br></br> SERVICES</p>
            </div>
          </button>

          <button type="button" id="slide5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" className={`thumbnail-btn ${activeSlideIndex === 4 ? 'active' : ''}`} aria-label="Slide 5" onClick={() => setActiveSlideIndex(4)}>
            <div className="container">
              <div className="test"><img src={icon5Image} alt="a2" className='hmslide'/></div>
              <p>MEETINGS AND <br></br>EVENTS</p>
            </div>
          </button>

          <button type="button" id="slide6" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" className={`thumbnail-btn ${activeSlideIndex === 5 ? 'active' : ''}`} aria-label="Slide 6" onClick={() => setActiveSlideIndex(5)}>
            <div className="container">
              <div className="test"><img src={icon6Image} alt="a3" className='hmslide7'/></div>
              <p className='dining'>DINING</p>
            </div>
          </button>

          <button type="button" id="slide7" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" className={`thumbnail-btn ${activeSlideIndex === 6 ? 'active' : ''}`} aria-label="Slide 7" onClick={() => setActiveSlideIndex(6)}>
            <div className="container">
              <div className="test"><img src={icon7Image} alt="a3" className='hmslide4'/></div>
              <p>CHARTERED <br></br> SERVICES</p>
            </div>
          </button>
        
          {/* Add similar button elements for other slides */}
        </div>
       </div>



        <div className="carousel-inner">
          <div className="carousel-item active">
          <img src={hotelImage} className="w-100 img2" alt="1"/>
          {/*  <p className="mice11 " >your</p>
              <h5 className="servic11" > event </h5>
              <p className="partner11" >partner</p>*/}
              <h5 className="servic11" > Luxurious accommodations in <br></br>sought-after destinations worldwide</h5>
            <div className="carousel-caption d-none d-md-block"></div>
          </div>

          <div className="carousel-item">
            <img src={miceImage} className="w-100 " id="micimg" alt="2"/>
          {/*  <p className='mice'>  mice </p>
          <p className='servic'>  services </p>
          <p className='partner'>  partner </p>*/}

          <h5 className="servic11" > Igniting success in MICE events<br></br> with strategic consulting and execution</h5>

            <div className="carousel-caption d-none d-md-block">
            </div>
         </div>


         <div className="carousel-item">
            <img src={retreatImage} className="w-100" alt="3"/>
           {/* <p className="mice11" >your</p>
            <h5 className="servic11" > event </h5>
            <p className="partner11" >partner</p>*/}
            <h5 className="servic11" >  Curated private retreats tailored <br></br>to your specific preferences</h5>

            <div className="carousel-caption d-none d-md-block">
            </div>
        </div>
        <div className="carousel-item">
            <img src={productionImage} className="w-100" id="micimg" alt="4"/>
        {/*    <p className='mice'>  mice </p>
          <p className='servic'>  services </p>
          <p className='partner'>  partner </p>*/}
          <h5 className="servic11" > Creating unforgettable experiences <br></br>with immersive audio-visual solutions</h5>

            <div className="carousel-caption d-none d-md-block">
            </div>
        </div>
        <div className="carousel-item">
            <img src={meetingImage} className="w-100" alt="5"/>
           {/* <p className="mice11" >your</p>
            <h5 className="servic11" > event </h5>
            <p className="partner11" >partner</p>*/}
            <h5 className="servic11" >   Creating memorable meetings <br></br>and events that exceed expectations</h5>

            <div className="carousel-caption d-none d-md-block">
            </div>
        </div>
        <div className="carousel-item">
            <img src={diningImage} className="w-100" id="micimg" alt="6"/>
           {/* <p className='mice'>  mice </p>
          <p className='servic'>  services </p>
          <p className='partner'>  partner </p>*/}
          <h5 className="servic11" >Unique and unforgettable culinary<br></br> experiences for every occasion</h5>

            <div className="carousel-caption d-none d-md-block">
            </div>
        </div>
        <div className="carousel-item">
            <img src={charteredImage} className="w-100" alt="7"/>
           {/* <p className="mice11" >your</p>
            <h5 className="servic11" > event </h5>
            <p className="partner11" >partner</p>*/}
            <h5 className="servic11" > Customized travel solutions for <br></br>groups and individuals</h5>
            <div className="carousel-caption d-none d-md-block">
            </div>
        </div>   


     {/* Add similar carousel-item elements for other slides */}
        </div>
        <button
  className="carousel-control-prev"
  type="button"
  data-bs-target="#carouselExampleIndicators"
  data-bs-slide="prev"
  disabled={activeSlideIndex === 0} // Disable if the first slide is active
>
  <span className="carousel-control-prev-icon" aria-hidden="true" id="nst1"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button
  className="carousel-control-next"
  type="button"
  data-bs-target="#carouselExampleIndicators"
  data-bs-slide="next"
  disabled={activeSlideIndex === totalSlides - 1} // Disable if the last slide is active
>
  <span className="carousel-control-next-icon" aria-hidden="true" id="nst"></span>
  <span className="visually-hidden">Next</span>
</button>

      </div>
      <div className='dt' id="dtid">
        <h1 id="slide1" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className={`thumbnail-btn ${activeSlideIndex === 0 ? 'active' : ''}`} aria-label="Slide 1" onClick={() => setActiveSlideIndex(0)} style={{fontSize:'53px'}}>.</h1>
        <h1 id="slide2" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className={`thumbnail-btn ${activeSlideIndex === 1 ? 'active' : ''}`} aria-label="Slide 2" onClick={() => setActiveSlideIndex(1)} style={{fontSize:'53px'}}>.</h1>
        <h1 id="slide3" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" className={`thumbnail-btn ${activeSlideIndex === 2 ? 'active' : ''}`} aria-label="Slide 3" onClick={() => setActiveSlideIndex(2)} style={{fontSize:'53px'}}>.</h1>
        <h1 id="slide4" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" className={`thumbnail-btn ${activeSlideIndex === 3 ? 'active' : ''}`} aria-label="Slide 4" onClick={() => setActiveSlideIndex(3)} style={{fontSize:'53px'}}>.</h1>
        <h1 id="slide5" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" className={`thumbnail-btn ${activeSlideIndex === 4 ? 'active' : ''}`} aria-label="Slide 5" onClick={() => setActiveSlideIndex(4)} style={{fontSize:'53px'}}>.</h1>

        <h1 id="slide6" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" className={`thumbnail-btn ${activeSlideIndex === 5 ? 'active' : ''}`} aria-label="Slide 6" onClick={() => setActiveSlideIndex(5)} style={{fontSize:'53px'}}>.</h1>
        <h1 id="slide7" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" className={`thumbnail-btn ${activeSlideIndex === 6 ? 'active' : ''}`} aria-label="Slide 7" onClick={() => setActiveSlideIndex(6)} style={{fontSize:'53px'}}>.</h1>
        </div>
      </div>
         
        
    </>
  );
};

export default Homeslider;