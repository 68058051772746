import React, { useState, useRef } from "react";
import "./hotelbookingforcorporates.css";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

import B1Image from "./images/B1.png";
import B2Image from "./images/B2.png";
import B3Image from "./images/B3.png";
import B4Image from "./images/B4.png";
import B5Image from "./images/B5.png";
import B6Image from "./images/B6.png";

import L1Image from "./images/L1.png";
import L2Image from "./images/L2.png";
import L3Image from "./images/L3.png";
import L4Image from "./images/L4.png";
import L5Image from "./images/L5.png";
import L6Image from "./images/L6.png";

import V1Image from "./images/V1.png";
import V2Image from "./images/V2.png";
import V3Image from "./images/V3.png";
import V4Image from "./images/V4.png";

import Gallery1Image from "./images/Gallery1.png";
import Gallery2Image from "./images/Gallery2.png";
import Gallery3Image from "./images/Gallery3.png";
import Gallery4Image from "./images/Gallery4.png";
import Gallery5Image from "./images/Gallery5.png";
import flexibilityImage from "./images/flexibility.png";
import MeasurableResultsImage from "./images/MeasurableResults.png";
import GlobalReachImage from "./images/GlobalReach.png";
import costeffectivenessImage from "./images/costeffectiveness.png";
import AudienceTargetingImage from "./images/AudienceTargeting.png";
import EnhancedEngagementImage from "./images/EnhancedEngagement.png";

import ProcessImage from "./images/Process.png";
import ProcessVerticalImage from "./images/ProcessVertical.png";

import Hbac1Image from "./images/Hbac1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Slide from "./slide";
import BlogCom  from './post';
import Callback  from './callback';
import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import PhoneInput from 'react-phone-input-2';
import SMMImage from "./images/SMM.jpg";
import SEOImage from "./images/SEO.jpg";
import UIUXImage from "./images/UI UX.jpg";
import CreativeMarketingImage from "./images/Creative Marketing.jpg";
import ContentMarketingImage from "./images/Content Marketing.jpg";
import TechSolutionsImage from "./images/Tech Solutions.jpg";
import ourwork1Image from './images/ourwork1.png'
import ourwork2Image from './images/ourwork2.png'
import ourwork3Image from './images/ourwork3.png'
import ourwork4Image from './images/ourwork4.png'
import ourwork5Image from './images/ourwork5.png'
import ourwork6Image from './images/ourwork6.png'

const Services = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // After the "API call" has finished, stop showing the loading icon
    setLoading(false);

    try {
      // Assuming sendForm returns a promise
      const result = await emailjs.sendForm(
        "service_mhkkkwa",
        "template_megbwh2",
        form.current,
        {
          publicKey: "20SA2U48taVusKc5H",
        }
      );
      console.log("Email successfully sent!", result.text);
      // Set form as submitted if email was sent successfully
      setSubmitted(true);

      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      // Reset the form here if needed
      form.current.reset();
    } catch (error) {
      console.error("Failed to send email:", error);
    }

    setLoading(false); // End loading
  };
  const filterServices = (category) => {
    switch (category) {
      case "Corporate Events":
        return [
          "Award Ceremonies",
          "Team Building Activities",
          "Exhibitions",
          "Product Launch",
          "Conferences & Seminars",
          "Corporate Offsites",
        ];
      case "Experiential Travel":
        return [
          "Cultural Experience",
          "Local Cuisine",
          "Local Activities",
          "Customised Itinerary",
          "Flight Tickets",
          "Hotel Booking",
        ];
      case "Corporate Gifting":
        return [
          "Budget Gifting",
          "Customised Gifting",
          "Occasional Gifting",
          "Festival Gifting",
          "Health and Safety Gifting",
          "Virtual Gifting",
        ];
      case "Digital Marketing":
        return [
          "SMM",
          "SEO",
          "UI/UX Design",
          "Content Marketing",
          "Tech Solutions",
          "Creative Marketing",
        ];
      default:
        return [];
    }
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm("service_mhkkkwa", "template_megbwh2", form.current, {
  //       publicKey: "20SA2U48taVusKc5H",
  //     })
  //     .then(
  //       () => {
  //         console.log("SUCCESS!");
  //         setSubmitted(true);
  //         // Reset form fields after submission
  //         form.current.reset();
  //       },
  //       (error) => {
  //         console.log("FAILED...", error.text);
  //       }
  //     );
  // };
  return (
    <div>
       <div>
        <Callback/>
        </div>
      <section className="ftco-section-img-bg-hero ftcbannew" id="SftcbannewH">
        <div className="mrg">
          <div className="row">
            <div className="col contcol">
              <div className="wrapper">
                <div className="row no-gutters justify-content-between">
                  <div className="col-lg-6 d-flex align-items-stretch gtbox">
                    <div className="info">
                      <h3 className=" conth1" id="conth1">
                        DIGITAL
                      </h3>
                      <h3 className=" conth2H" id="conth2">
                        MARKETING
                      </h3>
                      <h3 className=" conth3" id="conth3">
                        SERVICES
                      </h3>
                    </div>
                  </div>
                  <div className="col cont" id="col-cont">
                    <div className="contact-wrap getcont">
                      <h3 className="mb-4 contform">
                        Create Your Requirement{" "}
                      </h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4 contformp">
                        Get in touch with us for more information on any of the
                        products or services we offer{" "}
                      </div>
                      <form ref={form} onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="inputAddress" className="fname">
                            Full Name*{" "}
                          </label>
                          <input
                            type="text"
                            name="fname"
                            className="form-control"
                            id="inputname"
                            placeholder="Your Full Name "
                            required
                            style={{ width: "97%" }}
                          />
                        </div>
                        <div className="form-row" id="phn1">
                        <div className="form-group col-md-6 contactcontfeild">
                          <label htmlFor="phoneNumber" className="fphone" >
                            Phone Number*
                          </label>
                          <div className="input-group contactfrmph">
                          <PhoneInput
                            country={'in'}
                            inputProps={{
                              name: 'fphone',
                              required: true,
                              className: 'form-control contactfc',
                              id: 'phoneNumber',
                              placeholder: 'Enter Your Phone Number',
                            }}
                            disableCountryCode={false} // Show country code
                          />
                            <input type="tel" name='fphone' className="form-control contactfc" id="phoneNumber" placeholder="9999****** " required style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: '0.88px solid' }} />
                          </div>
                        </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputEmail4" className="femail">
                              Your Email ID
                            </label>
                            <input
                              type="email"
                              name="femail"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Enter Your Email Id "
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fcategary">
                              Category’s{" "}
                            </label>
                            <select name='fcategary' id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
  <option value="" disabled={selectedCategory !== ''} style={{color:'red'}} id="sdg">Select Your Category</option>
  <option value="Corporate Events">Corporate Events</option>
  <option value="Experiential Travel">Experiential Travel</option>
  <option value="Corporate Gifting">Corporate Gifting</option>
  <option value="Digital Marketing">Digital Marketing</option>
</select>

                          </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              name="fservices"
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              {filterServices(selectedCategory).map(
                                (service, index) => (
                                  <option key={index} name="fservices">
                                    {service}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          {/* <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              <option>...</option>
                            </select>
                          </div> */}
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="invalidCheck2"
                                required
                              />
                              <label
                                className="form-check-label frcheckbox"
                                htmlFor="invalidCheck2"
                              >
                                By Clicking The Button Below, You Agree To Our
                                Terms Of Service And Acknowledge Our Global
                                Privacy Policy.{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn sub"
                          disabled={loading}
                        >
                          SUBMIT{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </form>
                      {submitted && (
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginTop: "60px" }}
                        >
                          Your Form is submitted Successfully.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Section 1 started*/}
      <section className="mainServ" id="mainServ">
        <h3 className="Serv" id="Serv">
          Services
        </h3>
        <h1 className="ServHead" id="ServHead">
          Digital Marketing
        </h1>
        <p className="ServPara" id="ServPara">
          Digital marketing is the exciting playground where the digital aspects
          of our world, including websites, social media, search engines, and
          email, become potent canvases to highlight your products or services.
          It's a universe governed by tailored strategies—be it content that
          grips, social media trends that engage, or search engine optimisation
          that ensures visibility.
        </p>
        {/*main div for black section*/}
        <div className="DivSerMain" id="DivSerMain">
          {/*>>>>>>>>>>Sec1<<<<<<<<<<<*/}
          <div className="CODivsec1" id="CODivsec1">
            <img className="COBI1" id="COBI1" src={SMMImage} alt="" />
            <div id="COorangeheading1">
              <img className="L1" id="L1" src={L1Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac1" id="Hbac1" src={Hbac1Image} alt="" />
              <h4 className="PBhead-1" id="PBhead-1">
                SMM
              </h4>
              <p className="DivPara-1" id="DivPara-1">
                Utilising social media platforms to promote products or
                services, engage with audiences, and drive website traffic.
              </p>
              <Link to="/contact">    <h1 className="DivInq-1" id="DivInq-1">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec2<<<<<<<<<<<*/}
          <div className="CODivsec2" id="CODivsec2">
            <img className="COBI2" id="COBI2" src={SEOImage} alt="" />
            <div id="COorangeheading2">
              <img className="L2" id="L2" src={L2Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac2" id="Hbac2" src={Hbac1Image} alt="" />
              <h4 className="PBhead-2" id="PBhead-2">
                SEO
              </h4>
              <p className="DivPara-2" id="DivPara-2">
                Optimising website content and structure to improve visibility
                in search engine results pages and attract organic traffic.
              </p>
              <Link to="/contact">  <h1 className="DivInq-2" id="DivInq-2">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec3<<<<<<<<<<<*/}
          <div className="CODivsec3" id="CODivsec3">
            <img className="COBI3" id="COBI3" src={UIUXImage} alt="" />
            <div id="COorangeheading3">
              <img className="L3" id="L3" src={L3Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac3" id="Hbac3" src={Hbac1Image} alt="" />
              <h4 className="PBhead-3" id="PBhead-3">
                UI/UX Design
              </h4>
              <p className="DivPara-3" id="DivPara-3">
                Designing digital interfaces to enhance user satisfaction by
                focusing on usability, accessibility, and visual appeal.
              </p>
              <Link to="/contact">  <h1 className="DivInq-3" id="DivInq-3">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec4<<<<<<<<<<<*/}
          <div className="CODivsec4" id="CODivsec4">
            <img className="COBI4" id="COBI4" src={ContentMarketingImage} alt="" />
            <div id="COorangeheading4">
              <img className="L4" id="L4" src={L4Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac4" id="Hbac4" src={Hbac1Image} alt="" />
              <h4 className="PBhead-4" id="PBhead-4">
                Content Marketing
              </h4>
              <p className="DivPara-4" id="DivPara-4">
                Creating and distributing valuable, relevant content to attract
                and retain a target audience, ultimately driving profitable
                customer action.
              </p>
              <Link to="/contact">   <h1 className="DivInq-4" id="DivInq-4">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec5<<<<<<<<<<<*/}
          <div className="CODivsec5" id="CODivsec5">
            <img className="COBI5" id="COBI5" src={CreativeMarketingImage} alt="" />
            <div id="COorangeheading5">
              <img className="L5" id="L5" src={L5Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac5" id="Hbac5" src={Hbac1Image} alt="" />
              <h4 className="PBhead-5" id="PBhead-5">
                Creative Marketing
              </h4>
              <p className="DivPara-5" id="DivPara-5">
                Developing innovative and impactful advertising campaigns, often
                incorporating unconventional strategies to capture attention and
                evoke emotions.
              </p>
              <Link to="/contact">   <h1 className="DivInq-5" id="DivInq-5">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec6<<<<<<<<<<<*/}
          <div className="CODivsec6" id="CODivsec6">
            <img className="COBI6" id="COBI6" src={TechSolutionsImage} alt="" />
            <div id="COorangeheading6">
              <img className="L6" id="L6" src={L6Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac6" id="Hbac6" src={Hbac1Image} alt="" />
              <h4 className="PBhead-6" id="PBhead-6">
                Tech Solutions
              </h4>
              <p className="DivPara-6" id="DivPara-6">
                Providing technological tools and services to address digital
                marketing needs, including analytics platforms, automation
                software, and customer relationship management systems.
              </p>
              <Link to="/contact">   <h1 className="DivInq-6" id="DivInq-6">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
        </div>
      </section>
      {/*>>>>>>>>>>New Section 2 Starte<<<<<<<<<<<<< */}
      <section className="NewSection2" id="spear">
        <h3 className="COWhattopHead1" id="COWhattopHead1">
          WHAT WE PROMISE{" "}
        </h3>
        <h1 className="COWhattopHead2" id="COWhattopHead2">
          Our Digital Marketing USP
        </h1>
        <p className="WhatPara3" id="WhatPara3">
          Transformative corporate events tailored to inspire, connect, and
          energise teams with expertly crafted experiences that exceed
          expectations and foster growth.
          <span className="WhatButton1" id="WhatButton1">
            <Link to="/contact" preventScrollReset={true}>
              <button className="COENQUIRENOW">ENQUIRE NOW</button>
            </Link>
          </span>
        </p>
        {/*>>>>>>>>>>>>>>>>Particluar Section<<<<<<<<<<<<<< */}
        <div className="COmain1" id="COmain1">
          <img className="V1" id="V1" src={GlobalReachImage} alt="" />
          <h2 className="COParHead1" id="COParHead1">
            Global Reach
          </h2>
          <p className="COPartiPara1" id="COPartiPara1">
            Target audiences worldwide through digital channels for broader
            market access and brand exposure.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 2<<<<<<<<<<<<<< */}
        <div className="COmain2" id="COmain2">
          <img className="V2" id="V2" src={MeasurableResultsImage} alt="" />
          <h2 className="COParHead2" id="COParHead2">
            Measurable Results
          </h2>
          <p className="COPartiPara2" id="COPartiPara2">
            Track and analyse campaign performance with real-time data for
            informed decision-making.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="COmain3" id="COmain3">
          <img className="V2" id="V3" src={costeffectivenessImage} alt="" />
          <h2 className="COParHead3" id="COParHead3">
            Cost-Effectiveness
          </h2>
          <p className="COPartiPara3" id="COPartiPara3">
            Achieve higher ROI compared to traditional marketing methods through
            targeted strategies and lower overheads.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 4<<<<<<<<<<<<<< */}
        <div className="COmain4" id="COmain4">
          <img className="V4" id="V4" src={AudienceTargetingImage} alt="" />
          <h2 className="COParHead4" id="COParHead4">
            Audience Targeting
          </h2>
          <p className="COPartiPara4" id="COPartiPara4">
            Precision-target specific demographics, behaviours, and interests
            for more personalised marketing approaches.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="COmain5" id="COmain5">
          <img className="V5" id="V5" src={flexibilityImage} alt="" />
          <h2 className="COParHead5" id="COParHead5">
            Flexibility
          </h2>
          <p className="COPartiPara5" id="COPartiPara5">
            Quickly adapt strategies based on audience responses and market
            trends for agile campaign management.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="Comain6" id="COmain6">
          <img className="V6" id="V6" src={EnhancedEngagementImage} alt="" />
          <h2 className="COParHead6" id="COParHead6">
            Enhanced Engagement
          </h2>
          <p className="COPartiPara6" id="COPartiPara6">
            Interact with audiences in real-time through various digital
            platforms, fostering stronger brand-consumer relationships.
          </p>
        </div>
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Process>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        {/*  <div>
          <h1 className="COprocessMain" id="COprocessMain">
            How We Function?
          </h1>
          <h2 className="COprocessMain2" id="COprocessMain2">
            Below is our functioning process from filling the form to execution.
          </h2>
          <img className="COProcess" id="COProcess" src={ProcessImage} alt="" />
          <img
            className="COVertical"
            id="COVerticalS"
            src={ProcessVerticalImage}
            alt=""
          />

          <h2 className="SubCOprocess1" id="SubCOprocess1">
            FILL IN THE FORM
          </h2>
          <p className="SubCOprocess11" id="SubCOprocess11">
          To best assist you, please share your needs and contact information.             </p>
          <h2 className="SubCOprocess2" id="SubCOprocess2">
            REVIEW
          </h2>
          <p className="SubCOprocess22" id="SubCOprocess22">
          Our team reviews your details thoroughly for better understanding of your preferences. 
          </p>
          <h2 className="SubCOprocess3" id="SubCOprocess3">
            CONNECT
          </h2>
          <p className="SubCOprocess33" id="SubCOprocess33">
          Our team will connect with you for further requirement gathering and presentation of plans. 
          </p>
          <h2 className="SubCOprocess4" id="SubCOprocess4">
            EXECUTE
          </h2>
          <p className="SubCOprocess44" id="SubCOprocess44">
          Finally, we’ll successfully execute the planned events. 
          </p>
        </div>
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Process-end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
      </section>



      
<section id="Cobg">
  <h3 className="COPortHeading" id="COPortHeading">
    PORTFOLIO
  </h3>
  <h1 className="OurHeading" id="OurHeading">
    OUR WORK
  </h1>
  {/*>>>>>>>>>>>>>>>>>>>>>GALLERY<<<<<<<<<<<<<<<<<<<<<<<*/}
  <div class="row-Gallery" style={{ position: "static" }}>
    <div class="col-galler1">
      <div class="gallery-group">
        <img
          class="COGallery1"
          id="COGallery1"
          src={ourwork1Image}
          alt="Gallery 1"
        />
        <img
          class="COGallery2"
          id="COGallery2"
          src={ourwork2Image}
          alt="Gallery 2"
        />
         <img
          class="COGalleryn12"
          id="COGalleryn12"
          src={ourwork3Image}
          alt="Gallery 2"
        />
      </div>
    </div>
    <div class="col">
      <img
        class="COGallery3"
        id="COGallery3"
        src={ourwork4Image}
        alt="Gallery 3"
      />
    </div>
    
    <div class="col">
      <div class="gallery-group">
        <img
          class="COGallery4"
          id="COGallery4"
          src={ourwork5Image}
          alt="Gallery 4"
        />
        <img
          class="COGallery5"
          id="COGallery5"
          src={ourwork6Image}
          alt="Gallery 5"
        />
      </div>
    </div>
  </div>
</section>

      {/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Blogs Section<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
      <section className="Oranservices" id="OranMain">
        <div className="COBlogS" id="COBlogS">
          <h3 className="COBlogh1" id="COBlogh1">
            INSIGHTS{" "}
          </h3>
          <h1 className="COBlogh2" id="COBlogh2">
            LATEST BLOG & NEWS EVENT
          </h1>
          <div className="BlogSC" id="BlogSC">
            blogs
          </div>

          {/* <div className="BlogsS" id="BlogsS">
            <img className="BL1image" src={BL1Image} alt="" />
            <div>
              <img className="Date1" id="Date1" src={Date1Image} alt="" />
            </div>
            <div>
              <h3 className="BT-1" id="BT-1">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-1" id="ES-1">
                Engineering Survey Week
              </h1>
              <p className="LO-1" id="LO-1">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-1" id="RM-1">
                  Read More
                </h4>
              </Link>
            </div>

            <img className="BL2image" id="BL2image" src={BL2Image} alt="" />
            <div>
              <img className="Date2" id="Date2" src={Date2Image} alt="" />
            </div>
            <div>
              <h3 className="BT-2" id="BT-2">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-2" id="ES-2">
                Sharing Your Knowledge
              </h1>
              <p className="LO-2" id="LO-2">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-2" id="RM-2">
                  Read More
                </h4>
              </Link>
            </div>
            <img className="BL3image" id="BL3image" src={BL3Image} alt="" />
            <div>
              <img className="Date3" id="Date3" src={Date3Image} alt="" />
            </div>
            <div>
              <h3 className="BT-3" id="BT-3">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-3" id="ES-3">
                Lightning Talks Metric Meetup
              </h1>
              <p className="LO-3" id="LO-3">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-3" id="RM-3">
                  {" "}
                  Read More
                </h4>
              </Link>
                    </div>
                    </div>*/}
        </div>
      </section>
     {/* <div className="cpblog" id="cpblog">
        <Slide />
                  </div>*/}
                                  <div id="mainblog" className='blogn3'><BlogCom/></div>

    </div>
  );
};

export default Services;
