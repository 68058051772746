import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Number1Image from "../images/Number1.png";
import Number2Image from "../images/Number2.png";
import Number3Image from "../images/Number3.png";

const Blogslider = ({ blogs }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as per your requirement
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if blogs is undefined or has no items before accessing length property
  const slidesToShow = isMobile ? 1 : blogs ? Math.min(3, blogs.length) : 0;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  // Render the component only if blogs is available
  return blogs ? (
    <div className="blog-carousel">
      <style>{`
                      @media only screen and (min-width: 428px) and (max-width: 430px){
                        #Blogslider .slick-dots{
                          margin-top: -63px;
                          }
                        }                          
@media only screen and (max-width: 360px) and (max-height: 800px) {
  #Blogslider .slick-dots li button:before {
font-size:100px !important;
margin-left: -29px;
}

 }
 @media (max-width: 767px) {
  #Blogslider .slick-dots li button:before {
    margin-left: -29px;
    }
  }
      #mobNumber{
        display:none;
      }
      #Number-image .col-md-4{
        width:28%;
      }
      img#image2Url {
        position: relative;
        bottom: -100px;
        left: -55px;
        display:block;
    }
    #Blogslider h3{
      padding-top:30px !important;
      padding-bottom:15px !important;
    }
    #Blogslider .slick-slider{
      height:220px !important;
      margin-left: 20px !important;
    }
    #Blogslider .slick-dots{
      position:static !important;
      transform: translate(0%, -200%);
    }
    #Blogslider .slick-dots li button:before{
      position:static !important;
    }
    
      #Blogslider .slick-slide {
        height: 0px !important;
        max-width: 270px !important;
        width: 270px !important;
        margin-left: 40px !important;
        margin-right: 0px !important;
        padding-right: 0px !important;
    }
    #Number-image{
      display:flex;
      column-gap: 75px;
      margin-top:-18%;
      margin-left:30px;
          align-items: baseline;
    }
    @media only screen and (max-width: 768px) {
      /* Your mobile-specific CSS styles here */
      img#image2Url {
        position: relative;
        bottom: -100px;
        left: -69px;
        width: 150px;
        height: 150px;
        display: none;
    }
    img.img-fluid2{
      position:static !important;
    }
    .Head-2 {
      position: absolute;
      width: 535px;
      height: 58px;
      left: 90px;
      top: 1470px;
      font-family: 'Helvetica';
      font-style: normal;
      font-weight: 700;
      font-size: 36px !important;
      /* letter-spacing: -1.5px; */
      line-height: 120%;
      transform: translate(10%, 34%);
      display: flex;
      align-items: center;
      color: #000000;
  }
     .slick-list{
    overflow: visible !important;
    margin-top: 2%;
    }
    #mobNumber{
      display: flex;
      position: relative;
      left: 18px;
      bottom: -46px;
      }
      #mobNumber img{
        height:80px;
    }
    .Head-2{  
      font-size: 30px !important;
    letter-spacing: 0.3px;
    margin-bottom: 0px;
    }
  }
      `}</style>
      <div id="mobNumber" className="row">
        <div className="col-4">
          {" "}
          <img src={Number1Image} alt="Blog" className="img-fluid2" />
        </div>
        <div className="col-4">
          {" "}
          <img src={Number2Image} alt="Blog" className="img-fluid2" />
        </div>
        <div className="col-4">
          {" "}
          <img src={Number3Image} alt="Blog" className="img-fluid2" />
        </div>
      </div>
      <h1
        className="Head-2"
        style={{
          marginBottom: "50px",
          textTransform: "uppercase",
          width: "100%",
        }}
      >
        We Are the Differentiators
      </h1>
      {isMobile ? (
        <Slider {...settings}>
          {blogs.map((blog) => (
            <div>
              <img
                src={blog.image2Url}
                alt="Blog"
                id="image2Url"
                className="img-fluid-2"
              />
              <div key={blog.id}>
                <img src={blog.imageUrl} alt="Blog" className="img-fluid" />
                <h3>{blog.title}</h3>
                <p>{blog.content}</p>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div id="Number-image" className="row">
          {blogs.map((blog) => (
            <div key={blog.id} className="col-md-4">
              <img
                src={blog.image2Url}
                alt="Blog"
                id="image2Url"
                className="img-fluid-2"
              />
              <div className="card mb-3">
                <img
                  id="blogimg"
                  src={blog.imageUrl}
                  alt="Blog"
                  className="card-img-top"
                />
                <div className="card-body">
                  <h5
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "25px",
                      lineHeight: "32px",
                      padding: "30px 2px",
                    }}
                    className="card-title"
                  >
                    {blog.title}
                  </h5>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "15px",
                      paddingLeft:'2px'

                    }}
                    className="card-text"
                  >
                    {blog.content}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default Blogslider;
