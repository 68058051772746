import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./bookappointment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import PhoneInput from 'react-phone-input-2';

function Popup({ isOpen, onClose }) {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 2000));

    setLoading(false);

    try {
      const result = await emailjs.sendForm(
        "service_mhkkkwa",
        "template_megbwh2",
        form.current,
        {
          publicKey: "20SA2U48taVusKc5H",
        }
      );
      console.log("Email successfully sent!", result.text);
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      form.current.reset();
    } catch (error) {
      console.error("Failed to send email:", error);
    }

    setLoading(false);
  };

  const filterServices = (category) => {
    switch (category) {
      case "Corporate Events":
        return [
          "Award Ceremonies",
          "Team Building Activities",
          "Exhibitions",
          "Product Launch",
          "Conferences & Seminars",
          "Corporate Offsites",
        ];
      case "Experiential Travel":
        return [
          "Cultural Experience",
          "Local Cuisine",
          "Local Activities",
          "Customised Itinerary",
          "Flight Tickets",
          "Hotel Booking",
        ];
      case "Corporate Gifting":
        return [
          "Budget Gifting",
          "Customised Gifting",
          "Occasional Gifting",
          "Festival Gifting",
          "Health and Safety Gifting",
          "Virtual Gifting",
        ];
      case "Digital Marketing":
        return [
          "SMM",
          "SEO",
          "UI/UX Design",
          "Content Marketing",
          "Tech Solutions",
          "Creative Marketing",
        ];
      default:
        return [];
    }
  };

  const handleClosePopup = () => {
    onClose(); // Close the popup
  };

  if (!isOpen) {
    return null; // If popup is hidden, return null
  }

  return (
    <>
      <div className="overlay"></div>
      <section id="Popup" className="ftco-section img bg-hero ftcbook" style={{ zIndex: 9 }}>
        <style>{`
          section#Popup {
            position: absolute;
            top: 250px;
            width: 90vw;
          }
          #Popup .cont {
              background: white;
              border-radius-left: 20px;
              margin-top: -60px;
              z-index: 1;
              margin-left: -20px;
              box-shadow: 0px 9.92px 49.62px 0px #00000026;
              padding-top: 70px;
              padding-bottom: 50px;
              border-radius: 0px 50px 50px 0px;
          }
          
          #Popup button.close-btn {
            position: absolute;
            top: -36px;
            right: 50px;
            z-index: 1;
        }
        #Popup .fas, .fa-solid{
          font-size:26px;
        }
          #Popup .gtboxba {
              width: 42% !important;
              border-radius: 50px 0px 0px 50px;
              margin-top: -60px;
              background-repeat: no-repeat;
              background-size: cover;
          }
          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(5px);
            z-index: 8; /* Make sure it’s below the popup but above other content */
          }
          /* Styles for screens with a maximum width of 767px (typical for mobile devices) */
  @media only screen and (max-width: 767px) {
    #ph5 {
      border: 0px solid #545454 !important;
      height: 56px !important;
      max-width: 354px !important;
      border-radius: 0px;
      width: 103% !important;
  }
    #Popup .cont {
      background: white;
      border-radius-left: 20px;
      margin-top: 0px;
      z-index: 1;
      margin-left: 0px;
      box-shadow: 0px 9.92px 49.62px 0px #00000026;
      padding-top: 20px;
      padding-bottom: 50px;
      border-radius: 0px 0px 0px 0px;
  }
  section#Popup {
    position: fixed;
    top: 68px;
    width: 100%;
    z-index: 3;
  }
  #Popup button.close-btn {
    position: relative;
          top: 5px;
          right: -315px;
          z-index: 1;
  }
  #Popup .form-group {
     width: 100% !important; 
  }
  #Popup .getcont{
    padding-left: 50px;
      padding-right: 48px;
  margin:0px;
  }
  
  #Popup .gtboxba {
   display:none !important;
  }
  #Popup input#inputname {
    width: 100% !important;
  }
  #Popup .contfeild{
    padding-right: 0% !important;
  }
  #Popup .frmph{
    width: 100% !important;
  }
  #selection{
    padding-right: 0px !important;
  }
  #selection .form-group {
    width:100% !important;
  }
  #Popup .contform{
    font-size: 27px !important;
    line-height: 25px !important;
  }
  #Popup .frcheckbox{
    font-size:12px !important;
  }
  #Popup .selectev{
    width: 100% !important;
  }
  #Popup  .selectcat{
    width: 100% !important;
  }
  }
  @media only screen and (max-width: 414px) and (max-height: 896px) {
  
  
  }   
        `}</style>

        <div className="mrg">
          <div className="row">
            <div className="col contcol">
              <div className="wrapper">
                <div className="row no-gutters justify-content-between">
                  <div className="col-lg-6 d-flex align-items-stretch gtboxba"></div>
                  <div className="col cont">
                    <div className="contact-wrap getcont">
                      <button className="close-btn" onClick={handleClosePopup}>
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                      <h3 className="mb-4 contform">Book An Appointment</h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4 contformp">
                        Get in touch with us for more information on any of the
                        products or services we offer
                      </div>
                      <form ref={form} onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="form-group">
                          <label htmlFor="inputAddress" className="fname">
                            Full Name*
                          </label>
                          <input
                            type="text"
                            name="fname"
                            className="form-control "
                            id="inputname"
                            placeholder="Your Full Name "
                            required
                            style={{ width: "97%" }}
                          />
                        </div>
                        <div className="form-row" id="ph3">
                          <div className="form-group col-md-6 contactcontfeild">
                            <label htmlFor="phoneNumber" className="fphone" >
                              Phone Number*
                            </label>
                            <div className="input-group contactfrmph" id="ph5">
                              <PhoneInput
                                country={'in'}
                                inputProps={{
                                  name: 'fphone',
                                  required: true,
                                  className: 'form-control contactfc',
                                  id: 'phoneNumber',
                                  placeholder: 'Enter Your Phone Number',
                                }}
                                disableCountryCode={false} // Show country code
                              />
                              <input type="tel" name='fphone' className="form-control contactfc" id="phoneNumber" placeholder="9999****** " required style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: '0.88px solid' }} />
                            </div>
                          </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputEmail4" className="femail">
                              Your Email ID
                            </label>
                            <input
                              type="email"
                              name="femail"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Enter Your Email Id "
                            />
                          </div>
                        </div>
                        <div id="selection" className="form-row">
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fcategary">
                              Category’s{" "}
                            </label>
                            <select name='fcategary' id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
                              <option value="" disabled={selectedCategory !== ''} style={{ color: 'red' }} id="sdg">Select Your Category</option>
                              <option value="Corporate Events">Corporate Events</option>
                              <option value="Experiential Travel">Experiential Travel</option>
                              <option value="Corporate Gifting">Corporate Gifting</option>
                              <option value="Digital Marketing">Digital Marketing</option>
                            </select>
                          </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              name="fservices"
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              {filterServices(selectedCategory).map(
                                (service, index) => (
                                  <option key={index}>{service}</option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="invalidCheck2"
                              required
                            />
                            <label
                              className="form-check-label frcheckbox"
                              htmlFor="invalidCheck2"
                            >
                              By Clicking The Button Below, You Agree To Our Terms
                              Of Service And Acknowledge Our Global Privacy
                              Policy.
                            </label>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn sub"
                          disabled={loading}
                        >
                          SUBMIT{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </form>
                      {submitted && (
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginTop: "60px" }}
                        >
                          Your Form is submitted Successfully.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Popup;
