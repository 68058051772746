import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import twitterImage from "./images/twitter.png";

const Socialmedia = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768); // Initial state based on window width

  useEffect(() => {
    const handleResize = () => {
      // Update isMobileView based on current window width
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSocialMediaIcons = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='followdiv'>
      <ul>
        <div className='follow' onClick={isMobileView ? toggleSocialMediaIcons : undefined}>
          Follow us {isMobileView && <i className="material-icons rotate" style={{ marginLeft: '0px' }}>{isOpen ? 'expand_less' : 'expand_more'}</i>}
        </div>
        {(isOpen || !isMobileView) && (
          <>
            <hr className='followhr'></hr>
            <Link to="https://www.facebook.com/profile.php?id=100091998467476&mibextid=LQQJ4d" target="_blank">
              <li className='fbfollow'> <i className="fab fa-facebook-f sdfb" style={{ color: 'white', fontSize: '14px' }}></i></li>
            </Link>

            <Link to="https://www.linkedin.com/company/vui-hospitality-pvt-ltd/" target="_blank">
              <li className='twitterfollow'><i className="fab fa-linkedin-in"  id="twitter" style={{ color: 'white', fontSize: '14px',marginLeft:'4px' }}></i></li>
            </Link>

            <Link to="https://www.instagram.com/vui_hospitality" target="_blank">
              <li className='instafollow'><i className="fab fa-instagram" style={{ color: 'white', fontSize: '14px' }}></i></li>
            </Link>
            <Link to="https://www.linkedin.com/">
              <li className='linkfollow'><i className="fab fa-linkedin-in" style={{ color: 'white', fontSize: '14px' }}></i></li>
            </Link>
          </>
        )}
      </ul>
    </nav>
  );
};
export default Socialmedia;
