import React, { useState, useRef } from "react";
import "./Exhibitionshowservices.css";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

import B1Image from "./images/B1.png";
import B2Image from "./images/B2.png";
import B3Image from "./images/B3.png";
import B4Image from "./images/B4.png";
import B5Image from "./images/B5.png";
import B6Image from "./images/B6.png";

import L1Image from "./images/L1.png";
import L2Image from "./images/L2.png";
import L3Image from "./images/L3.png";
import L4Image from "./images/L4.png";
import L5Image from "./images/L5.png";
import L6Image from "./images/L6.png";

import V1Image from "./images/V1.png";
import V2Image from "./images/V2.png";
import V3Image from "./images/V3.png";
import V4Image from "./images/V4.png";

import Gallery1Image from "./images/Gallery1.png";
import Gallery2Image from "./images/Gallery2.png";
import Gallery3Image from "./images/Gallery3.png";
import Gallery4Image from "./images/Gallery4.png";
import Gallery5Image from "./images/Gallery5.png";
import ItineraryCreationImage from "./images/ItineraryCreation.png";
import OnlineBookingsImage from "./images/OnlineBookings.png";
import ExtensiveNetworkImage from "./images/ExtensiveNetwork.png";
import TravelCompanionImage from "./images/TravelCompanion.png";
import personaltouchsImage from "./images/personaltouchs.png";
import ImmersiveCulturalImage from "./images/ImmersiveCultural.png";

import ProcessImage from "./images/Process.png";
import ProcessVerticalImage from "./images/ProcessVertical.png";

import Hbac1Image from "./images/Hbac1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Slide from "./slide";
import BlogCom  from './post';
import Callback  from './callback';
import 'react-phone-input-2/lib/style.css'; // Import the CSS file for styling
import PhoneInput from 'react-phone-input-2';
import ourwork1Image from './images/ourwork1.png'
import ourwork2Image from './images/ourwork2.png'
import ourwork3Image from './images/ourwork3.png'
import ourwork4Image from './images/ourwork4.png'
import ourwork5Image from './images/ourwork5.png'
import ourwork6Image from './images/ourwork6.png'
import CulturalexperienceImage from './images/Cultural experience .jpg'
import LocalCuisineImage from './images/Local Cuisine.jpg'
import LocalActivitiesImage from './images/Local Activities.jpg'
import CustomisedItineraryImage from './images/Customised Itinerary.jpg'
import FlightBookingImage from './images/Flight Booking.jpg'
import HotelBookingImage from './images/Hotel Booking.jpg'


const Services = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // After the "API call" has finished, stop showing the loading icon
    setLoading(false);

    try {
      // Assuming sendForm returns a promise
      const result = await emailjs.sendForm(
        "service_qx3i0t9",
        "template_2htjzmp",
        form.current,
        {
          publicKey: "gdODjJHS5A-JFuTFC",
        }
      );
      console.log("Email successfully sent!", result.text);
      // Set form as submitted if email was sent successfully
      setSubmitted(true);

      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      // Reset the form here if needed
      form.current.reset();
    } catch (error) {
      console.error("Failed to send email:", error);
    }

    setLoading(false); // End loading
  };
  const filterServices = (category) => {
    switch (category) {
      case "Corporate Events":
        return [
          "Award Ceremonies",
          "Team Building Activities",
          "Exhibitions",
          "Product Launch",
          "Conferences & Seminars",
          "Corporate Offsites",
        ];
      case "Experiential Travel":
        return [
          "Cultural Experience",
          "Local Cuisine",
          "Local Activities",
          "Customised Itinerary",
          "Flight Tickets",
          "Hotel Booking",
        ];
      case "Corporate Gifting":
        return [
          "Budget Gifting",
          "Customised Gifting",
          "Occasional Gifting",
          "Festival Gifting",
          "Health and Safety Gifting",
          "Virtual Gifting",
        ];
      case "Digital Marketing":
        return [
          "SMM",
          "SEO",
          "UI/UX Design",
          "Content Marketing",
          "Tech Solutions",
          "Creative Marketing",
        ];
      default:
        return [];
    }
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm("service_mhkkkwa", "template_megbwh2", form.current, {
  //       publicKey: "20SA2U48taVusKc5H",
  //     })
  //     .then(
  //       () => {
  //         console.log("SUCCESS!");
  //         setSubmitted(true);
  //         // Reset form fields after submission
  //         form.current.reset();
  //       },
  //       (error) => {
  //         console.log("FAILED...", error.text);
  //       }
  //     );
  // };
  return (
    <div>
        <div>
        <Callback/>
        </div>
      <section className="ftco-section-img-bg-hero ftcbannew" id="SftcbannewEX">
        <div className="mrg">
          <div className="row">
            <div className="col contcol">
              <div className="wrapper">
                <div className="row no-gutters justify-content-between">
                  <div className="col-lg-6 d-flex align-items-stretch gtbox">
                    <div className="info">
                      <h3 className=" conth1" id="conth1EX">
                        EXPERIENTIAL
                      </h3>
                      <h3 className=" conth2EX" id="conth2EX">
                        TRAVEL
                      </h3>
                      <h3 className=" conth3" id="conth3EX">
                        SERVICES
                      </h3>
                    </div>
                  </div>
                  <div className="col cont" id="col-cont">
                    <div className="contact-wrap getcont">
                      <h3 className="mb-4 contform">
                        Create Your Requirement{" "}
                      </h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4 contformp">
                        Get in touch with us for more information on any of the
                        products or services we offer{" "}
                      </div>
                      <form ref={form} onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="inputAddress" className="fname">
                            Full Name*{" "}
                          </label>
                          <input
                            type="text"
                            name="fname"
                            className="form-control "
                            id="inputname"
                            placeholder="Your Full Name "
                            required
                            style={{ width: "97%" }}
                          />
                        </div>
                        <div className="form-row" id="phn1">
                        <div className="form-group col-md-6 contactcontfeild">
                          <label htmlFor="phoneNumber" className="fphone" >
                            Phone Number*
                          </label>
                          <div className="input-group contactfrmph">
                          <PhoneInput
                            country={'in'}
                            inputProps={{
                              name: 'fphone',
                              required: true,
                              className: 'form-control contactfc',
                              id: 'phoneNumber',
                              placeholder: 'Enter Your Phone Number',
                            }}
                            disableCountryCode={false} // Show country code
                          />
                            <input type="tel" name='fphone' className="form-control contactfc" id="phoneNumber" placeholder="9999****** " required style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'none', borderLeft: '0.88px solid' }} />
                          </div>
                        </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputEmail4" className="femail">
                              Your Email ID
                            </label>
                            <input
                              type="email"
                              name="femail"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Enter Your Email Id "
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fcategary">
                              Category’s{" "}
                            </label>
                            <select name='fcategary' id="inputcategory" className="form-select selectev" value={selectedCategory} onChange={handleCategoryChange}>
  <option value="" disabled={selectedCategory !== ''} style={{color:'red'}} id="sdg">Select Your Category</option>
  <option value="Corporate Events">Corporate Events</option>
  <option value="Experiential Travel">Experiential Travel</option>
  <option value="Corporate Gifting">Corporate Gifting</option>
  <option value="Digital Marketing">Digital Marketing</option>
</select>

                          </div>
                          <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              name="fservices"
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              {filterServices(selectedCategory).map(
                                (service, index) => (
                                  <option key={index} name="fservices">
                                    {service}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          {/* <div className="form-group col-md-6 contfeild">
                            <label htmlFor="inputState" className="fservices">
                              Services{" "}
                            </label>
                            <select
                              id="inputServices"
                              className="form-select selectcat"
                            >
                              <option selected>Select Your Services </option>
                              <option>...</option>
                            </select>
                          </div> */}
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="invalidCheck2"
                                required
                              />
                              <label
                                className="form-check-label frcheckbox"
                                htmlFor="invalidCheck2"
                              >
                                By Clicking The Button Below, You Agree To Our
                                Terms Of Service And Acknowledge Our Global
                                Privacy Policy.{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn sub"
                          disabled={loading}
                        >
                          SUBMIT{" "}
                          {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                        </button>
                      </form>
                      {submitted && (
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginTop: "60px" }}
                        >
                          Your Form is submitted Successfully.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Section 1 started*/}
      <section className="mainServ1" id="mainServ">
        
        <h3 className="Serv" id="Serv">
          Services
        </h3>
        <h1 className="ServHeadE" id="ServHeadEX">
          All Experiential Travel SERVICES
        </h1>
        <p className="ServPara" id="ServPara">
          Embark on a journey of a lifetime with our well-curated experiential
          travel services that take you to the heart of local cultures. Our
          services go beyond mere sightseeing and offer unique opportunities to
          experience and live the stories and traditions of exciting
          destinations. With us, you'll create indelible memories and deep
          connections, transforming travel into a truly immersive experience.
        </p>
        {/*main div for black section*/}
        <div className="DivSerMain" id="DivSerMain">
          {/*>>>>>>>>>>Sec1<<<<<<<<<<<*/}
          <div className="CODivsec1" id="CODivsec1">
            <img className="COBI1" id="COBI1" src={CulturalexperienceImage} alt="" />
            <div id="COorangeheading1">
              <img className="L1" id="L1" src={L1Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac1" id="Hbac1" src={Hbac1Image} alt="" />
              <h4 className="PBhead-1" id="PBhead-1">
                Cultural Experience
              </h4>
              <p className="DivPara-1" id="DivPara-1">
                Immerse in the local culture with authentic experiences.
                Discover hidden gems, savour traditional flavours, and connect
                with the community in our intimately crafted local adventures.
              </p>
              <Link to="/contact">   <h1 className="DivInq-1" id="DivInq-1">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec2<<<<<<<<<<<*/}
          <div className="CODivsec2" id="CODivsec2">
            <img className="COBI2" id="COBI2" src={LocalCuisineImage} alt="" />
            <div id="COorangeheading2">
              <img className="L2" id="L2" src={L2Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac2" id="Hbac2" src={Hbac1Image} alt="" />
              <h4 className="PBhead-2" id="PBhead-2">
                Local Cuisine
              </h4>
              <p className="DivPara-2" id="DivPara-2">
                Experience rich, authentic local cuisine, skilfully prepared to
                celebrate regional flavours along with culinary storytelling
                that takes your palate on a journey through the local culture
                and traditions.
              </p>
              <Link to="/contact">  <h1 className="DivInq-2" id="DivInq-2">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec3<<<<<<<<<<<*/}
          <div className="CODivsec3" id="CODivsec3">
            <img className="COBI3" id="COBI3" src={LocalActivitiesImage} alt="" />
            <div id="COorangeheading3">
              <img className="L3" id="L3" src={L3Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac3" id="Hbac3" src={Hbac1Image} alt="" />
              <h4 className="PBhead-3" id="PBhead-3">
                Local Activities
              </h4>
              <p className="DivPara-3" id="DivPara-3">
                Indulge in local activities where tradition meets fun. Engage
                with the community, learn new skills, and create notable
                memories through hands-on, culturally immersive experiences.
              </p>
              <Link to="/contact">    <h1 className="DivInq-3" id="DivInq-3">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec4<<<<<<<<<<<*/}
          <div className="CODivsec4" id="CODivsec4">
            <img className="COBI4" id="COBI4" src={CustomisedItineraryImage} alt="" />
            <div id="COorangeheading4">
              <img className="L4" id="L4" src={L4Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac4" id="Hbac4" src={Hbac1Image} alt="" />
              <h4 className="PBhead-4" id="PBhead-4">
                Customised Itinerary
              </h4>
              <p className="DivPara-4" id="DivPara-4">
                Experience travel tailored to your interests with our customised
                itineraries. Explore destinations your way, with expert planning
                ensuring a seamless, personalised journey full of unique,
                curated experiences.
              </p>
              <Link to="/contact">   <h1 className="DivInq-4" id="DivInq-4">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec5<<<<<<<<<<<*/}
          <div className="CODivsec5" id="CODivsec5">
            <img className="COBI5" id="COBI5" src={FlightBookingImage} alt="" />
            <div id="COorangeheading5">
              <img className="L5" id="L5" src={L5Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac5" id="Hbac5" src={Hbac1Image} alt="" />
              <h4 className="PBhead-5" id="PBhead-5">
                Flight Tickets Booking
              </h4>
              <p className="DivPara-5" id="DivPara-5">
                Let us take the hassle of booking your travel mode. We offer a
                seamless flight ticket booking service, ensuring the best deals
                and a stress-free journey to your destination.
              </p>
              <Link to="/contact">   <h1 className="DivInq-5" id="DivInq-5">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
          {/*>>>>>>>>>>Sec6<<<<<<<<<<<*/}
          <div className="CODivsec6" id="CODivsec6">
            <img className="COBI6" id="COBI6" src={HotelBookingImage} alt="" />
            <div id="COorangeheading6">
              <img className="L6" id="L6" src={L6Image} alt="" />
              {/*Comman Image */}
              <img className="Hbac6" id="Hbac6" src={Hbac1Image} alt="" />
              <h4 className="PBhead-6" id="PBhead-6">
                Hotel Booking
              </h4>
              <p className="DivPara-6" id="DivPara-6EX">
                Rest easy with our convenient hotel booking service. We secure
                comfortable accommodations suited to your preferences and
                budget, ensuring you enjoy a stress-free stay in your chosen
                destination.
              </p>
              <Link to="/contact">   <h1 className="DivInq-6" id="DivInq-6">
                Inquire now &#62;
              </h1></Link>
            </div>
          </div>
        </div>
      </section>
      {/*>>>>>>>>>>New Section 2 Starte<<<<<<<<<<<<< */}
      <section className="NewSection2">
        <h3 className="COWhattopHead1" id="COWhattopHead1EX">
          WHAT WE PROMISE{" "}
        </h3>
        <h1 className="COWhattopHead2-1" id="COWhattopHead2EX">
          Our Experiential Travel Services USP
        </h1>
        <p className="WhatPara3" id="WhatPara3EX">
          Tailored journeys that immerse you in local culture, forging deep
          connections and unique experiences crafted to your interests.
          <span className="WhatButton1" id="WhatButton1">
            <Link to="/contact" preventScrollReset={true}>
              <button className="COENQUIRENOW">ENQUIRE NOW</button>
            </Link>
          </span>
        </p>
        {/*>>>>>>>>>>>>>>>>Particluar Section<<<<<<<<<<<<<< */}
        <div className="COmain1" id="COmain1EX">
          <img className="V1" id="V1" src={ItineraryCreationImage} alt="" />
          <h2 className="COParHead1" id="COParHead1">
            Itinerary Creation and Scheduling
          </h2>
          <p className="COPartiPara1" id="COPartiPara1">
            Expertly designed itineraries and precise scheduling ensure a
            seamless, enriching experience from start to finish.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 2<<<<<<<<<<<<<< */}
        <div className="COmain2" id="COmain2EX">
          <img className="V2" id="V2" src={OnlineBookingsImage} alt="" />
          <h2 className="COParHead2" id="COParHead2">
          Affordable Expertise           </h2>
          <p className="COPartiPara2" id="COPartiPara2">
          We create custom online booking systems that simplify your event planning, all while staying within your budget. Get the features you need without overspending. 
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="COmain3" id="COmain3EX">
          <img className="V2" id="V3" src={ExtensiveNetworkImage} alt="" />
          <h2 className="COParHead3" id="COParHead3">
            Extensive Network of Global Partners
          </h2>
          <p className="COPartiPara3" id="COPartiPara3">
            Our far-reaching global partnerships guarantee access to premier
            venues and services, enhancing your event's international flair.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 4<<<<<<<<<<<<<< */}
        <div className="COmain4" id="COmain4EX">
          <img className="V4" id="V4" src={TravelCompanionImage} alt="" />
          <h2 className="COParHead4" id="COParHead4">
            End-to-End Travel Companion
          </h2>
          <p className="COPartiPara4" id="COPartiPara4">
            From departure to return, we offer comprehensive support, ensuring
            comfort and peace of mind throughout your journey.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="COmain5" id="COmain5EX">
          <img className="V5" id="V5" src={personaltouchsImage} alt="" />
          <h2 className="COParHead5" id="COParHead5">
            Our Personal Touch
          </h2>
          <p className="COPartiPara5" id="COPartiPara5">
            We pride ourselves on adding a warm, personal touch to each event,
            making every experience uniquely memorable and personal.
          </p>
        </div>
        {/*>>>>>>>>>>>>>>>>Particluar Section 3<<<<<<<<<<<<<< */}
        <div className="Comain6" id="COmain6EX">
          <img className="V6" id="V6" src={ImmersiveCulturalImage} alt="" />
          <h2 className="COParHead6" id="COParHead6">
            Immersive Cultural Encounters
          </h2>
          <p className="COPartiPara6" id="COPartiPara6">
            Dive into the heart of your destination with our curated experiences
            that bring you face-to-face with the local culture and heritage.
          </p>
        </div>
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Process>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
        {/*  <div>
          <h1 className="COprocessMain" id="COprocessMain">
            How We Function?
          </h1>
          <h2 className="COprocessMain2" id="COprocessMain2">
            Below is our functioning process from filling the form to execution.
          </h2>
          <img className="COProcess" id="COProcess" src={ProcessImage} alt="" />
          <img
            className="COVertical"
            id="COVerticalS"
            src={ProcessVerticalImage}
            alt=""
          />

          <h2 className="SubCOprocess1" id="SubCOprocess1">
            FILL IN THE FORM
          </h2>
          <p className="SubCOprocess11" id="SubCOprocess11">
          To best assist you, please share your needs and contact information.             </p>
          <h2 className="SubCOprocess2" id="SubCOprocess2">
            REVIEW
          </h2>
          <p className="SubCOprocess22" id="SubCOprocess22">
          Our team reviews your details thoroughly for better understanding of your preferences. 
          </p>
          <h2 className="SubCOprocess3" id="SubCOprocess3">
            CONNECT
          </h2>
          <p className="SubCOprocess33" id="SubCOprocess33">
          Our team will connect with you for further requirement gathering and presentation of plans. 
          </p>
          <h2 className="SubCOprocess4" id="SubCOprocess4">
            EXECUTE
          </h2>
          <p className="SubCOprocess44" id="SubCOprocess44">
          Finally, we’ll successfully execute the planned events. 
          </p>
        </div>
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<Process-end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/}
      </section>



      
<section id="Cobg" className="exvi">
  <h3 className="COPortHeading" id="COPortHeading">
    PORTFOLIO
  </h3>
  <h1 className="OurHeading" id="OurHeading">
    OUR WORK
  </h1>
  {/*>>>>>>>>>>>>>>>>>>>>>GALLERY<<<<<<<<<<<<<<<<<<<<<<<*/}
  <div class="row-Gallery" style={{ position: "static" }}>
    <div class="col-galler1">
      <div class="gallery-group">
        <img
          class="COGallery1"
          id="COGallery1"
          src={ourwork1Image}
          alt="Gallery 1"
        />
        <img
          class="COGallery2"
          id="COGallery2"
          src={ourwork2Image}
          alt="Gallery 2"
        />
         <img
          class="COGalleryn12"
          id="COGalleryn12"
          src={ourwork3Image}
          alt="Gallery 2"
        />
      </div>
    </div>
    <div class="col">
      <img
        class="COGallery3"
        id="COGallery3"
        src={ourwork4Image}
        alt="Gallery 3"
      />
    </div>
    
    <div class="col">
      <div class="gallery-group">
        <img
          class="COGallery4"
          id="COGallery4"
          src={ourwork5Image}
          alt="Gallery 4"
        />
        <img
          class="COGallery5"
          id="COGallery5"
          src={ourwork6Image}
          alt="Gallery 5"
        />
      </div>
    </div>
  </div>
</section>

      {/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Blogs Section<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
      <section className="Oranservices" id="OranMain">
        <div className="COBlogS" id="COBlogS">
          <h3 className="COBlogh1" id="COBlogh1">
            INSIGHTS{" "}
          </h3>
          <h1 className="COBlogh2" id="COBlogh2">
            LATEST BLOG & NEWS EVENT
          </h1>
          <div className="BlogSC" id="BlogSC">
            blogs
          </div>

          {/*  <div className="BlogsS" id="BlogsS">
            <img className="BL1image" src={BL1Image} alt="" />
            <div>
              <img className="Date1" id="Date1" src={Date1Image} alt="" />
            </div>
            <div>
              <h3 className="BT-1" id="BT-1">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-1" id="ES-1">
                Engineering Survey Week
              </h1>
              <p className="LO-1" id="LO-1">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-1" id="RM-1">
                  Read More
                </h4>
              </Link>
            </div>

            <img className="BL2image" id="BL2image" src={BL2Image} alt="" />
            <div>
              <img className="Date2" id="Date2" src={Date2Image} alt="" />
            </div>
            <div>
              <h3 className="BT-2" id="BT-2">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-2" id="ES-2">
                Sharing Your Knowledge
              </h1>
              <p className="LO-2" id="LO-2">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-2" id="RM-2">
                  Read More
                </h4>
              </Link>
            </div>
            <img className="BL3image" id="BL3image" src={BL3Image} alt="" />
            <div>
              <img className="Date3" id="Date3" src={Date3Image} alt="" />
            </div>
            <div>
              <h3 className="BT-3" id="BT-3">
                &lt;Business,Technology/&#62;
              </h3>
              <h1 className="ES-3" id="ES-3">
                Lightning Talks Metric Meetup
              </h1>
              <p className="LO-3" id="LO-3">
                Lorem Ipsum dolor sit amet quid dolormentum. Proin gravida nibh
                vel velit auctor aliquet. Aenean sollicitudin, lorem quis
                bibendum auctorelit consequat ipsum, nec sagittis sem ...
              </p>
              <Link to="/Blog">
                {" "}
                <h4 className="RM-3" id="RM-3">
                  {" "}
                  Read More
                </h4>
              </Link>
            </div>
                    </div>*/}
        </div>
      </section>
    {/*  <div className="cpblog" id="cpblog">
        <Slide />
                  </div>*/}
                                  <div id="mainblog" className='blogn3'><BlogCom/></div>

    </div>
  );
};

export default Services;
