import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './tabslid.css';

const Tabslide6 = ({ images }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {images.map((image, idx) => (
          <Carousel.Item key={idx}>
            <img
              className="d-block w-100"
              src={image.src}
              alt={image.alt}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="thumbnail-carousel">
        {images.map((image, idx) => (
          <img
            key={idx}
            className={`thumbnail ${idx === index ? 'active' : ''}`}
            src={image.src}
            alt={image.alt}
            onClick={() => setIndex(idx)}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabslide6;
